import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useCallback, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { formatNumber, NumberFormatType } from '@newedge/common';
import { AssetAllocationTableRow } from './AssetAllocationTableRow';
import { AssetAllocationNestedRow, AssetAllocationRow } from './@types';
import { CSSProperties } from '@mui/styles';
import _ from 'lodash';

const HeaderCell = styled(TableCell)(({ theme: { palette } }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '1.6rem',
  backgroundColor: palette.greyPercent[20],
}));

const TotalValueCell = styled(TableCell)(({ theme: { spacing } }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  paddingTop: spacing(4),
}));

export type State = {
  data: AssetAllocationRow[];
  column?: keyof Omit<AssetAllocationRow, 'nestedData'>;
  direction: 'asc' | 'desc';
};

export type ChangeSortAction = {
  type: 'CHANGE_SORT';
  column: keyof Omit<AssetAllocationRow, 'nestedData'>;
};

export type SetDataAction = {
  type: 'SET_DATA';
  data: AssetAllocationRow[];
};

const reducer = (
  state: State,
  action: ChangeSortAction | SetDataAction
): State => {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        const newData: AssetAllocationRow[] = state.data
          .slice()
          .reverse()
          .map((o: AssetAllocationRow) => {
            const newRow = Object.assign({}, o);
            newRow.nestedData = o.nestedData.slice().reverse();
            return newRow;
          });
        return {
          ...state,
          data: newData,
          direction: state.direction === 'asc' ? 'desc' : 'asc',
        };
      }
      const sortedData = _.sortBy(state.data, [action.column]);
      sortedData.forEach((o) => {
        o.nestedData = _.sortBy(o.nestedData, [action.column]);
      });
      return {
        ...state,
        column: action.column,
        data: sortedData,
        direction: 'asc',
      };
    case 'SET_DATA':
      return {
        ...state,
        data: action.data,
        column: undefined,
        direction: 'asc',
      };
    default:
      throw new Error('Invalid action type');
  }
};

export interface Column<T> {
  id: keyof T;
  label: string;
}

interface LocationState {
  setFirstRowOpen?: boolean;
  category?: string;
}

type AssetAllocationTableProps = {
  columns: Column<Omit<AssetAllocationRow, 'nestedData'>>[];
  data: AssetAllocationRow[];
  initialSortColumn?: keyof Omit<AssetAllocationRow, 'nestedData'>;
  disableLinks: boolean;
  onViewHoldingsClick: (
    row: AssetAllocationRow,
    nestedRow: AssetAllocationNestedRow
  ) => void;
  nestedCellStyle?: CSSProperties;
};

export const AssetAllocationTable = ({
  columns,
  data,
  initialSortColumn,
  disableLinks,
  onViewHoldingsClick,
  nestedCellStyle,
}: AssetAllocationTableProps) => {
  const location = useLocation();
  const locationState = location.state as LocationState;

  const [state, dispatch] = useReducer(reducer, {
    data,
    column: undefined,
    direction: 'asc',
  });

  useEffect(() => {
    dispatch({ type: 'SET_DATA', data });
  }, [data]);

  if (initialSortColumn && state.column === undefined) {
    dispatch({ type: 'CHANGE_SORT', column: initialSortColumn });
  }

  let marketValueTotal = 0;
  let percentTotal = 0;
  state.data.forEach((o) => {
    marketValueTotal += o.marketValue;
    percentTotal += o.percent;
  });

  const renderTableRows = useCallback(() => {
    let firstRowSetOpen = false;
    return state.data.map((row) => {
      let startOpen = false;
      if (locationState && locationState.setFirstRowOpen && !firstRowSetOpen) {
        startOpen = true;
        firstRowSetOpen = true;
      }
      if (locationState && locationState.category === row.name) {
        startOpen = true;
      }
      return (
        <AssetAllocationTableRow
          key={row.name}
          rowData={row}
          startOpen={startOpen}
          disableLinks={disableLinks}
          onViewHoldingsClick={onViewHoldingsClick}
          nestedCellStyle={nestedCellStyle}
        />
      );
    });
  }, [
    state.data,
    locationState,
    disableLinks,
    onViewHoldingsClick,
    nestedCellStyle,
  ]);

  const renderTotalRow = useCallback(() => {
    return (
      <TableRow>
        <TableCell />
        <TableCell
          colSpan={5}
          sx={({ spacing }) => ({
            fontWeight: 'bold',
            fontSize: '1.6rem',
            textTransform: 'uppercase',
            paddingTop: spacing(4),
          })}
        >
          Result Total
        </TableCell>
        <TotalValueCell colSpan={6}>
          {formatNumber(marketValueTotal)}
        </TotalValueCell>
        <TotalValueCell colSpan={6}>
          {formatNumber(percentTotal, NumberFormatType.Percent, 2, 2)}
        </TotalValueCell>
      </TableRow>
    );
  }, [marketValueTotal, percentTotal]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table' sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            {columns.map((item) => (
              <HeaderCell colSpan={6}>
                <TableSortLabel
                  active={state.column === item.id}
                  direction={state.direction}
                  onClick={() => {
                    dispatch({ type: 'CHANGE_SORT', column: item.id });
                  }}
                  IconComponent={ArrowDropDownIcon}
                >
                  {item.label}
                </TableSortLabel>
              </HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {renderTableRows()}
          {renderTotalRow()}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetAllocationTable;
