import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';

interface ManageDisplayNameRowProps {
  displayName: string;
  onUpdateClick: (newDisplayName: string) => void;
}

export const ManageDisplayNameRow = ({
  displayName,
  onUpdateClick,
}: ManageDisplayNameRowProps) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [value, setValue] = useState<string>(displayName);

  return (
    <Box
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box sx={{ mx: 4 }} data-testid='ProfileNameLabel'>
        Profile Name:
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: !edit ? 'space-between' : 'normal',
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        {edit ? (
          <>
            <TextField
              size='small'
              sx={{
                backgroundColor: '#3f3f3f',
                borderRadius: '10px',
              }}
              color='secondary'
              value={value}
              onChange={(event) => {
                setValue(event.target.value);
              }}
              inputProps={{
                'data-testid': 'ProfileNameInput',
              }}
            />
            <Button
              onClick={() => {
                onUpdateClick(value);
                setEdit(false);
              }}
              sx={{ mx: 2 }}
              data-testid='ProfileNameUpdate'
            >
              Update
            </Button>
            <Button
              variant='text'
              onClick={() => {
                setEdit(false);
                setValue(displayName);
              }}
              sx={{
                color: '#BDBDBD',
                textDecoration: 'underline',
                margin: 0,
              }}
              data-testid='ProfileNameCancel'
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Box sx={{ mx: 2 }} data-testid='ProfileNameDisplay'>
              {displayName}
            </Box>
            <Button
              onClick={() => {
                setEdit(true);
              }}
              disableRipple
              data-testid='ProfileNameEdit'
            >
              Edit
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ManageDisplayNameRow;
