export enum NumberFormatType {
  Currency,
  Number,
  Percent,
}

export const formatNumber = (
  value: number,
  numberFormatType: NumberFormatType = NumberFormatType.Currency,
  maximumDecimal: number | undefined = undefined,
  minimumDecimal: number | undefined = undefined,
  positiveNumberDecorator?: boolean
): string => {
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: maximumDecimal,
    minimumFractionDigits: minimumDecimal,
  });

  if (numberFormatType === NumberFormatType.Currency) {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: maximumDecimal,
      minimumFractionDigits: minimumDecimal,
    });
    return `${formatter.format(value)}`;
  }

  if (
    numberFormatType === NumberFormatType.Percent &&
    positiveNumberDecorator
  ) {
    return `${value > 0 ? '+' : ''}${formatter.format(value)}%`;
  }

  if (numberFormatType === NumberFormatType.Percent) {
    return `${formatter.format(value)}%`;
  }
  
  return `${formatter.format(value)}`;
};

export const abbreviateNumber = (
  value: number,
  isPercent: boolean = false
): string => {
  let sign: string = '';
  let suffix: string = '';

  if (value < 0) {
    sign = '- ';
  }

  if (isPercent) {
    return `${sign}${Math.abs(value).toFixed(2)}%`;
  }

  if (value > 1000000000) {
    value = value / 1000000000;
    suffix = 'B';
    return `${sign}${formatNumber(
      Number(Math.abs(value).toFixed(2)),
      NumberFormatType.Currency,
      2,
      2
    )}${suffix}`;
  }

  return `${sign}${formatNumber(
    Number(Math.abs(value).toFixed(2)),
    NumberFormatType.Currency,
    0,
    0
  )}${suffix}`;
};

export default abbreviateNumber;
