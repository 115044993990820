import { DialogActions, Button } from '@mui/material';
import { ModalButton } from './@types';

export type ConfirmActionsProps = {
  submitButton?: ModalButton;
  cancelButton?: ModalButton;
  handleCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function ConfirmActions({
  submitButton,
  cancelButton,
  handleCancel,
  handleSubmit,
}: ConfirmActionsProps) {
  return (
    <DialogActions
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {cancelButton && (
        <Button onClick={handleCancel} {...cancelButton}>
          {cancelButton.text}
        </Button>
      )}
      {submitButton && (
        <Button onClick={handleSubmit} {...submitButton}>
          {submitButton.text}
        </Button>
      )}
    </DialogActions>
  );
}

export default ConfirmActions;
