import moment from 'moment';

/**
 * Checks if the provided key exists inside of an object, with type safety.
 * @param obj The Record/Object to check.
 * @param key Name of key to look for in the Object.
 * @returns boolean
 */
export function isObjKey<T extends object>(obj: T, key: any): key is keyof T {
  return obj && key in obj;
}

/**
 * Checks if the provided key exists inside of an object, with type safety.  Then evaluates if the key's value equals a provided value.
 * @param obj The Record/Object to check.
 * @param key Name of key to look for in the Object.
 * @param value Value to compare the object key's value to.
 * @returns boolean
 */
export function isObjKeyEqualTo<TObj extends object>(
  obj: TObj,
  key: any,
  value: any
) {
  if (isObjKey(obj, key)) {
    return obj[key] === value;
  }
  return false;
}

export function sortByDate<TObj extends object>(
  items: TObj[],
  dateFieldSelector: (item: TObj) => Date,
  sortAscending: boolean = false
): TObj[] {
  return sortAscending
    ? items.sort((a, b) =>
        moment(dateFieldSelector(b)).diff(dateFieldSelector(a))
      )
    : items.sort((a, b) =>
        moment(dateFieldSelector(a)).diff(dateFieldSelector(b))
      );
}

/**
 * Removes null and undefined values from an array.
 * @param value The value to check if null or undefined.
 */

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}