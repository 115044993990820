import { IconButton, styled, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCallback, useState } from 'react';
import { PerformanceTableRowData } from '../@types';
import PerformanceTableNestedRow from './PerformanceTableNestedRow';
import { ColumnVisibilityState } from './ColumnVisibilityMenu';
import { formatNumber, NumberFormatType } from '@newedge/common';
import { cellValueFormatter } from '../performanceHelpers';
import { PerformanceTableColumn } from './@types';

const StyledTableCell = styled(TableCell)(({ theme: { extensions } }) => ({
  fontSize: '1.4rem',
  textAlign: 'right',
  backgroundColor: extensions.grey[14],
}));

const StyledInceptionTableCell = styled(TableCell)(
  ({ theme: { extensions, typography, spacing } }) => ({
    ...typography.link,
    backgroundColor: extensions.grey[14],
  })
);

type PerformanceTableRowProps = {
  rowData: PerformanceTableRowData;
  startOpen?: boolean;
  first?: boolean;
  visibilityState: ColumnVisibilityState[];
  isOpen: boolean;
};

const PerformanceTableRow = ({
  rowData,
  startOpen,
  visibilityState,
  isOpen,
}: PerformanceTableRowProps) => {
  let initialOpenValue = false;
  if (startOpen) {
    initialOpenValue = true;
  }
  const [isNestedOpen, setIsNestedOpen] = useState(initialOpenValue);

  const renderCellValue = useCallback(
    (
      label: PerformanceTableColumn['label'],
      key: keyof Omit<PerformanceTableRowData, 'nestedData'>
    ) => {
      if (
        visibilityState.find((column) => column.label === label)?.visible ===
        false
      ) {
        return null;
      }
      return cellValueFormatter(rowData[key]);
    },
    [rowData, visibilityState]
  );

  return (
    <>
      <TableRow sx={isOpen ? undefined : { display: 'none' }}>
        <TableCell
          sx={(theme) => ({
            ...theme.typography.caption,
            backgroundColor: theme.extensions.grey[8],
          })}
          component='th'
          scope='row'
        >
          {rowData.nestedData && (
            <IconButton
              aria-label='expand row'
              sx={{ fontSize: '2.4rem', padding: '3px' }}
              onClick={() => {
                setIsNestedOpen(!isNestedOpen);
              }}
              size='large'
            >
              {isNestedOpen ? (
                <KeyboardArrowUpIcon fontSize='inherit' />
              ) : (
                <KeyboardArrowDownIcon fontSize='inherit' />
              )}
            </IconButton>
          )}
          <span style={{ textTransform: 'uppercase' }}>{rowData.name}</span>
        </TableCell>
        <StyledTableCell>
          {rowData.marketValue
            ? formatNumber(rowData.marketValue, NumberFormatType.Currency, 0, 0)
            : '--'}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('MTD', 'monthToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('QTD', 'quarterToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('YTD', 'yearToDate')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('1YR', 'oneYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('3YR', 'threeYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('5YR', 'fiveYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('10YR', 'tenYearReturns')}
        </StyledTableCell>
        <StyledTableCell>
          {renderCellValue('Since Inception', 'sinceInception')}
        </StyledTableCell>
        <StyledInceptionTableCell></StyledInceptionTableCell>
      </TableRow>
      {rowData!.nestedData!.map((row, index) => {
        return (
          <PerformanceTableNestedRow
            key={row.name + '_' + index}
            isOpen={isNestedOpen}
            isParentOpen={isOpen}
            rowData={row}
            visibilityState={visibilityState}
          />
        );
      })}
    </>
  );
};

export default PerformanceTableRow;
