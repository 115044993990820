import { TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CompactTableColumn } from './@types';
import { ColumnDataType, DataAlignment } from '../sortable-table/@types';
import {
  formatNumber,
  NumberFormatType,
} from '../../../utils/numberFormatters';
import { ValueOf } from '../@types';
import { TooltipWrapper } from '../../../utils';

const StyledTableCell = styled(TableCell)(({ theme: { spacing } }) => {
  return {
    fontSize: '1.4rem',
    padding: spacing(1),
    paddingLeft: spacing(2.5),
  };
});

interface CompactTableRowProps<T> {
  columns: CompactTableColumn<T>[];
  rowData: T;
}

export const CompactTableRow = <T extends unknown>({
  columns,
  rowData,
}: CompactTableRowProps<T>) => {
  const getFormattedText = (
    value: ValueOf<T>,
    column: CompactTableColumn<T>
  ): string => {
    switch (column.dataType) {
      case ColumnDataType.Currency:
        return formatNumber(
          value as unknown as number,
          NumberFormatType.Currency,
          column.precision ?? 2,
          column.precision ?? 2
        );
      case ColumnDataType.Percent:
        return formatNumber(
          value as unknown as number,
          NumberFormatType.Percent,
          column.precision ?? 2,
          column.precision ?? 2
        );
      case ColumnDataType.Number:
        return formatNumber(
          value as unknown as number,
          NumberFormatType.Number,
          column.precision ?? 2,
          column.precision ?? 2
        );
    }
    return value as unknown as string;
  };

  return (
    <TableRow
      sx={{
        tableLayout: 'fixed',
        '&:nth-of-type(odd)': {
          bgcolor: 'greyPercent.14',
        },
      }}
    >
      <StyledTableCell width='5%' />
      {columns.map((column) => {
        return (
          <StyledTableCell
            align={
              column.dataAlignment === DataAlignment.Right ? 'right' : 'left'
            }
            key={`${String(column.id)}_${column.label}_${typeof column}`}
          >
            <TooltipWrapper
              text={getFormattedText(rowData[column.id], column)}
              typographyVariant='link'
              maxLength={column.maxLength}
            />
          </StyledTableCell>
        );
      })}
      <StyledTableCell width='5%' />
    </TableRow>
  );
};

export default CompactTableRow;
