import {
  Box,
  Grid,
  TextField,
  Typography,
  Theme,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/lab/Autocomplete';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import AccordionItem from '../accordion/AccordionItem';
import {
  DateRangeCriteria,
  FilterItem,
  RangeCriteria,
  SearchableCriteria,
  SelectableCriteria,
} from 'src/components/table-filter/@types';
import { v4 as uuidv4 } from 'uuid';
import { NumberRangeFilter } from './range-filter/NumberRangeFilter';
import { DateFilterPicker } from './date-filter/DateFilterPicker';
import { MobileDateFilterPicker } from './date-filter/MobileDateFilterPicker';

const PREFIX = 'SearchCriteria';

const classes = {
  chip: `${PREFIX}-chip`,
  allCapitals: `${PREFIX}-allCapitals`,
  expandIcon: `${PREFIX}-expandIcon`,
  summaryRoot: `${PREFIX}-summaryRoot`,
  icon: `${PREFIX}-icon`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.chip}`]: {
    margin: theme.spacing(1.5),
  },

  [`& .${classes.allCapitals}`]: {
    textTransform: 'uppercase',
  },

  [`& .${classes.expandIcon}`]: {
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(0),
    fontSize: '30px',
  },

  [`& .${classes.summaryRoot}`]: {
    flexDirection: 'row-reverse',
    paddingLeft: 0,
  },

  [`& .${classes.icon}`]: {
    fontSize: '2.4rem',
    padding: '3px',
  },
}));

interface SearchCriteriaProps {
  criteria: SearchableCriteria | RangeCriteria | DateRangeCriteria;
  onValuesChange: (newValues: FilterItem) => void;
}

export const SearchCriteria = ({
  criteria,
  onValuesChange,
}: SearchCriteriaProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const abbreviations: { [key: string]: string } = useMemo(
    () => ({
      'Management Style': 'M/S',
      'Asset Class': 'A/C',
      'Sub-Asset Class': 'S-A/C',
      Ticker: 'Tkr',
      Date: 'Date',
      'Transcation Amount Range': '$',
      'Transcation Type': 'Type',
      Keyword: 'Key',
    }),
    []
  );

  const setSliderValue = useCallback(
    (newValue: number[]) => {
      onValuesChange(
        {
          predicateIdentifier: (criteria as RangeCriteria).predicateIdentifier,
          displayValue:
            (abbreviations[criteria.header] ?? criteria.header) +
            ': [' +
            newValue[0] +
            ', ' +
            newValue[1] +
            ']',
          filterType: 'range',
          lowerBound: newValue[0],
          upperBound: newValue[1],
          unique: true,
        },
      );
    },
    [abbreviations, criteria, onValuesChange]
  );

  return (
    <Root>
      <AccordionItem
        isExpanded={isExpanded}
        onChange={() => setIsExpanded(!isExpanded)}
        key={uuidv4()}
        accordionSummaryProps={{
          classes: {
            // TODO: fix this expandIcon assignment (doesn't exist on AccordionItem)
            // expandIcon: classes.expandIcon,
            root: classes.summaryRoot,
          },
        }}
        expandIcon={
          <ExpandMoreIcon fontSize="large" style={{ fontSize: 30 }} />
        }
        summaryComponent={
          <Box>
            <Typography>{criteria.header}</Typography>
          </Box>
        }
        detailsComponent={
          <Grid container direction="row">
            <Grid item xs={12}>
              {criteria.filterType === 'text' ||
              criteria.filterType === 'keyword' ? (
                <Autocomplete
                  multiple
                  value={[]} // Don't ever populate the search bar with the selected values.
                  onChange={(_e, value) => {
                    if (typeof value[0] === 'string') {
                      onValuesChange(
                        {
                          displayValue:
                            (abbreviations[criteria.header] ??
                              criteria.header) +
                            ': ' +
                            value[0],
                          filterType: criteria.filterType,
                          searchValue: value[0],
                        },
                      );
                    } else {
                      const newValue = value[0];
                      onValuesChange(
                        {
                          predicateIdentifier: newValue.predicateIdentifier,
                          displayValue:
                            (abbreviations[criteria.header] ??
                              criteria.header) +
                            ': ' +
                            newValue.displayValue,
                          filterType: criteria.filterType,
                          searchValue: newValue.searchValue,
                          logic: newValue.logic,
                        },
                      );
                    }
                  }}
                  id="tags-standard"
                  options={_.sortBy(criteria.selections, ['displayValue'])}
                  getOptionLabel={(option: SelectableCriteria | string) => {
                    if (typeof option !== 'string') {
                      return option.displayValue;
                    }
                    return option;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Search"
                      color="iron"
                    />
                  )}
                  freeSolo={criteria.allowArbitraryValue}
                  popupIcon={<ArrowDropDownIcon className={classes.icon} />}
                />
              ) : null}
              {criteria.filterType === 'range' ? (
                <NumberRangeFilter
                  min={criteria.min}
                  max={criteria.max}
                  setValue={setSliderValue}
                />
              ) : null}
              {criteria.filterType === 'date' ? (
                <>
                  {isMobileOrTablet ? (
                    <MobileDateFilterPicker
                      config={criteria}
                      onValueChange={onValuesChange}
                    />
                  ) : (
                    <DateFilterPicker
                      config={criteria}
                      onValueChange={onValuesChange}
                    />
                  )}
                </>
              ) : null}
            </Grid>
          </Grid>
        }
      />
    </Root>
  );
};

export default SearchCriteria;
