import { PropsWithChildren } from 'react';
import { Button as MuiButton, ButtonProps } from '@mui/material';

const styles = {
  sm: { fontSize: '1rem', lineHeight: '1rem' },
  md: { fontSize: '1.4rem', lineHeight: '1.4rem', py: 1, px: 2 },
  lg: { fontSize: '1.6rem', lineHeight: '1.6rem', py: 1, px: 2 },
  xl: { fontSize: '2.4rem', lineHeight: '2.4rem', py: 1, px: 2 },
};

type CustomButtonProps = PropsWithChildren<{
  buttonProps?: ButtonProps;
  variant?: 'sm' | 'md' | 'lg' | 'xl';
}>;

// TODO: define the base button theme & add application insights tracking
function CustomButton({
  buttonProps,
  children,
  variant = 'md',
}: CustomButtonProps) {
  return (
    <MuiButton
      {...buttonProps}
      classes={{
        ...buttonProps?.classes,
      }}
      sx={[
        (theme) => ({
          background: 'linear-gradient(to right,#0A42C6,#1C60FF)',
          color: 'white',
          margin: theme.spacing(1),
          ...styles[variant],
        }),
        ...(Array.isArray(buttonProps?.sx) && buttonProps?.sx
          ? buttonProps.sx
          : [buttonProps?.sx]),
      ]}
    >
      {children}
    </MuiButton>
  );
}

export default CustomButton;
