import _ from 'lodash';
import { FilterItem, SelectableCriteria } from './@types';

export const filterSelected = (
  criteria: SelectableCriteria[],
  filterValues: FilterItem[]
): SelectableCriteria[] => {
  return criteria.filter((o) => {
    return (
      filterValues.findIndex(
        (value) =>
          _.isEqual(value.searchValue, o.searchValue) &&
          value.predicateIdentifier === o.predicateIdentifier
      ) === -1
    );
  });
};

export default filterSelected;
