import React, { ReactNode } from 'react';
import { Box, createStyles, Link, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useAuth } from '@newedge/auth';

// TODO: RouteChildProps replacement & navigate to previous location

interface ErrorViewProps {
  customLinkRenderer?: () => ReactNode;
}

export function ErrorView({ customLinkRenderer }: ErrorViewProps) {
  const { logout } = useAuth();
  const { errorCode } = useParams();
  const navigate = useNavigate();

  const handleLogOut = () => {
    logout().then(() => {
      navigate(window.location.origin);
    });
  };

  const dashboardLink = (
    <div>
      <Link component={RouterLink} to='/my/dashboard'>
        Go back to dashboard page
      </Link>
    </div>
  );

  const errorCodeMap: Record<string, [string, React.ReactNode]> = {
    '401': [
      'NOT AUTHORIZED',
      <div style={{ maxWidth: 690 }}>
        You are not authorized to access this page.{' '}
        <Link onClick={handleLogOut}>Try logging in again.</Link>
      </div>,
    ],
    '403': [
      'FORBIDDEN',
      <div style={{ maxWidth: 690 }}>
        You are forbidden to access this page.
        <br />
        <Link onClick={handleLogOut}>Log Out.</Link>
      </div>,
    ],
    '404': [
      'NOT FOUND',
      <div style={{ maxWidth: 640 }}>
        We cannot find the page you are looking for.{' '}
        {customLinkRenderer ? customLinkRenderer() : dashboardLink}
      </div>,
    ],
  };

  const isCodeValid = errorCode && errorCodeMap[errorCode] !== undefined;

  const [codeCaption, description] = isCodeValid
    ? errorCodeMap[errorCode!]
    : [
        '',
        <div style={{ maxWidth: 550 }}>
          Something went wrong!.{' '}
          {customLinkRenderer ? customLinkRenderer() : dashboardLink}
        </div>,
      ];

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 28 }}>
        <div>
          {isCodeValid ? (
            <Box
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(280),
                lineHeight: 0.75,
                [theme.breakpoints.down('xs')]: {
                  fontSize: theme.typography.pxToRem(160),
                },
                paddingBottom: theme.typography.pxToRem(10),
              })}
            >
              {errorCode}
            </Box>
          ) : (
            <Box
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(200),
                [theme.breakpoints.down('xs')]: {
                  fontSize: theme.typography.pxToRem(100),
                },
              })}
            >
              Oops!
            </Box>
          )}
          {
            <Box
              sx={(theme) => ({
                fontSize: theme.typography.pxToRem(36),
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#424242',
                [theme.breakpoints.down('xs')]: {
                  fontSize: theme.typography.pxToRem(25),
                },
              })}
            >
              {codeCaption}
            </Box>
          }
        </div>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={(theme) => ({
            fontSize: theme.typography.pxToRem(200),
            marginLeft: 45,
            marginRight: 62,
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
              margin: theme.spacing(0, 2),
            },
            [theme.breakpoints.down('xs')]: {
              display: 'none',
            },
          })}
        >
          <Box
            sx={{
              width: 33,
              height: 290,
              background: '#007A80',
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 18 }}>
          <div>
            <Typography variant='h2'>Terribly sorry but...</Typography>
            <Typography>{description}</Typography>
          </div>
        </Box>
      </Box>
    </>
  );
}

export default ErrorView;
