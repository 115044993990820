import { Box, Link, Typography } from '@mui/material';
import { useAuth } from '@newedge/auth';
import {
  ClientPortalLogo,
  InactivityLogOutMessageModal,
} from '@newedge/common';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface LoggedOutViewProps {
  redirectStartPage?: string;
}

// TODO: redirectStartPage: location.state?.from ?? '/',  Navigate to previous location
export function LoggedOutView({
  redirectStartPage = '/my/dashboard',
}: LoggedOutViewProps) {
  const { login } = useAuth();
  let [searchParams] = useSearchParams();
  const [isInactivityLogOutMessageOpen, setIsInactivityLogOutMessageOpen] =
    useState<boolean>(
      searchParams.get('showInactivityLogOutMessage') === 'true'
    );

  return (
    <Box
      sx={{
        width: 1040,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div>
        <Box
          sx={(theme)=> ({
            justifyContent: 'left',
            width: '17%',
            maxWidth: '1400px',
            paddingBottom: theme.spacing(2),
          })}
        >
          <ClientPortalLogo />
        </Box>
        <Typography sx={(theme) => ({ marginTop: theme.spacing(2), marginBottom: theme.spacing(10) })} variant='h2'>
          You have logged out...
        </Typography>
        <Typography>
          If you have done this in error,{' '}
          <Link
            underline='always'
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              login({
                scopes: [],
                redirectStartPage: redirectStartPage,
              });
            }}
          >
            please log in again
          </Link>
          .
        </Typography>
      </div>
      <InactivityLogOutMessageModal
        isOpen={isInactivityLogOutMessageOpen}
        okOnClick={() => setIsInactivityLogOutMessageOpen(false)}
      />
    </Box>
  );
}

export default LoggedOutView;
