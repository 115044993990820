import { Logger } from '@newedge/common';

import {
  CacheConfig,
  Environment,
  GraphQLResponse,
  Network,
  QueryResponseCache,
  RecordSource,
  RequestParameters,
  Store,
  UploadableMap,
  Variables,
} from 'relay-runtime';
import { RelayConfiguration } from './@types';
import {
  networkAndCacheFetchFn,
  networkFetchFunction,
} from './relayFetchFunctions';

const createDefaultRelayStore = () =>
  new Store(new RecordSource(), {
    // This property tells Relay to not immediately clear its cache when the user
    // navigates around the app. Relay will hold onto the specified number of
    // query results, allowing the user to return to recently visited pages
    // and reusing cached data if its available/fresh.
    gcReleaseBufferSize: 10,
  });

// TODO: Need to watch for caching issues here. Unsure if cache handling happens before or after the client is used
const createRelayFetchFunction =
  (
    clientEndpoint: string,
    clientScopes: string[],
    getAccessTokenFn: (scopes: string[]) => Promise<string | undefined>,
    responseCache?: QueryResponseCache,
    logger?: Logger
  ) =>
  async (
    request: RequestParameters,
    variables: Variables,
    cacheConfig?: CacheConfig,
    uploadables?: UploadableMap | null
  ): Promise<GraphQLResponse> => {
    if (!clientEndpoint) {
      logger?.error(
        'Could not create GQL client based on current configuration.'
      );
      return new Promise(() => null);
    }

    const token = await getAccessTokenFn(clientScopes);

    if (responseCache)
      return networkAndCacheFetchFn(
        responseCache,
        clientEndpoint,
        request,
        variables,
        token,
        uploadables,
        cacheConfig
      );

    return networkFetchFunction(
      clientEndpoint,
      request,
      variables,
      token,
      uploadables
    );
  };

/**
 * Creates a Relay environment that fetches access tokens for the provided endpoints & scopes.
 */
const createRelayEnvironment = ({
  endpoint,
  scopes,
  getAccessToken,
  logger,
  inMemoryCache,
  relayStore,
  initLocalStore,
  relayFetchFn,
}: RelayConfiguration) => {
  const store = relayStore ?? createDefaultRelayStore();

  const fetchFunction =
    relayFetchFn ??
    createRelayFetchFunction(
      endpoint,
      scopes,
      getAccessToken,
      inMemoryCache,
      logger
    );

  const environment = new Environment({
    network: Network.create(fetchFunction),
    store,
  });
  if (initLocalStore) initLocalStore(environment);
  return environment;
};

export default createRelayEnvironment;
