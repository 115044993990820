import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
  CacheConfig,
  GraphQLResponse,
  QueryResponseCache,
  RequestParameters,
  UploadableMap,
  Variables,
} from 'relay-runtime';
import {
  forceFetch,
  getHeaders,
  getRequestBody,
  isMutation,
  isQuery,
} from './helpers/network-helpers';

const DEFAULT_TIMEOUT = 20000;
const TIMEOUT_REGEX = new RegExp(/Still no successful response after/);
const SERVER_UNAVAILABLE_REGEX = new RegExp(/Failed to fetch/);

/**
 * Fetch request over network with axios.
 * TODO: Set timeout from Relay configuration
 */
const networkFetchFunction = async (
  endpoint: string,
  request: RequestParameters,
  variables: Variables,
  accessToken?: string,
  uploadables?: UploadableMap | null,
  timeout: number = DEFAULT_TIMEOUT
) => {
  try {
    const body = getRequestBody(request, variables, uploadables);
    const headers = getHeaders(accessToken, uploadables);

    axiosRetry(axios, {
      retryDelay: axiosRetry.exponentialDelay,
    });

    const response = await axios.post<GraphQLResponse>(endpoint, body, {
      timeout,
      headers,
    });

    const { data } = response;

    if (response.status === 401 && 'errors' in data) {
      throw new Error(data.errors?.toString());
    }

    if (isMutation(request) && 'errors' in data && data.errors) {
      throw new Error(JSON.stringify(data));
    }

    return data;
  } catch (e: unknown) {
    if (
      e instanceof Error &&
      (TIMEOUT_REGEX.test(e?.message) ||
        SERVER_UNAVAILABLE_REGEX.test(e?.message))
    ) {
      throw new Error('Unavailable service. Try again later.');
    }
    throw e;
  }
};

/**
 * Fetch request over network with axios.
 */
const networkAndCacheFetchFn = async (
  queryResponseCache: QueryResponseCache,
  endpoint: string,
  request: RequestParameters,
  variables: Variables,
  accessToken?: string,
  uploadables?: UploadableMap | null,
  cacheConfig?: CacheConfig
): Promise<GraphQLResponse> => {
  // TODO: handle blank requests.  Not sure what scenario may cause this.  GET requests with the GQL in the query string maybe?
  if (!request.text) return new Promise(() => null);

  const queryID = request.text;

  // Clearing the cache, since we're not yet evaluating the effects of a mutation on the current repsonses
  if (isMutation(request)) {
    queryResponseCache.clear();
    return networkFetchFunction(
      endpoint,
      request,
      variables,
      accessToken,
      uploadables
    );
  }

  const fromCache = queryResponseCache.get(queryID, variables);
  if (isQuery(request) && fromCache !== null && !forceFetch(cacheConfig)) {
    return fromCache;
  }

  const fromServer = await networkFetchFunction(
    endpoint,
    request,
    variables,
    accessToken,
    uploadables
  );

  if (fromServer) {
    queryResponseCache.set(queryID, variables, fromServer);
  }

  return fromServer;
};

export { networkFetchFunction, networkAndCacheFetchFn };
