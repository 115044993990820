import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { ConfirmActions } from '../modals';
import { useMsal } from '@azure/msal-react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

interface AutoLogoutMonitorProps {
  children: ReactNode;
  logoutUrl?: string;
}

export const AutoLogoutMonitor = ({
  children,
  logoutUrl = '/logged-out?showInactivityLogOutMessage=true',
}: AutoLogoutMonitorProps) => {
  const autoLogoutWatcher = useRef<NodeJS.Timeout>();
  const [isWarningOpen, setIsWarningOpen] = useState<boolean>(false);
  const [remainingSeconds, setRemainingSeconds] = useState<number>(0);
  const lastEventTimestamp = useRef<number>(new Date().getTime());
  const handleOnInterval = useRef<Function>(() => {});

  const forceLogoutDuration = 900000; //15 mins
  const warningDuration = 600000; //10 mins

  const msalClient = useMsal();

  handleOnInterval.current = () => {
    const inactiveDuration = new Date().getTime() - lastEventTimestamp.current;

    if (inactiveDuration > forceLogoutDuration + 1000) {
      msalClient.instance.logoutRedirect({
        postLogoutRedirectUri: logoutUrl,
      });

      return;
    } else if (!isWarningOpen && inactiveDuration > warningDuration) {
      setRemainingSeconds((forceLogoutDuration - warningDuration) / 1000);
      setIsWarningOpen(true);

      return;
    }

    setRemainingSeconds(remainingSeconds - 1);
  };

  const handleWindowEvent = useCallback(() => {
    lastEventTimestamp.current = new Date().getTime();
  }, []);

  useEffect(() => {
    const events: (keyof WindowEventMap)[] = [
      'click',
      'scroll',
      'load',
      'keypress',
      'wheel',
      'mousemove',
      'touchmove',
    ];

    if (!isWarningOpen) {
      events.forEach((event) => {
        window.addEventListener(event, handleWindowEvent);
      });
    }

    return () => {
      if (isWarningOpen) {
        return;
      }

      events.forEach((event) => {
        window.removeEventListener(event, handleWindowEvent);
      });
    };
  }, [isWarningOpen, handleWindowEvent]);

  useEffect(() => {
    autoLogoutWatcher.current = setInterval(
      () => handleOnInterval.current(),
      1000
    );

    return () => {
      clearInterval(autoLogoutWatcher.current!);
    };
  }, []);

  return (
    <>
      {children}

      <Dialog
        open={isWarningOpen}
        aria-labelledby='inactivity-warning'
        PaperProps={{
          sx: {
            backgroundColor: '#6B6B6B',
          },
        }}
      >
        <DialogContent
          sx={{
            maxWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: '#FFFFFF',
          }}
        >
          <WarningAmberIcon sx={{ fontSize: 40 }} />
          <DialogContentText
            sx={{
              textAlign: 'center',
              color: '#FFFFFF',
              fontSize: '1.6rem',
              fontWeight: '400',
              fontFamily: '"Source Sans Pro", "Arial", sans-serif',
            }}
          >
            {`Your session is about to expire. You will be logged out in ${Math.floor(
              remainingSeconds / 60
            )} mins ${remainingSeconds % 60} secs`}
          </DialogContentText>
        </DialogContent>
        <ConfirmActions
          handleSubmit={() => {
            setIsWarningOpen(false);
          }}
          submitButton={{
            text: 'Continue Session',
            sx: {
              fontFamily: '"Source Sans Pro", "Arial", sans-serif',
              fontSize: '1.4rem',
              background: 'linear-gradient(to right, #0A42C6, #1C60FF)',
              contrastText: '#FFFFFF',
              color: '#FFFFFF',
              textTransform: 'none',
              margin: '0.8rem',
            },
            variant: 'contained',
          }}
          cancelButton={{
            text: 'Logout',
            sx: {
              fontFamily: '"Source Sans Pro", "Arial", sans-serif',
              fontSize: '1.4rem',
              backgroundColor: '#5C5C5C',
              contrastText: '#FFFFFF',
              color: '#FFFFFF',
              textTransform: 'none',
              margin: '0.8rem',
              '&:hover': {
                backgroundColor: 'rgb(64, 64, 64)',
              },
            },
            variant: 'contained',
          }}
          handleCancel={() => msalClient.instance.logout()}
        />
      </Dialog>
    </>
  );
};

export default AutoLogoutMonitor;
