import { Box, Button } from '@mui/material';

export const RefineYourReportButton = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
    <Button onClick={onClick}>Refine Your Report</Button>
  </Box>
);

export default RefineYourReportButton;
