import { TileProps } from '@newedge/common';
import { TaxInsightTableRowData, TaxInsightsCategories } from './@types';

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const computeToutData = (data: TaxInsightTableRowData[]) => {
  const toutData: TileProps[] = [];
  TaxInsightsCategories.forEach((category) => {
    const categoryData = data.find((o) => o.category === category);
    if (categoryData) {
      const total = categoryData.cashArray.reduce(
        (prev, curr) => prev + curr,
        0
      );
      toutData.push({
        eyebrowText: category,
        headlineText: formatter.format(total),
        color: '',
        eyebrowLinkText: categoryData.eyebrowLinkText,
        eyebrowLinkPath: categoryData.eyebrowLinkPath,
        secondaryFilters: categoryData.secondaryFilters,
      });
    } else {
      toutData.push({
        eyebrowText: category,
        headlineText: 'N/A',
        color: '',
      });
    }
  });

  return toutData;
};

export default computeToutData;
