import { Outlet } from 'react-router';
import { ClientPortalLogo } from '@newedge/common';
import { Box } from '@mui/material';

export function MessageRoot() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Box sx={{ flexGrow: 1, display: 'flex' }}>{<ClientPortalLogo />}</Box>
      </Box>
      <Box
        sx={(theme) => ({
          flexGrow: 2,
          maxWidth: 1350,
          padding: theme.spacing(0, 3),
          margin: 'auto',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        })}
      >
        <Outlet />
      </Box>
    </Box>
  );
}

export default MessageRoot;
