import { Box } from '@mui/material';

interface MobileReportTableHeaderProps {
  title: string;
}

export const MobileReportTableHeader = ({
  title,
}: MobileReportTableHeaderProps) => {
  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundColor: '#505050',
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        ...theme.typography.body1,
      })}
    >
      {title}
    </Box>
  );
};

export default MobileReportTableHeader;
