import { DateTime } from 'luxon';

const dt = DateTime.utc();
const startDate = dt.startOf('month').plus({ month: 1 });
const endDate = dt.startOf('month').plus({ year: 1 });

const dates = [];
let currentDate = startDate;
while (currentDate <= endDate) {
  dates.push(currentDate);
  currentDate = currentDate.plus({ month: 1 });
}

export const mockRelayCashFlowDetailData = {
  getCashFlowDetailProjection: dates
    .map((date) => [
      {
        accountId: 1,
        taxStatusName: 'Taxable',
        isFederalTaxable: false,
        isStateTaxable: true,
        incomeTypeName: 'Dividend',
        incomeEstimateDate: date.toISO(),
        incomeEstimateAmount: 4000,
      },
      {
        accountId: 1,
        taxStatusName: 'Tax Exempt',
        isFederalTaxable: false,
        isStateTaxable: true,
        incomeTypeName: 'Interest',
        incomeEstimateDate: date.toISO(),
        incomeEstimateAmount: 5000,
      },
      {
        accountId: 1,
        taxStatusName: 'Tax Deferred',
        isFederalTaxable: true,
        isStateTaxable: false,
        incomeTypeName: 'Dividend',
        incomeEstimateDate: date.toISO(),
        incomeEstimateAmount: 6000,
      },
    ])
    .flat(),
};

export const mockComputedToutData = [
  {
    eyebrowText: 'Taxable Total',
    headlineText: '$48,000',
    color: '',
  },
  {
    eyebrowText: 'Tax Exempt Total',
    headlineText: '$60,000',
    color: '',
  },
  {
    eyebrowText: 'Tax Deferred Total',
    headlineText: '$72,000',
    color: '',
  },
];

export const mockComputedTableData = [
  {
    category: 'Totals',
    startMonth: dates[0].month,
    cashArray: new Array(12).fill(15000),
  },
  {
    category: 'Taxable',
    startMonth: dates[0].month,
    cashArray: new Array(12).fill(4000),
    details: [
      {
        category: 'Federal Exempt / State Taxable',
        dividends: new Array(12).fill(4000),
        interest: new Array(12).fill(0),
      },
    ],
  },
  {
    category: 'Tax Exempt',
    startMonth: dates[0].month,
    cashArray: new Array(12).fill(5000),
    details: [
      {
        category: 'Federal Exempt / State Taxable',
        dividends: new Array(12).fill(0),
        interest: new Array(12).fill(5000),
      },
    ],
  },
  {
    category: 'Tax Deferred',
    startMonth: dates[0].month,
    cashArray: new Array(12).fill(6000),
    details: [
      {
        category: 'Federal Taxable / State Exempt',
        dividends: new Array(12).fill(6000),
        interest: new Array(12).fill(0),
      },
    ],
  },
];
