import { useNavigate } from 'react-router-dom';
import { NewEdgeLogoTextSvg } from './NewEdgeLogoTextSvg';
import { Box, styled } from '@mui/material';

const logoWidth = 236;
const logoHeight = 86;

const StyledNewEdgeLogoTextSvg = styled(NewEdgeLogoTextSvg)(({ theme }) => ({
  logo: {
    width: logoWidth,
    height: logoHeight,
    fill: theme.palette.text.primary,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: logoWidth / 2,
      height: logoHeight / 2,
    },
  },
}));

interface ClientPortalLogoProps {
  clickPath?: string;
}

export function ClientPortalLogo({
  clickPath = '/my/dashboard',
}: ClientPortalLogoProps) {
  const navigate = useNavigate();
  return (
    <Box
      onClick={() => navigate(clickPath)}
      sx={{ display: 'flex', flexDirection: 'column' }}
    >
      <Box
        sx={(theme) => ({
          flex: 1,
          paddingBottom: theme.spacing(0.5),
          display: 'inline-flex',
          '& .small': {},
        })}
      >
        <StyledNewEdgeLogoTextSvg preserveAspectRatio={'xMinYMin slice'} />
      </Box>
    </Box>
  );
}
