import { Box, ClickAwayListener, Popper, Skeleton } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { Key, useCallback, useEffect, useMemo, useState } from 'react';
import {
  formatNumber,
  NumberFormatType,
} from '../../../utils/numberFormatters';
import NotAvailableTypography from '../../typography/NotAvailableTypography';
import { ColumnDataType, DataAlignment, HeadCell, Order } from './@types';
import { getDefaultComparator, stableSort } from './SortHelpers';
import { DateTime } from 'luxon';
import InfiniteScroll from 'react-infinite-scroll-component';

const StyledTableCell = styled(TableCell)(() => ({
  border: 0,
  verticalAlign: 'middle',
}));

interface SortableTableHeadProps<T> {
  onRequestSort: (property: keyof T) => void;
  order: Order;
  orderBy: string | number | symbol;
  headCells: HeadCell<T>[];
  setOrder: (order: Order) => void;
  setOrderBy: (orderBy: keyof T) => void;
}

const SortableTableHead = <T,>(props: SortableTableHeadProps<T>) => {
  const { order, orderBy, onRequestSort, headCells, setOrder, setOrderBy } =
    props;
  const createSortHandler = (property: keyof T) => () => {
    onRequestSort(property);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [headerId, setHeaderId] = useState('');

  const renderCustomSort = (
    customSortRenderer: HeadCell<T>['customSortRenderer'],
    sortId: string
  ) => {
    return (
      <Popper
        id='sort-selection-popper'
        open={Boolean(anchorEl) && sortId === headerId}
        anchorEl={anchorEl}
        disablePortal
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <>
            {customSortRenderer?.(setOrder, setOrderBy, () =>
              setAnchorEl(null)
            )}
          </>
        </ClickAwayListener>
      </Popper>
    );
  };

  const isActive = (headCell: HeadCell<T>) => {
    return (
      orderBy === headCell.id ||
      (headCell.relatedIds && headCell.relatedIds.includes(orderBy as keyof T))
    );
  };

  const hiddenText =
    order === Order.Desc ? 'sorted descending' : 'sorted ascending';

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell: HeadCell<T>) => {
          return (
            <StyledTableCell
              key={headCell.id as Key}
              align='left'
              sortDirection={isActive(headCell) ? order : false}
              sx={(theme: Theme) => ({ bgcolor: theme.extensions.grey[20] })}
            >
              <TableSortLabel
                sx={(theme) => {
                  return {
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                    alignItems: 'start',
                    ...theme.typography.body1,
                  };
                }}
                active={isActive(headCell)}
                direction={isActive(headCell) ? order : Order.Asc}
                onClick={
                  headCell.customSortRenderer
                    ? (event: React.MouseEvent<HTMLElement>) => {
                        setAnchorEl(
                          anchorEl ? null : event.currentTarget.parentElement
                        );
                        setHeaderId(headCell.id as string);
                      }
                    : createSortHandler(headCell.id)
                }
                IconComponent={ArrowDropDownIcon}
                hideSortIcon={!!headCell.customSortRenderer}
              >
                {headCell.label}
                {isActive(headCell) ? (
                  <Box
                    component='span'
                    sx={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                      width: 1,
                    }}
                  >
                    {hiddenText}
                  </Box>
                ) : null}
              </TableSortLabel>
              {headCell.customSortRenderer
                ? renderCustomSort(
                    headCell.customSortRenderer,
                    headCell.id as string
                  )
                : null}
            </StyledTableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const NO_DATA_MESSAGE = 'No data meets the selection criteria.';

interface SortableTableProps<T> {
  headCells: HeadCell<T>[];
  dataRows: T[];
  initialSortColumn: keyof T;
  initialSortDirection?: Order;
  keyFields?: (keyof T)[];
  stickyHeader?: boolean;
  showNoDataMessage?: boolean;
}

export const SortableTable = <T,>({
  headCells,
  dataRows,
  initialSortColumn,
  initialSortDirection = Order.Asc,
  keyFields = [],
  stickyHeader = false,
  showNoDataMessage = false,
}: SortableTableProps<T>) => {
  const [order, setOrder] = React.useState<Order>(initialSortDirection);
  const [orderBy, setOrderBy] = React.useState<keyof T>(initialSortColumn);

  const handleRequestSort = useCallback(
    (property: keyof T) => {
      const isAsc = orderBy === property && order === Order.Asc;
      setOrder(isAsc ? Order.Desc : Order.Asc);
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const getFormattedText = useCallback(
    (value: number | string, headCell: HeadCell<T>): string | null => {
      if (headCell.transformFn) {
        return headCell.transformFn(value as number);
      }

      if (!value && headCell.nullDisplay) {
        return headCell.nullDisplay;
      }

      if (value === null || value === undefined) {
        return null;
      }

      switch (headCell.dataType) {
        case ColumnDataType.Date:
          return typeof value === 'string'
            ? DateTime.fromISO(value).toFormat('M/d/yyyy')
            : '';
        case ColumnDataType.Currency:
          return formatNumber(
            value as number,
            NumberFormatType.Currency,
            headCell.precision ?? 2
          );
        case ColumnDataType.Percent:
          return formatNumber(
            value as number,
            NumberFormatType.Percent,
            headCell.precision,
            headCell.minimumDecimalToDisplay
          );
        case ColumnDataType.Number:
          return formatNumber(
            value as number,
            NumberFormatType.Number,
            headCell.precision,
            headCell.minimumDecimalToDisplay
          );
      }
      return value as string;
    },
    []
  );

  const format = useCallback(
    (value: number | string, headCell: HeadCell<T>) => {
      const formattedText = getFormattedText(value, headCell);

      if (
        headCell.maxLength &&
        formattedText &&
        formattedText.length > headCell.maxLength
      ) {
        return (
          <Tooltip
            sx={(theme) => ({ ...theme.typography.caption })}
            title={<Typography variant='caption'>{formattedText}</Typography>}
          >
            <Typography sx={{ overflowWrap: 'anywhere' }}>
              {`${formattedText.substring(0, headCell.maxLength)}...`}
            </Typography>
          </Tooltip>
        );
      }

      return <NotAvailableTypography>{formattedText}</NotAvailableTypography>;
    },
    [getFormattedText]
  );

  const getSortComparator = useCallback(() => {
    const sortHeadCell = headCells.find(
      (headCell) =>
        headCell.id === orderBy ||
        (headCell.relatedIds && headCell.relatedIds?.some((x) => x === orderBy))
    );

    if (sortHeadCell?.customSortComparator) {
      return sortHeadCell.customSortComparator(order, orderBy);
    }

    return getDefaultComparator(order, orderBy);
  }, [headCells, order, orderBy]);

  const sortedDataRows = useMemo(() => {
    return stableSort<T>(
      dataRows,
      getSortComparator() as (a: T, b: T) => number
    );
  }, [dataRows, getSortComparator]);

  const [renderedData, setRenderedData] = useState<T[]>(
    sortedDataRows.slice(0, 50)
  );

  useEffect(() => {
    setRenderedData(sortedDataRows.slice(0, 50));
  }, [sortedDataRows]);

  const groupSize = 50;
  const loadMoreItems = () => {
    setRenderedData(
      renderedData.concat(
        ...sortedDataRows.slice(
          renderedData.length,
          renderedData.length + groupSize
        )
      )
    );
  };

  const sortedTableRows = useMemo(() => {
    return renderedData.map((row, index) => {
      let key: Key = `${typeof row}_${index}`;
      if (keyFields.length > 0) {
        key = keyFields.map((k) => row[k]).join('_');
      }
      return (
        <TableRow
          key={key}
          sx={{
            '&:nth-of-type(odd)': {
              bgcolor: 'greyPercent.8',
            },
          }}
        >
          {headCells.map((headCell) => {
            return (
              <StyledTableCell
                key={`${String(headCell.id)}_${row[headCell.id]}`}
                align={
                  headCell.dataAlignment === DataAlignment.Right
                    ? 'right'
                    : 'left'
                }
              >
                {!!headCell.icon && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'right',
                    }}
                  >
                    {headCell.icon(row)}
                    {headCell.customCellRenderer
                      ? headCell.customCellRenderer?.(
                          row[headCell.id],
                          headCell,
                          row,
                          format
                        )
                      : format(row[headCell.id] as number | string, headCell)}
                  </Box>
                )}
                {!headCell.icon && (
                  <Box>
                    {headCell.customCellRenderer
                      ? headCell.customCellRenderer?.(
                          row[headCell.id],
                          headCell,
                          row,
                          format
                        )
                      : format(row[headCell.id] as number | string, headCell)}
                  </Box>
                )}
              </StyledTableCell>
            );
          })}
        </TableRow>
      );
    });
  }, [renderedData, format, headCells, keyFields]);

  return (
    <Paper>
      <InfiniteScroll
        next={loadMoreItems}
        hasMore={renderedData.length < sortedDataRows.length}
        loader={<></>}
        dataLength={renderedData.length}
      >
        <TableContainer
          sx={{ overflowX: stickyHeader ? 'initial' : undefined }}
        >
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby='tableTitle'
            aria-label='sortable table'
            stickyHeader={stickyHeader}
          >
            <SortableTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
            />

            <TableBody>{sortedTableRows}</TableBody>
          </Table>
        </TableContainer>
      </InfiniteScroll>
      {renderedData.length < sortedDataRows.length ? (
        <Skeleton height={250} width='100%' />
      ) : null}
      {showNoDataMessage && sortedDataRows.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
          {NO_DATA_MESSAGE}
        </Box>
      ) : null}
    </Paper>
  );
};

export default SortableTable;
