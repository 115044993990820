import { useCallback } from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import { useLogger } from '@newedge/common';
import { EndSessionRequest, RedirectRequest } from '@azure/msal-browser';

export const useAuth = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const logger = useLogger('Auth');
  const login = useCallback(
    async (request?: RedirectRequest) => {
      // TODO: login silent first, then redirect
      instance.loginRedirect(request).catch((e: any) => {
        logger.error(e);
      });
    },
    [instance, logger]
  );

  const logout = useCallback(
    async (logoutRequest?: EndSessionRequest) => {
      instance.logoutRedirect(logoutRequest).catch((e: any) => {
        logger.error(e);
      });
    },
    [instance, logger]
  );

  const getAccessToken = useCallback(
    async (parameters?: any) => {
      return instance.acquireTokenSilent({
        scopes: parameters?.scopes ?? [],
      });
    },
    [instance]
  );

  return {
    login,
    logout,
    account,
    getAccessToken,
    msalInstance: instance,
    inProgress,
  };
};

export default useAuth;
