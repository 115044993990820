import { RefObject } from 'react';
import { SxProps, Theme, styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionActions,
  AccordionActionsProps,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const StyledAccordion = styled(Accordion)(
  ({ theme: { palette, breakpoints } }) => ({
    width: '100%',
    '&::before': {
      opacity: 0,
    },
    borderRadius: '5px',
    '&.Mui-expanded': {
      margin: 0,
    },
    backgroundColor: palette.transparentBackground?.main,
    [breakpoints.down('sm')]: {
      scrollMargin: '95px',
    },
  })
);

type AccordionItemProps = {
  accordionProps?: Partial<AccordionProps> & { 'data-testid'?: string };
  accordionSummaryProps?: Partial<AccordionSummaryProps>;
  accordionDetailsProps?: Partial<AccordionDetailsProps>;
  accordionActionsProps?: Partial<AccordionActionsProps>;
  summaryComponent: React.ReactElement;
  detailsComponent: React.ReactElement;
  isExpanded?: boolean;
  onChange?: (event: object, expanded: boolean) => void;
  expandIcon?: React.ReactElement;
  renderRef?: RefObject<HTMLDivElement & { expandAccordition: () => void }>;
  defaultIconSize?: number;
  sx?: SxProps<Theme>;
};

export function AccordionItem({
  summaryComponent,
  detailsComponent,
  accordionActionsProps,
  accordionDetailsProps,
  accordionSummaryProps,
  accordionProps,
  isExpanded,
  onChange,
  expandIcon,
  defaultIconSize = 40,
  renderRef = undefined,
}: AccordionItemProps) {
  return (
    <StyledAccordion
      expanded={isExpanded}
      onChange={onChange}
      {...accordionProps}
      ref={renderRef}
    >
      <AccordionSummary
        expandIcon={
          expandIcon ? (
            expandIcon
          ) : (
            <ExpandMoreIcon style={{ fontSize: defaultIconSize }} />
          )
        }
        {...accordionSummaryProps}
        sx={[
          {
            flexDirection: 'row-reverse',
            borderRadius: '5px',
          },
          ...(Array.isArray(accordionSummaryProps?.sx) &&
          accordionSummaryProps?.sx
            ? accordionSummaryProps.sx
            : [accordionSummaryProps?.sx]),
        ]}
      >
        {summaryComponent}
      </AccordionSummary>
      <AccordionDetails {...accordionDetailsProps}>
        {detailsComponent}
      </AccordionDetails>
      {accordionActionsProps && <AccordionActions {...accordionActionsProps} />}
    </StyledAccordion>
  );
}

export default AccordionItem;
