import { Box, Button, ButtonBase, Modal, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import { Checkbox, FormControlLabel } from '@mui/material';
import _ from 'lodash';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ClientPortalFinancialAccount,
  UserProfileAccountGroup,
} from 'src/@types/global';
import { newEdgeNamedColors } from '@newedge/theme';
import { TooltipWrapper } from '../../utils';

const StyledCheckbox = styled(Checkbox)({
  color: '#F0F0F0 !important',
  '& .Mui-checked': {
    color: '#F0F0F0 !important',
  },
  '& svg': {
    fontSize: '2.4rem',
  },
});

interface AccountGroupOption {
  name: string;
  accounts: AccountOption[];
}

interface AccountOption {
  name: string;
  financialAccountId: number;
}

interface GlobalAccountSelectProps {
  accounts: ClientPortalFinancialAccount[];
  accountGroups: UserProfileAccountGroup[];
  selectedAccounts: number[];
  setSelectedAccounts: (accounts: number[]) => void;
  allAccounts: number[];
}

export const GlobalAccountSelect = ({
  accounts,
  accountGroups,
  selectedAccounts,
  setSelectedAccounts,
  allAccounts,
}: GlobalAccountSelectProps) => {
  const [selectedAccountstoBeApplied, setSelectedAccountstoBeApplied] =
    useState(selectedAccounts);

  useEffect(() => {
    setSelectedAccountstoBeApplied(selectedAccounts);
  }, [selectedAccounts]);

  const accountsCopy = accounts.slice();

  const sortedAccountGroups = useMemo((): AccountGroupOption[] => {
    return _.orderBy(accountGroups, [(group) => group.name.toLowerCase()]).map(
      (group) => {
        const newAcctList: AccountOption[] = [];
        group.accounts.forEach((acct) => {
          const index = accountsCopy.findIndex(
            (o) => o.financialAccountId === acct.financialAccountId
          );
          if (index > -1) {
            const accountInfo = accountsCopy[index];
            newAcctList.push({
              name: accountInfo.nicknameEntry
                ? accountInfo.nicknameEntry.accountNickname
                : accountInfo.accountName,
              financialAccountId: accountInfo.financialAccountId,
            });
            accountsCopy.splice(index, 1);
          }
        });
        const newGroup: AccountGroupOption = {
          name: group.name,
          accounts: _.orderBy(newAcctList, [(acct) => acct.name.toLowerCase()]),
        };
        return newGroup;
      }
    );
  }, [accountsCopy, accountGroups]);

  const ungroupedAccounts: AccountOption[] = _.orderBy(
    accountsCopy.map((acct) => {
      return {
        name: acct.nicknameEntry
          ? acct.nicknameEntry.accountNickname
          : acct.accountName,
        financialAccountId: acct.financialAccountId,
      };
    }),
    [(acct) => acct.name.toLowerCase()]
  );

  const isAllAccountsChecked = _.isEqual(
    selectedAccountstoBeApplied,
    allAccounts
  );

  const [label, setLabel] = useState('All Accounts');

  const selectedAccountGroups: AccountGroupOption[] = useMemo(() => {
    const groups: AccountGroupOption[] = [];
    sortedAccountGroups.forEach((group) => {
      if (
        group.accounts.length > 0 &&
        group.accounts.every((account) =>
          selectedAccountstoBeApplied.includes(account.financialAccountId)
        )
      ) {
        groups.push(group);
      }
    });
    return groups;
  }, [selectedAccountstoBeApplied, sortedAccountGroups]);

  useEffect(() => {
    if (isAllAccountsChecked) {
      setLabel('All Accounts');
    } else if (selectedAccountstoBeApplied.length === 0) {
      setLabel('');
    } else if (selectedAccountstoBeApplied.length === 1) {
      const acct = accounts.find(
        (o) => o.financialAccountId === selectedAccountstoBeApplied[0]
      );
      if (acct) {
        setLabel(
          acct.nicknameEntry
            ? acct.nicknameEntry.accountNickname
            : acct.accountName
        );
      }
    } else if (
      selectedAccountGroups.length === 1 &&
      selectedAccountstoBeApplied.every(
        (acct) =>
          selectedAccountGroups[0].accounts.findIndex(
            (o) => o.financialAccountId === acct
          ) !== -1
      )
    ) {
      setLabel(selectedAccountGroups[0].name);
    } else {
      setLabel('Multiple Accounts Selected');
    }
  }, [
    accounts,
    isAllAccountsChecked,
    selectedAccountGroups,
    selectedAccounts,
    selectedAccountstoBeApplied,
    sortedAccountGroups,
    ungroupedAccounts,
  ]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (anchorEl && selectedAccountstoBeApplied.length === 0) {
      setSelectedAccountstoBeApplied(allAccounts);
    }
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const renderAccountOption = useCallback(
    (account: AccountOption, nested: boolean = false) => {
      return (
        <FormControlLabel
          key={account.financialAccountId}
          sx={{
            '& .MuiFormControlLabel-label': (theme) => ({
              ...theme.typography.body1,
            }),
            marginLeft: '1rem !important',
            width: '90%',
            paddingLeft: nested ? '20px' : undefined,
          }}
          disableTypography
          label={
            <TooltipWrapper
              text={account.name}
              maxLength={72}
              typographyVariant='link'
            />
          }
          control={
            <StyledCheckbox
              checked={selectedAccountstoBeApplied.includes(
                account.financialAccountId
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                if (event.target.checked) {
                  setSelectedAccountstoBeApplied(
                    [
                      ...new Set([
                        ...selectedAccountstoBeApplied,
                        account.financialAccountId,
                      ]),
                    ].sort()
                  );
                } else {
                  setSelectedAccountstoBeApplied(
                    selectedAccountstoBeApplied.filter(
                      (value) => account.financialAccountId !== value
                    )
                  );
                }
              }}
            />
          }
        />
      );
    },
    [selectedAccountstoBeApplied, setSelectedAccountstoBeApplied]
  );

  const renderAccountGroupOption = useCallback(
    (group: AccountGroupOption) => {
      const isGroupChecked = group.accounts.every((account) =>
        selectedAccountstoBeApplied.includes(account.financialAccountId)
      );
      const isGroupIndeterminate =
        !isGroupChecked &&
        group.accounts.some((account) =>
          selectedAccountstoBeApplied.includes(account.financialAccountId)
        );
      return (
        <div key={group.name}>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': (theme) => ({
                ...theme.typography.body1,
              }),
              marginLeft: '1rem !important',
              width: '90%',
            }}
            disableTypography
            label={group.name}
            control={
              <StyledCheckbox
                checked={isGroupChecked}
                indeterminate={isGroupIndeterminate}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  if (event.target.checked) {
                    setSelectedAccountstoBeApplied(
                      [
                        ...new Set([
                          ...selectedAccountstoBeApplied,
                          ...group.accounts.map(
                            (account) => account.financialAccountId
                          ),
                        ]),
                      ].sort()
                    );
                  } else {
                    setSelectedAccountstoBeApplied(
                      selectedAccountstoBeApplied.filter(
                        (account) =>
                          !group.accounts.some(
                            (accountToTestForUnselection) =>
                              accountToTestForUnselection.financialAccountId ===
                              account
                          )
                      )
                    );
                  }
                }}
              />
            }
          />
          {group.accounts.map((account) => {
            return renderAccountOption(account, true);
          })}
        </div>
      );
    },
    [
      renderAccountOption,
      selectedAccountstoBeApplied,
      setSelectedAccountstoBeApplied,
    ]
  );

  const renderAccountCheckboxList = useCallback(() => {
    return (
      <Paper>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              margin: '0 2rem',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': (theme) => ({
                  ...theme.typography.body1,
                }),
              }}
              disableTypography
              label='All Accounts'
              control={
                <StyledCheckbox
                  checked={isAllAccountsChecked}
                  indeterminate={
                    !isAllAccountsChecked &&
                    selectedAccountstoBeApplied.length > 0
                  }
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                      setSelectedAccountstoBeApplied(allAccounts);
                    } else {
                      setSelectedAccountstoBeApplied([]);
                    }
                  }}
                />
              }
            />
            <Button
              size='small'
              sx={{ py: 1 }}
              onClick={() => setSelectedAccountstoBeApplied([])}
            >
              Clear All
            </Button>
          </Box>
          {sortedAccountGroups.map((group) => {
            if (group.accounts.length > 0) {
              return renderAccountGroupOption(group);
            }
            return null;
          })}
          {ungroupedAccounts.map((acct) => {
            return renderAccountOption(acct);
          })}
        </Box>
      </Paper>
    );
  }, [
    allAccounts,
    isAllAccountsChecked,
    renderAccountGroupOption,
    renderAccountOption,
    selectedAccountstoBeApplied,
    setSelectedAccountstoBeApplied,
    sortedAccountGroups,
    ungroupedAccounts,
  ]);

  const onClose = useCallback(() => {
    setAnchorEl(null);
    if (selectedAccountstoBeApplied.length === 0) {
      setSelectedAccountstoBeApplied(allAccounts);
      setSelectedAccounts(allAccounts);
    } else {
      setSelectedAccounts(selectedAccountstoBeApplied);
    }
  }, [selectedAccountstoBeApplied, setSelectedAccounts, allAccounts]);

  const renderModal = () => {
    return (
      <Modal open={open} onClose={onClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={(theme) => ({
              border: '1px solid #444444',
              boxShadow: '0 3px 12px rgba(27,31,35,.15)',
              borderTopLeftRadius: 'inherit',
              borderTopRightRadius: 'inherit',
              [`${theme.breakpoints.down('md')} and (orientation: landscape)`]:
                {
                  height: '25rem',
                },
              width: 300,
              zIndex: 1,
              fontSize: 14,
              maxHeight: '400px',
              overflowY: 'scroll',
            })}
          >
            {renderAccountCheckboxList()}
          </Box>
          <ButtonBase
            sx={{
              height: '42px',
              width: '100%',
              backgroundColor: '#333333',
              border: '1px solid #444444',
              borderTop: 'unset',
              borderBottomLeftRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
            }}
            onClick={onClose}
          >
            <span>See results</span>
            <ArrowForwardIcon sx={{ marginLeft: '5px' }} fontSize='small' />
          </ButtonBase>
        </Box>
      </Modal>
    );
  };

  return (
    <Box sx={{ minWidth: '300px', maxWidth: '435px' }}>
      <ButtonBase
        onClick={handleClick}
        sx={{
          width: '100%',
          borderRadius: '10px',
          height: '42px',
          display: 'flex',
        }}
      >
        <Box
          component='span'
          sx={{
            width: '45px',
            height: '100%',
            backgroundColor: (theme) => theme.palette.greyPercent[28],
            color: newEdgeNamedColors.lightCadet,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderTopLeftRadius: 'inherit',
            borderBottomLeftRadius: 'inherit',
          }}
        >
          <FolderSharedIcon fontSize='large' />
        </Box>
        <Box
          component='span'
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            backgroundColor: (theme) => theme.palette.greyPercent[10],
            color: newEdgeNamedColors.lightCadet,
            paddingLeft: 2,
            borderTopRightRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
          }}
        >
          <TooltipWrapper
            text={label}
            maxLength={72}
            typographyVariant='body1'
          />
        </Box>
      </ButtonBase>
      {renderModal()}
    </Box>
  );
};

export default GlobalAccountSelect;
