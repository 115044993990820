import { Typography, Theme, useMediaQuery, Box } from '@mui/material';
import {
  ColumnDataType,
  HeadCell,
  MobileReportTable,
  MobileReportTableColumn,
  Order,
  SortableTable,
  SortBySelect,
  getDefaultComparator,
  stableSort,
  formatNumber,
  NumberFormatType,
  TooltipWrapper,
} from '@newedge/common';
import { AccountBalancesModel } from '../@types';

interface AccountsOnlyTableProps {
  data?: AccountBalancesModel[];
}

export const AccountsOnlyTable = ({ data }: AccountsOnlyTableProps) => {
  const headCells: HeadCell<AccountBalancesModel>[] = [
    {
      id: 'accountNickname',
      dataType: ColumnDataType.String,
      label: 'Account Name',
      maxLength: 72,
    },
    {
      id: 'managementStyle',
      dataType: ColumnDataType.String,
      label: 'Management Style',
    },
    {
      id: 'accountType',
      dataType: ColumnDataType.String,
      label: 'Account Type',
    },
    {
      id: 'marketValue',
      dataType: ColumnDataType.Currency,
      label: 'Market Value',
    },
    {
      id: 'cashBalance',
      dataType: ColumnDataType.Currency,
      label: 'Cash & Cash Equivalents',
    },
    {
      id: 'totalBalance',
      dataType: ColumnDataType.Currency,
      label: 'Total Balance',
    },
    {
      id: 'unrealizedGl',
      relatedIds: ['unrealizedGlPercent'],
      dataType: ColumnDataType.Currency,
      label: 'Unrealized G/L',
      customSortRenderer: (setOrder, setOrderBy, close) => {
        return (
          <SortBySelect
            options={[
              {
                direction: Order.Asc,
                displayValue: 'Dollar: Lowest to Highest',
                onClick: () => {
                  setOrder(Order.Asc);
                  setOrderBy('unrealizedGl');
                  close();
                },
              },
              {
                direction: Order.Desc,
                displayValue: 'Dollar: Highest to Lowest',
                onClick: () => {
                  setOrder(Order.Desc);
                  setOrderBy('unrealizedGl');
                  close();
                },
              },
              {
                direction: Order.Asc,
                displayValue: 'Percent: Lowest to Highest',
                onClick: () => {
                  setOrder(Order.Asc);
                  setOrderBy('unrealizedGlPercent');
                  close();
                },
              },
              {
                direction: Order.Desc,
                displayValue: 'Percent: Highest to Lowest',
                onClick: () => {
                  setOrder(Order.Desc);
                  setOrderBy('unrealizedGlPercent');
                  close();
                },
              },
            ]}
            close={close}
          />
        );
      },
      customCellRenderer: (value, headCell, row, format) => {
        return (
          <>
            <div>{format(value, headCell)}</div>
            <div>
              {format(row.unrealizedGlPercent, {
                id: 'unrealizedGlPercent',
                dataType: ColumnDataType.Percent,
                label: 'Unrealized G/L (%)',
                precision: 2,
                minimumDecimalToDisplay: 2,
              })}
            </div>
          </>
        );
      },
    },
    {
      id: 'dailyChange',
      dataType: ColumnDataType.Percent,
      label: 'Daily Change',
      precision: 2,
      minimumDecimalToDisplay: 2,
      customCellRenderer: (value, headCell, row, format) => {
        if (value === undefined || value === null) {
          return <Typography>N/A</Typography>;
        }
        return (
          <Box sx={{ display: 'flex' }}>
            {value > 0 ? <Typography>+</Typography> : null}
            <Typography>{format(value * 100, headCell)}</Typography>
          </Box>
        );
      },
    },
  ];

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const mobileReportColumns: MobileReportTableColumn<AccountBalancesModel>[] = [
    {
      id: 'marketValue',
      label: 'Market Value',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '10rem' },
    },
    {
      id: 'cashBalance',
      label: 'Cash & Cash Equiv.',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '12rem' },
    },
    {
      id: 'totalBalance',
      label: 'Total Balance',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '10rem' },
    },
    {
      id: 'unrealizedGl',
      label: 'Unrealized G/L',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '12rem' },
      customValueRenderer: (value, row) => {
        return `${
          value !== undefined && value !== null
            ? formatNumber(value as number)
            : 'N/A'
        } (${
          row?.unrealizedGlPercent !== undefined &&
          row?.unrealizedGlPercent !== null
            ? formatNumber(
                row?.unrealizedGlPercent,
                NumberFormatType.Percent,
                2
              )
            : 'N/A'
        })`;
      },
    },
    {
      id: 'dailyChange',
      label: 'Daily Change',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => {
        if (value === undefined || value === null) {
          return <Typography>N/A</Typography>;
        }
        return (
          <Box sx={{ display: 'flex' }}>
            {value && (value as number) > 0 ? <Typography>+</Typography> : null}
            <Typography>
              {formatNumber(
                (value as number) * 100,
                NumberFormatType.Percent,
                2,
                2
              )}
            </Typography>
          </Box>
        );
      },
    },
  ];

  if (data) {
    if (isMobileOrTablet) {
      const sortedData = stableSort(
        data,
        getDefaultComparator(Order.Desc, 'totalBalance')
      );
      return (
        <MobileReportTable
          zebra
          title='Account Balances'
          columns={mobileReportColumns}
          data={sortedData}
          headerId='accountNickname'
          customRowHeaderRenderer={(row) => {
            return (
              <TooltipWrapper text={row.accountNickname ?? ''} maxLength={72} />
            );
          }}
        />
      );
    }
    return (
      <SortableTable
        headCells={headCells}
        dataRows={data}
        initialSortColumn='totalBalance'
        initialSortDirection={Order.Desc}
      />
    );
  }
  return <></>;
};

export default AccountsOnlyTable;
