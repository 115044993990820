import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import {
  FilterConfig,
  FilterItem,
  TableFilter,
  UserProfileAccountGroup,
  ClientPortalFinancialAccount,
  ToutsWidget,
  filterSelected,
  RefineYourReportButton,
  ExcelAccountItem,
  ExportToExcelButton,
} from '@newedge/common';
import { AccountBalancesModel } from './@types';
import {
  AccountBalancesExcelData,
  computeAccountBalancesAccountsOnlyExcelData,
  computeAccountBalancesByAccountGroupExcelData,
  computeAccountBalancesToutData,
  projectAccountGroupBalances,
} from './AccountBalancesHelper';
import { AccountGroupTable } from './table/AccountGroupTable';
import { AccountsOnlyTable } from './table/AccountsOnlyTable';
import { useMemo, useRef } from 'react';
import { defaultReportDisclosuresTextLines } from '../../assets/text/ReportDisclosures';

const getFilterOptions = (
  accounts: ClientPortalFinancialAccount[],
  filterValues: FilterItem[]
): FilterConfig => {
  const managementStyles = [...new Set(accounts.map((o) => o.managementStyle))];
  return {
    config: [
      {
        header: 'Management Style',
        selections: filterSelected(
          managementStyles.map((name) => {
            return {
              searchValue: accounts
                .filter((account) => account.managementStyle === name)
                .map((account) => account.financialAccountId),
              predicateIdentifier: 'accountId',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
    ],
  };
};

interface AccountBalancesViewProps {
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
  date: any;
  accountBalances: AccountBalancesModel[];
  accounts: ClientPortalFinancialAccount[];
  accountGroups: UserProfileAccountGroup[];
  selectedAccounts: number[];
  disableLinks?: boolean;
}

export const AccountBalancesView = ({
  filterValues,
  setFilterValues,
  setAsOfDate,
  date,
  accountBalances,
  accounts,
  accountGroups,
  selectedAccounts,
  disableLinks = false,
}: AccountBalancesViewProps) => {
  if (date) {
    setAsOfDate(date);
  }
  const isMobileOrTablet = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('md');
  });

  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  const handlerScrollToTop = () => {
    renderRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    renderRef.current?.expandAccordition();
  };

  const toutsData = computeAccountBalancesToutData(accountBalances);

  const filterConfig = getFilterOptions(accounts, filterValues);

  const accountBalancesDataWithNicknames: AccountBalancesModel[] | undefined =
    accountBalances.map((o) => {
      const nickname = accounts.find(
        (account) => account.nicknameEntry?.financialAccountId === o.accountId
      )?.nicknameEntry?.accountNickname;
      return {
        ...o,
        accountNickname: nickname ? nickname : o.accountDefaultName,
      } as AccountBalancesModel;
    });

  const accountGroupBalanceData = projectAccountGroupBalances(
    accountGroups,
    accountBalancesDataWithNicknames ?? []
  );

  const renderAccountBalancesTable = () => {
    if (accountGroups.length === 0) {
      return <AccountsOnlyTable data={accountBalancesDataWithNicknames} />;
    }

    return (
      <AccountGroupTable
        data={accountGroupBalanceData}
        disableLinks={disableLinks}
      />
    );
  };

  const selectedAccountList = useMemo(() => {
    const accountList: ExcelAccountItem[] = [];
    selectedAccounts.forEach((acctId) => {
      const accountInfo = accounts.find((o) => o.financialAccountId === acctId);
      const balanceInfo = accountBalances.find((o) => o.accountId === acctId);
      if (accountInfo && balanceInfo) {
        accountList.push({
          accountNumber: accountInfo.accountNumber,
          accountName: accountInfo.nicknameEntry
            ? accountInfo.nicknameEntry.accountNickname
            : accountInfo.accountName,
          accountBalance: balanceInfo.totalBalance,
          custodian: accountInfo.custodian ? accountInfo.custodian : '',
        });
      }
    });
    return accountList;
  }, [accounts, selectedAccounts]);

  const filenamePrefix = 'AccountBalances';
  const dataSheetTitle = 'Account Balances';

  const excelData = useMemo(() => {
    if (accountGroups.length === 0) {
      return computeAccountBalancesAccountsOnlyExcelData(
        accountBalancesDataWithNicknames
      );
    }
    return computeAccountBalancesByAccountGroupExcelData(
      accountGroupBalanceData
    );
  }, [accountBalancesDataWithNicknames]);

  const columnsToAutoSize: (keyof AccountBalancesExcelData)[] = [
    'Account Name',
    'Management Style',
    'Account Type',
    'Market Value',
    'Cash & Cash Equivalents',
    'Total Balance',
    'Unrealized G/L',
    'Daily Change',
  ];

  if (accountGroups.length > 0) {
    columnsToAutoSize.unshift('Account Group');
  }

  const renderExcelButton = () => {
    return (
      !isMobileOrTablet ? (
      <Grid item xs={4}>
        <Box sx={{ width: '100%', display: 'grid' }}>
          <Box sx={{ justifySelf: 'flex-end' }}>
            <ExportToExcelButton<AccountBalancesExcelData>
              data={excelData}
              accounts={selectedAccountList}
              accountListColumns={[
                {
                  key: 'accountNumber',
                  label: 'Account Number',
                },
                {
                  key: 'accountName',
                  label: 'Account Name',
                },
                {
                  key: 'accountBalance',
                  label: 'Account Balance',
                },
                {
                  key: 'custodian',
                  label: 'Custodian',
                },
              ]}
              filenamePrefix={filenamePrefix}
              dataSheetTitle={dataSheetTitle}
              columnsToAutoSize={columnsToAutoSize}
              disclosuresTextLines={defaultReportDisclosuresTextLines}
            />
          </Box>
        </Box>
      </Grid>
      ) : null
    );
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent='space-between'
      alignItems='center'
    >
      <ToutsWidget data={toutsData} />
      <Grid item xs={isMobileOrTablet ? 12 : 8}>
        <TableFilter
          values={filterValues}
          setValues={setFilterValues}
          config={filterConfig}
          renderRef={renderRef}
        />
      </Grid>
      {renderExcelButton()}
      <Grid item xs={12}>
        {renderAccountBalancesTable()}
        {isMobileOrTablet && (
          <RefineYourReportButton onClick={handlerScrollToTop} />
        )}
      </Grid>
    </Grid>
  );
};
