// ! Do not use the types in this file directly
// the only way this should be interacted with is through the theme in material-ui via useTheme, makeStyles, etc

import { namedColors } from './namedColors';

export const macgColors = {
  background: namedColors.white,

  // TODO: I think this is a good indication that we have too many colors and should revisit this
  backgroundContrastLight: namedColors.alabaster,
  backgroundContrast: namedColors.wildSand,
  backgroundContrastDark: namedColors.emperor,

  // TODO: I think this is a good indication that we have too many colors and should revisit this
  borderDarkest: namedColors.mineShaft,
  borderDarker: namedColors.tundora,
  borderDark: namedColors.boulder,
  borderLight: namedColors.lightGray,
  borderLightest: namedColors.alto,

  primary: namedColors.teal,

  lightGrayBorder: '#E4E4E4',

  highlight: namedColors.eucalyptus,

  iconPrimary: namedColors.boulder,
  icon: {
    // TODO: I think this is a good indication that we have too many colors and should revisit this
    dark: namedColors.emperor,
    dark2: namedColors.zambezi,
  },

  accordionIndicator: namedColors.orange,

  tableAltRow: namedColors.hintOfRed,
  warn: namedColors.navajoWhite,
  info: namedColors.cornflowerBlue,
  text: {
    primary: '#212020',
    dark: namedColors.black,
    contrast: namedColors.white,
  },
  data: {
    backgroundWhite: namedColors.white,
    comparisonWhite: namedColors.wildSand,
    data1: namedColors.teal,
    data1Light: namedColors.lightTeal,
    data2: namedColors.orange,
    data2Light: namedColors.lightOrange,
    data3: namedColors.eucalyptus,
    data3Light: namedColors.lightEucalyptus,
    data4: namedColors.sushi,
    data4Light: namedColors.lightSushi,
    data5: namedColors.gray,
    data5Light: namedColors.lightGray,
  },
};

export type ExtensionColors = typeof macgColors;
