import _ from 'lodash';
import { AssetAllocationRow, AssetAllocationTable } from '../table';
import { useNavigate } from 'react-router-dom';
import { FilterItem } from '@newedge/common';
import { useCallback } from 'react';

type AssetAllocationByManagementViewProps = {
  data: AssetAllocationRow[];
  disableLinks: boolean;
};

const AssetAllocationByManagementView = ({
  data,
  disableLinks,
}: AssetAllocationByManagementViewProps) => {
  const navigate = useNavigate();

  const onViewHoldingsClick = useCallback(
    (row: AssetAllocationRow) => {
      navigate('/my/holdings', {
        state: {
          secondaryFilters: [
            {
              displayValue: 'M/S: ' + row.name,
              predicateIdentifier: 'managementStyle',
              searchValue: row.name,
              filterType: 'text',
            } as FilterItem,
          ],
        },
      });
    },
    [navigate]
  );

  return (
    <AssetAllocationTable
      columns={[
        {
          id: 'name',
          label: 'Management Style',
        },
        {
          id: 'marketValue',
          label: 'Market Value',
        },
        {
          id: 'percent',
          label: '% Of Accounts',
        },
      ]}
      data={data}
      initialSortColumn='name'
      disableLinks={disableLinks}
      onViewHoldingsClick={onViewHoldingsClick}
    />
  );
};

export default AssetAllocationByManagementView;
