import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useCallback, useState } from 'react';
import { ColumnDataType, DataAlignment } from '../sortable-table/@types';
import { ValueOf } from '../@types';
import {
  formatNumber,
  NumberFormatType,
} from '../../../utils/numberFormatters';
import { CompactNestedTableColumn } from './@types';
import { TooltipWrapper } from '../../../utils/TooltipWrapper';

const StyledTableGroupRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: theme.extensions.grey[20],
  backgroundClip: 'padding-box',
  borderTop: '8px solid transparent',
  borderBottom: '8px solid transparent',
  paddingRight: theme.spacing(16),
}));

const StyledTableGroupCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: 'unset',
}));

const StyledNestedTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  fontWeight: 'normal',
  fontSize: '1.6rem',
}));

interface CompactNestedTableRowProps<T, S> {
  columns: CompactNestedTableColumn<T, S>[];
  rowData: T;
}

export const CompactNestedTableRow = <T, S>({
  columns,
  rowData,
}: CompactNestedTableRowProps<T, S>) => {
  const [open, setOpen] = useState<boolean>(true);

  const getFormattedText = useCallback(
    (
      value: ValueOf<T> | ValueOf<S>,
      column: CompactNestedTableColumn<T, S>
    ): string => {
      switch (column.dataType) {
        case ColumnDataType.Currency:
          return formatNumber(
            value as unknown as number,
            NumberFormatType.Currency,
            column.precision ?? 2,
            column.precision ?? 2
          );
        case ColumnDataType.Percent:
          return formatNumber(
            value as unknown as number,
            NumberFormatType.Percent,
            column.precision ?? 2,
            column.precision ?? 2
          );
        case ColumnDataType.Number:
          return formatNumber(
            value as unknown as number,
            NumberFormatType.Number,
            column.precision ?? 2,
            column.precision ?? 2
          );
      }
      return value as unknown as string;
    },
    []
  );

  return (
    <>
      <StyledTableGroupRow>
        <StyledTableGroupCell>
          <IconButton
            aria-label='expand row'
            sx={{ fontSize: '2.4rem', padding: '3px' }}
            onClick={() => setOpen(!open)}
            size='large'
          >
            {open ? (
              <ArrowDropUpIcon fontSize='inherit' />
            ) : (
              <ArrowDropDownIcon fontSize='inherit' />
            )}
          </IconButton>
        </StyledTableGroupCell>
        {columns.map((column, index) => {
          return (
            <StyledTableGroupCell
              align={
                column.dataAlignment === DataAlignment.Right ? 'right' : 'left'
              }
              key={`compact-nested-table-row-column-${index}`}
            >
              <Typography variant='body5'>
                {getFormattedText(rowData[column.id], column)}
              </Typography>
            </StyledTableGroupCell>
          );
        })}
        <StyledTableGroupCell width='5%' />
      </StyledTableGroupRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={columns.length + 2}>
          <Collapse in={open} timeout='auto' mountOnEnter unmountOnExit>
            <Box margin={0}>
              <Table sx={{ tableLayout: 'fixed' }}>
                <TableBody>
                  {columns[0]
                    .nestedAccessor(rowData)
                    .map((nestedRow, index) => {
                      return (
                        <TableRow key={`compact-nested-table-row-${index}`}>
                          <StyledNestedTableCell width='5%' />
                          {columns.map((column, subIndex) => {
                            const width = column.width;
                            return (
                              <StyledNestedTableCell
                                width={width ? width : undefined}
                                align={
                                  column.dataAlignment === DataAlignment.Right
                                    ? 'right'
                                    : 'left'
                                }
                                key={`compact-nested-table-row-nested-table-cell-${subIndex}`}
                              >
                                <TooltipWrapper
                                  text={getFormattedText(
                                    nestedRow[column.nestedId],
                                    column
                                  )}
                                  typographyVariant='link'
                                  maxLength={column.maxLength}
                                />
                              </StyledNestedTableCell>
                            );
                          })}
                          <StyledNestedTableCell width='5%' />
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CompactNestedTableRow;
