export const validateAccountGroupName = (
    newName: string | undefined,
    accountGroupNames: string[] | undefined,
    currentName: string | undefined = undefined
  ): string | undefined => {
    if (!newName) {
      return 'Please enter in an account group name.';
    } else if (newName.length > 30) {
      return 'Account Group Name must not be more than 30 characters.';
    } else if (
      accountGroupNames?.some(
        (existingName) => existingName.toUpperCase() == newName.toUpperCase()
      ) &&
      (!currentName || currentName != newName)
    ) {
      return 'Account Group Name Must Be Unique.';
    }

    return undefined;
  };

  export default validateAccountGroupName;