import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { AssetAllocationTable } from '../table/AssetAllocationTable';
import { AssetAllocationNestedRow, AssetAllocationRow } from '../table/@types';
import { FilterItem } from '@newedge/common';
import { useCallback } from 'react';

type AssetAllocationByAssetClassViewProps = {
  data: AssetAllocationRow[];
  disableLinks: boolean;
};

const AssetAllocationByAssetClassView = ({
  data,
  disableLinks,
}: AssetAllocationByAssetClassViewProps) => {
  const navigate = useNavigate();

  const onViewHoldingsClick = useCallback(
    (row: AssetAllocationRow, nestedRow: AssetAllocationNestedRow) => {
      navigate('/my/holdings', {
        state: {
          secondaryFilters: [
            {
              displayValue: 'A/C: ' + row.name,
              predicateIdentifier: 'assetClass',
              searchValue: row.name,
              filterType: 'text',
            } as FilterItem,
            {
              displayValue: 'S-A/C: ' + nestedRow.name,
              predicateIdentifier: 'subAssetClass',
              searchValue: nestedRow.name,
              filterType: 'text',
            } as FilterItem,
          ],
        },
      });
    },
    [navigate]
  );

  return (
    <AssetAllocationTable
      columns={[
        {
          id: 'name',
          label: 'Name',
        },
        {
          id: 'marketValue',
          label: 'Market Value',
        },
        {
          id: 'percent',
          label: '% Of Accounts',
        },
      ]}
      data={data}
      disableLinks={disableLinks}
      onViewHoldingsClick={onViewHoldingsClick}
      nestedCellStyle={{ textTransform: 'uppercase' }}
    />
  );
};

export default AssetAllocationByAssetClassView;
