import { Typography, Box } from '@mui/material';
import { DateTime } from 'luxon';

interface AsOfDateProps {
  asOfDate?: string | null;
}

export const AsOfDate = ({ asOfDate }: AsOfDateProps) => {
  return (
    <Box
      sx={(theme) => ({
        fontStyle: 'italic',
        color: theme.palette.greyPercent[60],
        alignSelf: 'flex-end',
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
          alignSelf: 'flex-start',
          marginBottom: 'unset',
        },
      })}
    >
      <Typography variant="body1">
        {asOfDate !== null && asOfDate !== undefined
          ? `Data Shown as of ${DateTime.fromISO(asOfDate)
              .toUTC()
              .toFormat('MM/dd/yyyy')} COB`
          : 'Data not available'}
      </Typography>
    </Box>
  );
};

export default AsOfDate;
