import {
  alpha,
  GridProps,
  GridSize,
  Paper,
  PaperProps,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Breakpoint, styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

const PREFIX = 'Widget';

const classes = {
  root: `${PREFIX}-root`,
  widgetTitle: `${PREFIX}-widgetTitle`,
  widgetRoot: `${PREFIX}-widgetRoot`,
  widgetItem: `${PREFIX}-widgetItem`,
  widgetItemTitle: `${PREFIX}-widgetItemTitle`,
  widgetItemElement: `${PREFIX}-widgetItemElement`,
};

const StyledPaper = styled(Paper)(
  ({ theme: { spacing, palette, breakpoints } }) => ({
    [`& .${classes.root}`]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      borderRadius: '10px',
    },

    [`& .${classes.widgetTitle}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: alpha(palette.greyPercent[24] as string, 0.65),
      borderRadius: '10px 10px 0 0',
      height: '5rem',
      [breakpoints.down('sm')]: {
        height: '3rem',
      },
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },

    [`&.${classes.widgetRoot}`]: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: spacing(0),
      width: '100%',
      borderRadius: '10px',
      backgroundColor: palette.transparentBackground.main,
    },

    [`& .${classes.widgetItem}`]: {
      width: '100%',
      padding: 0,
    },

    [`& .${classes.widgetItemTitle}`]: {
      paddingBottom: spacing(2),
    },

    [`& .${classes.widgetItemElement}`]: {
      width: '100%',
      padding: spacing(2),
      [breakpoints.down('sm')]: {
        padding: 0,
      },
    },
  })
);

type WidgetComponent = {
  title?: React.ReactNode | React.ReactElement;
  element: React.ReactElement;
  breakpoints?: Partial<Record<Breakpoint, boolean | GridSize>>;
};

type WidgetProps = {
  title?: React.ReactNode | React.ReactElement;
  paperProps?: Partial<PaperProps>;
  gridContainerProps?: Partial<GridProps>;
  widgets: WidgetComponent[];
  detailsMenu?: React.ReactNode | React.ReactElement;
};

function Widget({
  children,
  paperProps,
  gridContainerProps,
  widgets,
  title,
  detailsMenu,
}: PropsWithChildren<WidgetProps>) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );
  return (
    <StyledPaper
      elevation={6}
      {...paperProps}
      className={clsx(classes.widgetRoot, paperProps?.className)}
    >
      <div className={classes.widgetTitle}>
        {title ? (
          <Typography variant={isMobile ? 'h4' : 'h3'}>{title}</Typography>
        ) : null}
        {detailsMenu ? detailsMenu : null}
      </div>
      <Grid
        spacing={3}
        {...gridContainerProps}
        className={gridContainerProps?.className}
      >
        {widgets.map((x, i) => (
          <Grid key={i} item className={classes.widgetItem} {...x.breakpoints}>
            {x.title ? (
              <div className={classes.widgetItemTitle}>{x.title}</div>
            ) : null}
            <div className={classes.widgetItemElement}>{x.element}</div>
          </Grid>
        ))}
      </Grid>
      {children}
    </StyledPaper>
  );
}

export default Widget;
