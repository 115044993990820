import { Box } from '@mui/material';
import { Key, ReactNode } from 'react';
import { getFormattedText } from '../TableHelpers';
import { MobileReportTableColumn } from './@types';

interface MobileReportNestedTableProps<T, S> {
  columns: MobileReportTableColumn<T, S>[];
  data: S;
  positiveNumberDecorator?: boolean;
  customNestedTableHeaderRenderer?: (
    columns: MobileReportTableColumn<T, S>[],
    nestedRow: S
  ) => ReactNode;
}

export const MobileReportNestedTable = <T, S>({
  columns,
  data,
  positiveNumberDecorator,
  customNestedTableHeaderRenderer,
}: MobileReportNestedTableProps<T, S>) => {
  return (
    <Box sx={{ display: 'grid', width: '100%' }}>
      {customNestedTableHeaderRenderer
        ? customNestedTableHeaderRenderer(columns, data)
        : undefined}
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: 10,
          overflowX: 'scroll',
          overflowY: 'hidden',
          paddingBottom: 1.5,
          paddingLeft: 3,
          textAlign: 'right',
          marginLeft: 0,
          backgroundColor: theme.extensions.mobileReportTable.stripe,
        })}
      >
        {columns.map((column) => {
          if (column?.nestedId && column.customValueRenderer) {
            return column.customValueRenderer(data[column.nestedId], data);
          }
          return (
            <>
              {column?.nestedId && !column.isIgnorableColumn ? (
                <Box key={column.id as Key} style={column.style}>
                  <Box
                    sx={(theme) => ({
                      marginBottom: 0.5,
                      width: '100%',
                      ...theme.typography.body2,
                    })}
                  >
                    {column.label}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      width: '100%',
                      position: 'relative',
                      ...theme.typography.body1,
                    })}
                  >
                    {column.hasDashValuesWhenNull &&
                    data[column.nestedId] === null
                      ? '--'
                      : getFormattedText<T, S>(
                          data[column.nestedId],
                          column,
                          positiveNumberDecorator
                        )}
                  </Box>
                </Box>
              ) : undefined}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default MobileReportNestedTable;
