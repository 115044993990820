import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import {
  formatNumber,
  NumberFormatType,
  TooltipWrapper,
} from '@newedge/common';
import {
  AccountBalanceViewModel,
  AccountGroupBalanceViewModel,
} from '../@types';
import { ViewHoldingsMenu } from '@newedge/common';
import { useNavigate } from 'react-router';

const StyledTableCell = styled(TableCell)(({ theme: { typography } }) => ({
  textAlign: 'center',
  ...typography.body5,
}));

const StyledAccountCell = styled(TableCell)(({ theme: { typography } }) => ({
  textAlign: 'center',
  ...typography.link,
}));

const renderAccountRow = (
  accountData: AccountBalanceViewModel,
  isOpen: boolean,
  index: number,
  linksDisabled: boolean,
  onHoldingsClick: () => void
) => {
  return (
    <TableRow
      key={`Account_${accountData.AccountId}_${index}`}
      sx={{ display: isOpen ? 'table-row' : 'none' }}
    >
      <TableCell>
        {!linksDisabled && (
          <ViewHoldingsMenu onViewHoldingsClick={onHoldingsClick} />
        )}
      </TableCell>
      <StyledAccountCell>
        <TooltipWrapper
          text={accountData.DisplayName}
          typographyVariant='link'
          maxLength={72}
        />
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{accountData.ManagementStyle}</div>
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{accountData.AccountType}</div>
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{formatNumber(accountData.MarketValue)}</div>
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{formatNumber(accountData.CashBalance)}</div>
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{formatNumber(accountData.TotalBalance)}</div>
      </StyledAccountCell>
      <StyledAccountCell>
        <div>{formatNumber(accountData.UnrealizedGl)}</div>
        <div>
          {formatNumber(
            accountData.UnrealizedGlPercent,
            NumberFormatType.Percent,
            2,
            2
          )}
        </div>
      </StyledAccountCell>
      <StyledAccountCell>
        <Box sx={{ display: 'flex' }}>
          {accountData.DailyChange > 0 ? <Typography>+</Typography> : null}
          <Typography>
            {formatNumber(
              accountData.DailyChange * 100,
              NumberFormatType.Percent,
              2,
              2
            )}
          </Typography>
        </Box>
      </StyledAccountCell>
    </TableRow>
  );
};

interface AccountGroupTableProps {
  data: AccountGroupBalanceViewModel;
  disableLinks: boolean;
}

export const AccountGroupTableRow = ({
  data,
  disableLinks,
}: AccountGroupTableProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={(theme) => ({ backgroundColor: theme.palette.greyPercent[14] })}
      >
        <TableCell>
          <div>
            <IconButton
              aria-label='expand row'
              sx={(theme) => ({ ...theme.typography.h3, padding: '3px' })}
              onClick={() => setOpen(!open)}
              size='large'
            >
              {open ? (
                <ArrowDropUpIcon fontSize='inherit' />
              ) : (
                <ArrowDropDownIcon fontSize='inherit' />
              )}
            </IconButton>
          </div>
        </TableCell>
        <TableCell
          sx={(theme) => ({
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(0.5),
            textAlign: 'left',
            fontWeight: 'bold',
            fontSize: '1.6rem',
            textTransform: 'uppercase',
          })}
        >
          <div>{data.AccountGroupName}</div>
        </TableCell>
        <TableCell>
          <div></div>
        </TableCell>
        <TableCell>
          <div></div>
        </TableCell>
        <StyledTableCell>
          <div>{formatNumber(data.MarketValue)}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div>{formatNumber(data.CashBalance)}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div>{formatNumber(data.TotalBalance)}</div>
        </StyledTableCell>
        <StyledTableCell>
          <div>{formatNumber(data.UnrealizedGl)}</div>
          <div>
            {formatNumber(
              data.UnrealizedGlPercent,
              NumberFormatType.Percent,
              2,
              2
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell>
          <Box sx={{ display: 'flex' }}>
            {data.DailyChange > 0 ? <Typography>+</Typography> : null}
            <Typography>
              {formatNumber(
                data.DailyChange * 100,
                NumberFormatType.Percent,
                2,
                2
              )}
            </Typography>
          </Box>
        </StyledTableCell>
      </TableRow>
      {data.Accounts.map((accountRow, index) =>
        renderAccountRow(accountRow, open, index, disableLinks, () => {
          if (!disableLinks) {
            navigate('/my/holdings', {
              state: {
                accountFilter: accountRow.AccountId,
              },
            });
          }
        })
      )}
    </>
  );
};

export default AccountGroupTableRow;
