import { IPublicClientApplication } from '@azure/msal-browser';
import {
  UploadableMap,
  CacheConfig,
  RequestParameters,
  Variables,
} from 'relay-runtime';

interface GqlEndpoint {
  scopes: string[];
  endpoint: string;
  name: string;
}

import { RequestEnvironment } from '../@types';

export const isMutation = (request: RequestParameters) =>
  request.operationKind === 'mutation';
export const isQuery = (request: RequestParameters) =>
  request.operationKind === 'query';
export const forceFetch = (cacheConfig?: CacheConfig) =>
  !!(cacheConfig && cacheConfig.force);

function getRequestBodyWithUploadables(
  request: RequestParameters,
  variables: Variables,
  uploadables: UploadableMap
) {
  const formData = new FormData();
  formData.append('name', request.name);
  formData.append('query', request.text ?? '');
  formData.append('variables', JSON.stringify(variables));

  Object.keys(uploadables).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
      formData.append(key, uploadables[key]);
    }
  });

  return formData;
}

function getRequestBodyWithoutUplodables(
  request: RequestParameters,
  variables: Variables
) {
  return JSON.stringify({
    name: request.name,
    query: request.text, // GraphQL text from input
    variables,
  });
}

export function getRequestBody(
  request: RequestParameters,
  variables: Variables,
  uploadables?: UploadableMap | null
) {
  if (uploadables) {
    return getRequestBodyWithUploadables(request, variables, uploadables);
  }

  return getRequestBodyWithoutUplodables(request, variables);
}

export const getHeaders = (
  accessToken?: string,
  uploadables?: UploadableMap | null
) => {
  if (uploadables) {
    return {
      Accept: '*/*',
      Authorization: `bearer ${accessToken}`,
    };
  }

  return {
    'Content-type': 'application/json',
    Authorization: `bearer ${accessToken}`,
  };
};

export const createClientScopeMap = (endpoints: GqlEndpoint[]) =>
  endpoints.reduce((acc, curr) => {
    acc.set(curr.name, {
      scopes: curr.scopes,
      endpoint: curr.endpoint,
    });
    return acc;
  }, new Map<string, RequestEnvironment>());

export const createMsalAccessTokenFn =
  (msalInstance: IPublicClientApplication) =>
  async (scopes: string[] = []) => {
    const hasActiveAccount = msalInstance.getAllAccounts().length > 0;
    const token = hasActiveAccount
      ? await msalInstance.acquireTokenSilent({
          scopes,
        })
      : null;
    return token?.accessToken;
  };
