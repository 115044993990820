import { SVGAttributes } from 'react';

type NewEdgeLogoTextSvgProps = SVGAttributes<SVGElement>;
export function NewEdgeLogoTextSvg({ ...svgRest }: NewEdgeLogoTextSvgProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 84 33"
      {...svgRest}
    >
      <title>NewEdge Wealth Black Logo-svg</title>
      <style type="text/css">{`.st0{fill:#FFFFFF;}`}</style>
      <g>
        <g>
          <rect
            x="2.12"
            y="19.76"
            className="st0"
            width="10.63"
            height="3.66"
          />
          <path
            className="st0"
            d="M7.43,4.24c-3.83,0-6.94,3.11-6.94,6.94c0,3.83,3.11,6.94,6.94,6.94s6.94-3.11,6.94-6.94
          C14.37,7.34,11.26,4.24,7.43,4.24z M7.43,14.93c-0.87-1.59-2.17-2.89-3.76-3.76C5.26,10.31,6.56,9,7.43,7.42
          C8.3,9,9.6,10.31,11.19,11.17C9.6,12.04,8.3,13.35,7.43,14.93z"
          />
        </g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <path
                    className="st0"
                    d="M27.11,23.06L20.82,9.29v9.29c0,3.37,0.45,3.77,1.39,4.14v0.1h-3.62v-0.1c0.94-0.37,1.34-0.77,1.34-4.14
                  V8.72c0-2.13-0.45-2.53-1.39-2.9v-0.1h3.17l5.65,12.59V9.96c0-3.37-0.45-3.77-1.39-4.14v-0.1h3.59v0.1
                  c-0.94,0.37-1.31,0.77-1.31,4.14v13.11H27.11z"
                  />
                  <path
                    className="st0"
                    d="M36.45,20.27c-0.25,0.82-1.34,2.85-3.42,2.85c-2.2,0-3.89-2.28-3.89-6.17c0-4.88,1.83-7.06,3.96-7.06
                  c1.44,0,3.34,1.16,3.34,5.2c0,0.27,0,0.35-0.02,0.64H31.4v0.42c0,3.27,0.97,5.33,2.58,5.33c0.79,0,1.68-0.42,2.35-1.34
                  L36.45,20.27z M34.24,14.25c0-3.27-0.62-3.82-1.31-3.82c-0.87,0-1.54,0.62-1.54,4.14v0.69l2.85-0.17V14.25z"
                  />
                  <path
                    className="st0"
                    d="M44.2,22.99l-1.76-9.31l-1.78,9.31H39.2l-1.36-7.95c-0.62-3.67-1.07-4.24-1.68-4.76v-0.1h4.29v0.1
                  c-0.55,0.27-0.77,0.52-0.77,1.39c0,0.45,0.12,1.63,0.3,3.07l0.64,5.13l1.88-9.69h1.14l1.88,9.74l0.72-4.36
                  c0.25-1.44,0.5-3.07,0.5-3.69c0-0.99-0.35-1.29-0.97-1.59v-0.1h3.34v0.1c-0.74,0.5-1.24,1.16-1.93,4.76l-1.56,7.95H44.2z"
                  />
                  <path
                    className="st0"
                    d="M48.18,22.82v-0.1c0.94-0.37,1.63-0.77,1.63-2.9V8.72c0-2.13-0.27-2.53-1.21-2.9v-0.1h7.9v3.49h-0.1
                  l-0.87-1.54c-0.57-1.04-1.07-1.29-1.86-1.29h-1.61v7.31h1.71c0.89,0,1.19-0.37,1.96-1.59h0.1v3.82h-0.1
                  c-0.69-1.21-1.07-1.56-1.96-1.56h-1.71v7.78h1.78c0.7-0.03,0.93-0.11,1.32-0.56c0.31-0.35,0.4-0.62,0.58-1.29
                  c0.14-0.69,0.18-0.97,0.25-1.38h0.15l0.08,0.64c0.12,1.03,0.48,2.02,1.05,2.89l0.24,0.37H48.18z"
                  />
                  <path
                    className="st0"
                    d="M66.05,22.37c-1.11,0-2.35,0.4-3.12,0.74h-0.1l-0.07-1.44c-0.54,0.54-1.81,1.44-2.77,1.44
                  c-1.66,0-3.1-2.35-3.1-6.09c0-4.31,2.16-7.13,4.48-7.13c0.52,0,1.07,0.12,1.39,0.2V8.37c0-2.6-0.12-2.6-1.19-3.05V5.2
                  c1.14-0.17,2.35-0.45,3.27-0.97h0.15v14.84c0,2.63,0.25,2.7,1.07,3.12V22.37z M62.76,11.67c-0.62-0.77-1.14-1.19-1.68-1.19
                  c-1.31,0-1.98,2.11-1.98,5.65c0,3.54,1.07,5.43,2.45,5.43c0.37,0,0.82-0.1,1.21-0.3V11.67z"
                  />
                  <path
                    className="st0"
                    d="M70.91,21.18c1.88,0.1,3.05,1.14,3.05,3.17c0,3.05-2.33,4.41-4.61,4.41c-1.93,0-3.59-0.94-3.59-2.9
                  c0-1.78,1.34-2.53,2.3-2.95c-1.07-0.15-1.49-0.84-1.49-1.63c0-0.94,0.59-1.78,1.68-2.5c-1.14-0.67-1.96-2.11-1.96-4.14
                  c0-2.92,1.73-4.76,3.67-4.76c0.45,0,0.89,0.1,1.29,0.3l3.02-0.35v1.46l-2.18-0.52c0.89,0.82,1.44,2.16,1.44,3.69
                  c0,2.68-1.64,4.76-3.67,4.76c-0.42,0-0.84-0.07-1.24-0.25c-0.94,0.89-0.92,2.03,0.47,2.11L70.91,21.18z M68.63,22.97
                  c-0.57,0.5-1.04,1.36-1.04,2.63c0,1.83,1.04,2.65,2.33,2.65c1.49,0,2.4-1.16,2.4-2.77c0-1.36-0.64-2.35-2.25-2.43L68.63,22.97
                  z M71.33,14.59c0-3.34-0.59-4.21-1.41-4.21c-0.82,0-1.41,0.79-1.41,4.21c0,3.27,0.57,4.19,1.41,4.19
                  C70.74,18.78,71.33,17.86,71.33,14.59z"
                  />
                  <path
                    className="st0"
                    d="M81.63,20.27c-0.25,0.82-1.34,2.85-3.42,2.85c-2.2,0-3.89-2.28-3.89-6.17c0-4.88,1.83-7.06,3.96-7.06
                  c1.44,0,3.34,1.16,3.34,5.2c0,0.27,0,0.35-0.02,0.64h-5.03v0.42c0,3.27,0.97,5.33,2.58,5.33c0.79,0,1.68-0.42,2.35-1.34
                  L81.63,20.27z M79.43,14.25c0-3.27-0.62-3.82-1.31-3.82c-0.87,0-1.54,0.62-1.54,4.14v0.69l2.85-0.17V14.25z"
                  />
                </g>
              </g>
            </g>
          </g>
          <path
            className="st0"
            d="M39.92,24.79h0.55l0.43,2.14c0.08,0.43,0.15,0.85,0.23,1.29h0.03c0.08-0.44,0.18-0.86,0.28-1.29L42,24.79
          h0.52l0.56,2.14c0.1,0.42,0.21,0.85,0.3,1.29h0.03c0.07-0.44,0.15-0.86,0.22-1.29l0.44-2.14h0.51l-0.87,3.94h-0.66l-0.61-2.38
          c-0.08-0.31-0.13-0.58-0.18-0.92h-0.03c-0.06,0.34-0.13,0.61-0.2,0.92l-0.6,2.38H40.8L39.92,24.79z"
          />
          <path
            className="st0"
            d="M45.94,24.79h2.39v0.43h-1.86v1.23h1.57v0.43h-1.57v1.43h1.92v0.43h-2.45V24.79z"
          />
          <path
            className="st0"
            d="M50.75,24.79h0.61l1.4,3.94h-0.56l-0.39-1.19h-1.51l-0.4,1.19h-0.54L50.75,24.79z M50.43,27.12h1.24
          l-0.2-0.59c-0.15-0.44-0.28-0.87-0.42-1.33h-0.03c-0.13,0.46-0.26,0.89-0.4,1.33L50.43,27.12z"
          />
          <path
            className="st0"
            d="M53.97,24.79h0.53v3.51h1.81v0.43h-2.34V24.79z"
          />
          <path
            className="st0"
            d="M57.78,25.21h-1.25v-0.43h3.04v0.43h-1.26v3.51h-0.53V25.21z"
          />
          <path
            className="st0"
            d="M60.94,24.79h0.53v1.65h1.92v-1.65h0.53v3.94h-0.53v-1.85h-1.92v1.85h-0.53V24.79z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M82.32,22.54c0-0.35,0.27-0.59,0.6-0.59c0.32,0,0.59,0.24,0.59,0.59c0,0.35-0.27,0.59-0.59,0.59
          C82.59,23.13,82.32,22.89,82.32,22.54z M83.38,22.54c0-0.28-0.2-0.48-0.47-0.48s-0.47,0.2-0.47,0.48c0,0.28,0.2,0.48,0.47,0.48
          S83.38,22.83,83.38,22.54z M82.69,22.23h0.24c0.13,0,0.25,0.05,0.25,0.19c0,0.07-0.05,0.14-0.12,0.16l0.14,0.25h-0.14l-0.11-0.21
          h-0.13v0.21h-0.13V22.23z M82.9,22.53c0.09,0,0.14-0.04,0.14-0.1c0-0.06-0.03-0.1-0.13-0.1h-0.09v0.2H82.9z"
          />
        </g>
      </g>
    </svg>
  );
}
