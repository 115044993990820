import {
  Container,
  Typography,
  TypographyProps,
  useMediaQuery,
} from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { newEdgeNamedColors } from '@newedge/theme';
import { ExternalLink } from './ExternalLink';

const PREFIX = 'PageFooter';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  disclaimer: `${PREFIX}-disclaimer`,
  line: `${PREFIX}-line`,
};

const StyledContainer = styled(Container)(
  ({ theme: { breakpoints, spacing } }) => ({
    [`&.${classes.root}`]: {
      marginTop: spacing(5),
      maxWidth: 1250,
      padding: 0,
      [breakpoints.down('xs')]: {
        marginTop: spacing(1),
      },
    },

    [`& .${classes.content}`]: {
      borderTop: newEdgeNamedColors.iron,
      padding: spacing(4, 0, 5),
      maxWidth: 1250,
      [breakpoints.down('sm')]: {
        padding: spacing(1),
      },
    },

    [`& .${classes.disclaimer}`]: {
      color: newEdgeNamedColors.cadet,
      fontSize: '1.4rem',
      fontWeight: 'bold',
      fontStyle: 'italic',
    },

    [`& .${classes.line}`]: {
      fontSize: '2.1rem',
      lineHeight: '2.4rem',
      [breakpoints.down('sm')]: {
        fontSize: '1.4rem',
        lineHeight: '1.6rem',
      },
      [breakpoints.down('xs')]: {
        fontSize: '1.3rem',
        lineHeight: '1.5rem',
      },
    },
  })
);

export function PageFooter() {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const typographyProps: TypographyProps = {
    className: classes.line,
    align: small ? 'inherit' : 'center',
  };

  const terms = (
    <>
      Use of this system indicates that you have read and agree with the{' '}
      <strong>
        <ExternalLink href='https://secure.macg.com/staticpda/terms.pdf'>
          Terms and conditions
        </ExternalLink>
      </strong>
      .
    </>
  );

  const finraStatement = (
    <>
      Settlement Services are provided by <strong>NewEdge Wealth</strong>, a
      registered broker dealer. Member{' '}
      <ExternalLink className={classes.line} href='https://www.finra.org/#/'>
        FINRA
      </ExternalLink>
      ,{' '}
      <ExternalLink className={classes.line} href='https://www.sipc.org'>
        SIPC
      </ExternalLink>
      .
    </>
  );

  const contact = (
    <>
      {['1251 Waterfront Place', 'Pittsburgh, PA 15222', '412-391-7077'].join(
        small ? ', ' : ' | '
      )}
    </>
  );

  const disclaimer = (
    <span className={classes.disclaimer}>
      {[
        '**Footer is a temporary placeholder, awaiting final wording from Legal**',
      ].join(small ? ', ' : ' | ')}
    </span>
  );

  return (
    <StyledContainer className={classes.root}>
      <div className={classes.content}>
        {small ? (
          <>
            <Typography {...typographyProps}>
              {terms} {finraStatement} {contact}
            </Typography>
          </>
        ) : (
          <>
            <Typography {...typographyProps}>{terms}</Typography>
            <Typography {...typographyProps}>{finraStatement}</Typography>
            <Typography {...typographyProps}>{contact}</Typography>
            <Typography {...typographyProps}>{disclaimer}</Typography>
          </>
        )}
      </div>
    </StyledContainer>
  );
}

export default PageFooter;
