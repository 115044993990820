import { TableRow, TableCell, Tooltip, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTime } from 'luxon';
import { PerformanceNestedTableRowData } from '../@types';
import { cellValueFormatter } from '../performanceHelpers';
import { ColumnVisibilityState } from './ColumnVisibilityMenu';
import { useCallback } from 'react';
import {
  NumberFormatType,
  TooltipWrapper,
  formatNumber,
} from '@newedge/common';
import { PerformanceTableColumn } from './@types';

const StyledTableCell = styled(TableCell)(
  ({ theme: { typography, extensions } }) => ({
    ...typography.link,
    textAlign: 'right',
    backgroundColor: extensions.grey[8],
  })
);

type PerformanceTableNestedRowProps = {
  isOpen: boolean;
  isParentOpen: boolean;
  rowData: PerformanceNestedTableRowData;
  visibilityState: ColumnVisibilityState[];
};

const PerformanceTableNestedRow = ({
  isOpen,
  isParentOpen,
  rowData,
  visibilityState,
}: PerformanceTableNestedRowProps) => {
  const renderCellValue = useCallback(
    (
      label: PerformanceTableColumn['label'],
      key: keyof PerformanceNestedTableRowData
    ) => {
      if (
        visibilityState.find((column) => column.label === label)?.visible ===
        false
      ) {
        return null;
      }
      return cellValueFormatter(rowData[key]);
    },
    [rowData, visibilityState]
  );

  return (
    <TableRow sx={isOpen && isParentOpen ? undefined : { display: 'none' }}>
      <TableCell
        sx={(theme) => ({
          ...theme.typography.link,
          backgroundColor: theme.extensions.grey[8],
          paddingLeft: 6,
        })}
      >
        {rowData.custodian && rowData.name.length <= 72 ? (
          <Tooltip
            sx={{ fontSize: '1.4rem' }}
            title={<Box sx={{ fontSize: '1.4rem' }}>{rowData.custodian}</Box>}
          >
            <Typography sx={{ fontSize: '1.4rem', overflowWrap: 'anywhere' }}>
              {rowData.name}
            </Typography>
          </Tooltip>
        ) : (
          <TooltipWrapper
            text={rowData.name}
            maxLength={72}
            typographyVariant='link'
            tooltipRenderer={
              rowData.custodian
                ? (text) => (
                    <Box
                      sx={{
                        fontSize: '1.4rem',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span>
                        <Box sx={{ fontWeight: 'bold' }}>Account Name:</Box>{' '}
                        {text}
                      </span>
                      <span>
                        <Box sx={{ fontWeight: 'bold' }}>Custodian:</Box>{' '}
                        {rowData.custodian}
                      </span>
                    </Box>
                  )
                : undefined
            }
          />
        )}
      </TableCell>
      <StyledTableCell>
        {rowData.marketValue
          ? formatNumber(rowData.marketValue, NumberFormatType.Currency, 0, 0)
          : '--'}
      </StyledTableCell>
      <StyledTableCell>{renderCellValue('MTD', 'monthToDate')}</StyledTableCell>
      <StyledTableCell>
        {renderCellValue('QTD', 'quarterToDate')}
      </StyledTableCell>
      <StyledTableCell>{renderCellValue('YTD', 'yearToDate')}</StyledTableCell>
      <StyledTableCell>
        {renderCellValue('1YR', 'oneYearReturns')}
      </StyledTableCell>
      <StyledTableCell>
        {renderCellValue('3YR', 'threeYearReturns')}
      </StyledTableCell>
      <StyledTableCell>
        {renderCellValue('5YR', 'fiveYearReturns')}
      </StyledTableCell>
      <StyledTableCell>
        {renderCellValue('10YR', 'tenYearReturns')}
      </StyledTableCell>
      <TableCell
        sx={(theme) => ({
          ...theme.typography.link,
          textAlign: 'right',
          backgroundColor: theme.extensions.grey[8],
        })}
      >
        {renderCellValue('Since Inception', 'sinceInception')}
      </TableCell>
      <TableCell
        sx={(theme) => ({
          ...theme.typography.link,
          textAlign: 'right',
          backgroundColor: theme.extensions.grey[8],
        })}
      >
        {!rowData.inceptionDate
          ? '--'
          : DateTime.fromISO(rowData.inceptionDate).toFormat('M/d/yyyy')}
      </TableCell>
    </TableRow>
  );
};

export default PerformanceTableNestedRow;
