import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  formatNumber,
  NumberFormatType,
  TooltipWrapper,
  ViewHoldingsMenu,
} from '@newedge/common';
import { AssetAllocationNestedRow, AssetAllocationRow } from './@types';
import { CSSProperties } from '@mui/styles';

const StyledTableCell = styled(TableCell)(() => ({
  textAlign: 'center',
}));

type AssetAllocationNestedTableProps<T> = {
  parentRow: AssetAllocationRow;
  nestedData: AssetAllocationNestedRow[];
  disableLinks: boolean;
  onViewHoldingsClick: (
    row: AssetAllocationRow,
    nestedRow: AssetAllocationNestedRow
  ) => void;
  nestedCellStyle?: CSSProperties;
};

export const AssetAllocationNestedTable = <T,>({
  parentRow,
  nestedData,
  disableLinks,
  onViewHoldingsClick,
  nestedCellStyle = {},
}: AssetAllocationNestedTableProps<T>) => {
  const CellText = styled(Typography)(({ theme: { typography } }) => ({
    ...typography.link,
    ...nestedCellStyle,
  }));

  return (
    <Table sx={{ tableLayout: 'fixed' }}>
      <TableBody>
        {nestedData.map((item) => {
          return (
            <TableRow key={item.name}>
              <StyledTableCell />
              <StyledTableCell colSpan={5}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {!disableLinks && (
                    <ViewHoldingsMenu
                      onViewHoldingsClick={() =>
                        onViewHoldingsClick(parentRow, item)
                      }
                    />
                  )}
                  <TooltipWrapper
                    text={item.name}
                    typographyVariant='link'
                    typographyStyles={nestedCellStyle}
                    maxLength={72}
                  />
                </Box>
              </StyledTableCell>
              <StyledTableCell colSpan={6}>
                <CellText>{formatNumber(item.marketValue)}</CellText>
              </StyledTableCell>
              <StyledTableCell colSpan={6}>
                <CellText>
                  {formatNumber(item.percent, NumberFormatType.Percent, 2, 2)}
                </CellText>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default AssetAllocationNestedTable;
