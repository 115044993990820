import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type TabPanelProps = PropsWithChildren<{
  value: string;
  tabName: string;
  boxWrapperProps?: Partial<BoxProps>;
}>;

export function TabPanel({
  children,
  value,
  tabName,
  boxWrapperProps,
}: TabPanelProps) {
  return (
    <Box
      sx={{ width: '100%' }}
      role='tabpanel'
      hidden={value !== tabName}
      id={`${tabName}-tabpanel`}
      aria-labelledby={`${tabName}-tab`}
    >
      {value === tabName && <Box {...boxWrapperProps}>{children}</Box>}
    </Box>
  );
}

export default TabPanel;
