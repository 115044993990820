import { styled } from '@mui/material/styles';
import Pagination from '@mui/lab/Pagination';
import React from 'react';

const PREFIX = 'SortableTablePaginator';

const classes = {
  root: `${PREFIX}-root`,
  pagination: `${PREFIX}-pagination`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },

  [`& .${classes.pagination}`]: {
    display: 'flex',
    justifyContent: 'right',
    marginBottom: theme.spacing(2),
  },
}));

interface SortableTablePaginatorProps {
  pageCount: number;
  onPageChange: (event: React.ChangeEvent<unknown>, newPage: number) => void;
  currentPage: number;
}

const SortableTablePaginator = ({
  pageCount,
  onPageChange,
  currentPage,
}: SortableTablePaginatorProps) => {
  return (
    <Root className={classes.root}>
      <Pagination
        count={pageCount}
        onChange={onPageChange}
        page={currentPage}
        color="secondary"
        className={classes.pagination}
        shape="rounded"
        showFirstButton
        showLastButton
      />
    </Root>
  );
};

export default SortableTablePaginator;
