import { Tab, Tabs } from '@mui/material';
import { ReactNode } from 'react';

interface TabDefinition {
  id: string;
  label: string;
}

interface ReportTableTabsProps {
  value: string;
  onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  tabs: TabDefinition[];
  postfix?: ReactNode;
}

export const ReportTableTabs = ({
  value,
  onChange,
  tabs,
  postfix,
}: ReportTableTabsProps) => {
  return (
    <Tabs
      sx={{
        '& .MuiTabs-indicator': {
          display: 'none',
        },
      }}
      value={value}
      onChange={onChange}
    >
      <Tab
        sx={(theme) => ({
          pointerEvents: 'none',
          backgroundColor: theme.palette.greyPercent[8],
          paddingLeft: theme.spacing(2),
        })}
        label={'View By'}
      ></Tab>
      {tabs.map((tab) => {
        return <Tab label={tab.label} key={tab.label} value={tab.id} />;
      })}
      {postfix ? postfix : null}
    </Tabs>
  );
};

export default ReportTableTabs;
