import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const PREFIX = 'LayeredIcon';

const classes = {
  wrapper: `${PREFIX}-wrapper`,
};

const Root = styled('span')({
  [`& .${classes.wrapper}`]: {
    display: 'inline-block',
    position: 'relative',
    textAlign: 'center',
    width: '1.25em',
    height: '1em',
    verticalAlign: '-0.125em',
    '& svg': {
      position: 'absolute',
      transformOrigin: 'center center',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: 'auto',
    },
  },
});

export function LayeredIcon({
  className,
  children,
  ...rest
}: React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>) {
  return (
    <Root className={clsx(classes.wrapper, className)} {...rest}>
      {children}
    </Root>
  );
}

export default LayeredIcon;
