import { Typography as MuiTypography, TypographyProps } from '@mui/material';

type NotAvailableTypographyProps = TypographyProps;

function NotAvailableTypography(props: NotAvailableTypographyProps) {
  if (props.children === null || props.children === undefined) {
    return <MuiTypography {...props}>N/A</MuiTypography>;
  }
  return <MuiTypography {...props}>{props.children}</MuiTypography>;
}

export default NotAvailableTypography;
