import {
  Box,
  ButtonBase,
  Grid,
  Theme,
  useMediaQuery,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useRef, useState } from 'react';
import {
  FilterConfig,
  FilterItem,
  TableFilter,
  filterSelected,
  RefineYourReportButton,
  MobileReportTableColumn,
  MobileReportTable,
  ColumnDataType,
  ExportToExcelButton,
  ExcelAccountItem,
  ClientPortalFinancialAccount,
  UserProfileFinancialAccountNickname,
  TabPanel,
  ReportTableTabs,
  TooltipWrapper,
} from '@newedge/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { AssetAllocation } from './@types';
import {
  getDataByManagementStyle,
  getDataByAssetClass,
  computeExcelDataByAssetClass,
  computeExcelDataByManagementStyle,
  AssetAllocationByAssetClassExcelData,
  AssetAllocationByManagementStyleExcelData,
} from './assetAllocationDetailHelpers';
import { AssetAllocationByManagementView } from './by-management';
import { AssetAllocationByAssetClassView } from './by-asset-class';
import MobileTableTabs from './mobile/MobileTableTabs';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import _ from 'lodash';
import { defaultReportDisclosuresTextLines } from '../../assets/text/ReportDisclosures';
import { AccountBalancesModel } from '../account-balances';
import { AssetAllocationNestedRow, AssetAllocationRow } from './table';

const getFilterOptions = (
  accounts: ClientPortalFinancialAccount[],
  filterValues: FilterItem[]
): FilterConfig => {
  const managementStyles = [...new Set(accounts.map((o) => o.managementStyle))];
  return {
    config: [
      {
        header: 'Management Style',
        selections: filterSelected(
          managementStyles.map((name) => {
            return {
              searchValue: accounts
                .filter((account) => account.managementStyle === name)
                .map((account) => account.financialAccountId),
              predicateIdentifier: 'accountId',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
    ],
  };
};

const MobileTitle = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
  paddingBottom: theme.spacing(0.5),
  marginBottom: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
}));

const MobileLink = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#888888',
  ...theme.typography.link,
}));

const MobileArrowForwardIcon = styled(ArrowForwardIcon)(() => ({
  marginLeft: '5px',
}));

interface LocationState {
  selectedTab?: 'by-asset-class' | 'by-management-style';
  category?: string;
}

interface AssetAllocationDetailViewProps {
  data: any;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string | null) => void;
  accountBalances: Pick<AccountBalancesModel, 'accountId' | 'totalBalance'>[];
  accounts: ClientPortalFinancialAccount[];
  selectedAccounts: number[];
  userProfileAccounts: UserProfileFinancialAccountNickname[] | undefined;
  disableLinks?: boolean;
}

export const AssetAllocationDetailView = ({
  data,
  filterValues,
  setFilterValues,
  setAsOfDate,
  accountBalances,
  accounts,
  selectedAccounts,
  userProfileAccounts,
  disableLinks = false,
}: AssetAllocationDetailViewProps) => {
  if (data.length > 0) {
    const asOfDate: string | null = data[0].asOfDate;
    setAsOfDate(asOfDate);
  } else {
    setAsOfDate(null);
  }

  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  const assetAllocationDataWithNicknames = data?.map(
    (assetAllocationRecord: any) => {
      if (assetAllocationRecord) {
        const userProfileAccountData = userProfileAccounts?.find(
          (userProfileRecord: any) =>
            userProfileRecord?.financialAccountId ===
            assetAllocationRecord.accountId
        );
        return {
          ...assetAllocationRecord,
          userProfileData:
            userProfileAccountData === undefined
              ? null
              : (userProfileAccountData as UserProfileFinancialAccountNickname),
        } as AssetAllocation;
      }
      return {} as AssetAllocation;
    }
  );

  const dataByManagement = getDataByManagementStyle(
    assetAllocationDataWithNicknames
  );

  let sortedDataByManagement: AssetAllocationRow[] = [];
  sortedDataByManagement = _.sortBy(dataByManagement, ['name']);
  sortedDataByManagement.forEach((o) => {
    o.nestedData = _.sortBy(o.nestedData, ['name']);
  });

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const location = useLocation();
  const state: LocationState = location.state as LocationState;

  const [selectedTab, setSelectedTab] = useState<string>(
    state && state.selectedTab ? state.selectedTab : 'by-asset-class'
  );

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setSelectedTab(newValue);
  };

  const dataByAssetClass = getDataByAssetClass(
    assetAllocationDataWithNicknames
  );

  const ASSET_CATEGORIES = [
    'Cash and Cash Equivalents',
    'Fixed Income',
    'US Equity',
    'International Equity',
    'Alternatives',
    'Real Assets',
    'Other',
  ];

  let expandRowIndex: number | undefined = undefined;

  // sort by asset class / sub asset class
  let sortedDataByAssetClass: AssetAllocationRow[] = [];
  ASSET_CATEGORIES.forEach((category, index) => {
    const record = dataByAssetClass.find((x) => x.name === category);
    if (record) {
      if (state && record?.name === state.category) {
        expandRowIndex = index;
      }
      sortedDataByAssetClass.push({
        name:
          record?.name === 'Cash and Cash Equivalents'
            ? 'Cash & Cash Equivalents'
            : record?.name,
        marketValue: record?.marketValue,
        percent: record?.percent,
        nestedData: record?.nestedData,
      });
    }
  });

  const selectedAccountList = useMemo(() => {
    const accountList: ExcelAccountItem[] = [];
    selectedAccounts.forEach((acctId) => {
      const accountInfo = accounts.find((o) => o.financialAccountId === acctId);
      const balanceInfo = accountBalances.find(
        (o: any) => o.accountId === acctId
      );
      if (accountInfo) {
        accountList.push({
          accountNumber: accountInfo.accountNumber,
          accountName: accountInfo.nicknameEntry
            ? accountInfo.nicknameEntry.accountNickname
            : accountInfo.accountName,
          accountBalance: balanceInfo ? balanceInfo.totalBalance : undefined,
          custodian: accountInfo.custodian ? accountInfo.custodian : '',
        });
      }
    });
    return accountList;
  }, [accounts, selectedAccounts, accountBalances]);

  const filterConfig = getFilterOptions(accounts, filterValues);

  const handlerScrollToTop = () => {
    renderRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    renderRef.current?.expandAccordition();
  };

  const byMgmtColumns: MobileReportTableColumn<
    AssetAllocationRow,
    AssetAllocationNestedRow
  >[] = [
    {
      id: 'marketValue',
      nestedId: 'marketValue',
      label: 'Market Value',
      dataType: ColumnDataType.Currency,
    },
    {
      id: 'percent',
      nestedId: 'percent',
      label: '% Of Accounts',
      dataType: ColumnDataType.Percent,
    },
  ];

  const byProductColumns: MobileReportTableColumn<
    AssetAllocationRow,
    AssetAllocationNestedRow
  >[] = [
    {
      id: 'marketValue',
      nestedId: 'marketValue',
      label: 'Market Value',
      dataType: ColumnDataType.Currency,
    },
    {
      id: 'percent',
      nestedId: 'percent',
      label: '% Of Accounts',
      dataType: ColumnDataType.Percent,
    },
  ];

  const navigate = useNavigate();

  const renderMobileReport = () => {
    return (
      <>
        <MobileTableTabs
          value={selectedTab}
          onChange={handleChange}
          tabs={[
            { id: 'by-asset-class', label: 'Asset Class' },
            { id: 'by-management-style', label: 'Management Style' },
          ]}
        />
        <TabPanel
          tabName='by-asset-class'
          value={selectedTab}
          boxWrapperProps={{ p: 0 }}
        >
          <MobileReportTable
            columns={byProductColumns}
            data={sortedDataByAssetClass}
            headerId='name'
            nestedHeaderId='name'
            nestedAccessor={(rowData) => rowData.nestedData}
            expandRowIndex={expandRowIndex}
            customNestedHeaderRenderer={(row, nestedRow) => {
              return (
                <MobileTitle>
                  <Box component='span'>{nestedRow.name}</Box>
                  {!disableLinks && (
                    <ButtonBase
                      onClick={() => {
                        navigate('/my/holdings', {
                          state: {
                            secondaryFilters: [
                              {
                                displayValue: 'A/C: ' + row.name,
                                predicateIdentifier: 'assetClass',
                                searchValue: row.name,
                                filterType: 'text',
                              } as FilterItem,
                              {
                                displayValue: 'S-A/C: ' + nestedRow.name,
                                predicateIdentifier: 'subAssetClass',
                                searchValue: nestedRow.name,
                                filterType: 'text',
                              } as FilterItem,
                            ],
                          },
                        });
                      }}
                    >
                      <MobileLink>
                        <Box component='span'>View Holdings</Box>
                        <MobileArrowForwardIcon fontSize='small' />
                      </MobileLink>
                    </ButtonBase>
                  )}
                </MobileTitle>
              );
            }}
          />
        </TabPanel>
        <TabPanel
          tabName='by-management-style'
          value={selectedTab}
          boxWrapperProps={{ p: 0 }}
        >
          <MobileReportTable
            columns={byMgmtColumns}
            data={sortedDataByManagement}
            headerId='name'
            nestedHeaderId='name'
            nestedAccessor={(rowData) => rowData.nestedData}
            customNestedHeaderRenderer={(row, nestedRow) => {
              return (
                <MobileTitle>
                  <TooltipWrapper text={nestedRow.name} maxLength={72} />
                  {!disableLinks && (
                    <ButtonBase
                      onClick={() => {
                        navigate('/my/holdings', {
                          state: {
                            secondaryFilters: [
                              {
                                displayValue: 'M/S: ' + row.name,
                                predicateIdentifier: 'managementStyle',
                                searchValue: row.name,
                                filterType: 'text',
                              } as FilterItem,
                            ],
                          },
                        });
                      }}
                    >
                      <MobileLink>
                        <Box component='span'>View Holdings</Box>
                        <MobileArrowForwardIcon fontSize='small' />
                      </MobileLink>
                    </ButtonBase>
                  )}
                </MobileTitle>
              );
            }}
          />
        </TabPanel>
        <RefineYourReportButton onClick={handlerScrollToTop} />
      </>
    );
  };

  const excelData = useMemo(() => {
    if (selectedTab === 'by-asset-class') {
      return computeExcelDataByAssetClass(sortedDataByAssetClass);
    }
    if (selectedTab === 'by-management-style') {
      return computeExcelDataByManagementStyle(dataByManagement);
    }
    return [];
  }, [selectedTab, sortedDataByAssetClass, dataByManagement]);

  const columnsToAutoSize = useMemo(() => {
    if (selectedTab === 'by-asset-class') {
      return [
        'Asset Class Name',
        'Sub Asset Class Name',
        'Market Value',
        '% Of Accounts',
      ] as any;
    }
    if (selectedTab === 'by-management-style') {
      return [
        'Management Style',
        'Account Name',
        'Market Value',
        '% Of Accounts',
      ] as any;
    }
    return [];
  }, [selectedTab]);

  const filenamePrefix = useMemo(() => {
    if (selectedTab === 'by-asset-class') {
      return 'AssetAllocationByAssetClass';
    }
    if (selectedTab === 'by-management-style') {
      return 'AssetAllocationByManagementStyle';
    }
    return '';
  }, [selectedTab]);

  const dataSheetTitle = useMemo(() => {
    if (selectedTab === 'by-asset-class') {
      return 'Asset Allocation By Asset Class';
    }
    if (selectedTab === 'by-management-style') {
      return 'Asset Allocation By Mgmt Style';
    }
    return '';
  }, [selectedTab]);

  const renderDesktopReport = () => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <ReportTableTabs
            value={selectedTab}
            onChange={handleChange}
            tabs={[
              { id: 'by-asset-class', label: 'Asset Class' },
              { id: 'by-management-style', label: 'Management Style' },
            ]}
            postfix={
              <Box sx={{ width: '100%', display: 'grid' }}>
                <Box sx={{ justifySelf: 'flex-end' }}>
                  <ExportToExcelButton<
                    | AssetAllocationByAssetClassExcelData
                    | AssetAllocationByManagementStyleExcelData
                  >
                    data={excelData}
                    accounts={selectedAccountList}
                    accountListColumns={[
                      {
                        key: 'accountNumber',
                        label: 'Account Number',
                      },
                      {
                        key: 'accountName',
                        label: 'Account Name',
                      },
                      {
                        key: 'accountBalance',
                        label: 'Account Balance',
                      },
                      {
                        key: 'custodian',
                        label: 'Custodian',
                      },
                    ]}
                    filenamePrefix={filenamePrefix}
                    dataSheetTitle={dataSheetTitle}
                    columnsToAutoSize={columnsToAutoSize}
                    disclosuresTextLines={defaultReportDisclosuresTextLines}
                  />
                </Box>
              </Box>
            }
          />
          <TabPanel tabName='by-asset-class' value={selectedTab}>
            {!dataByAssetClass && (
              <Skeleton variant='rectangular' width='100%' height={200} />
            )}
            {dataByAssetClass && (
              <AssetAllocationByAssetClassView
                data={sortedDataByAssetClass}
                disableLinks={disableLinks}
              />
            )}
          </TabPanel>
          <TabPanel tabName='by-management-style' value={selectedTab}>
            {!dataByManagement && (
              <Skeleton variant='rectangular' width='100%' height={200} />
            )}
            {dataByManagement && (
              <AssetAllocationByManagementView
                data={dataByManagement}
                disableLinks={disableLinks}
              />
            )}
          </TabPanel>
        </Box>
      </Box>
    );
  };

  return (
    <Grid
      container
      spacing={3}
      justifyContent='space-between'
      alignItems='center'
    >
      <Grid item xs={12}>
        <TableFilter
          values={filterValues}
          setValues={setFilterValues}
          config={filterConfig}
          renderRef={renderRef}
          description='Use the filters below to select what accounts are included within the scope of the results.  If no filter criteria is selected, then data for all accounts will show.'
        />
      </Grid>
      <Grid item xs={12}>
        {isMobileOrTablet ? renderMobileReport() : renderDesktopReport()}
      </Grid>
    </Grid>
  );
};
