import { DateTime } from 'luxon';
import { formatNumber, NumberFormatType } from '../../utils/numberFormatters';
import { ColumnDataType } from './sortable-table/@types';
import { ValueOf } from './@types';
import { MobileReportTableColumn } from './mobile-table/@types';

export const getFormattedText = <T, S>(
  value: ValueOf<T> | ValueOf<S>,
  column: MobileReportTableColumn<T, S>,
  positiveNumberDecorator?: boolean
): string => {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  switch (column.dataType) {
    case ColumnDataType.Date:
      return DateTime.fromISO(value as string).toFormat('M/d/yyyy');
    case ColumnDataType.Currency:
      return formatNumber(
        value as unknown as number,
        NumberFormatType.Currency,
        column.precision ?? 2,
        column.precision ?? 2
      );
    case ColumnDataType.Percent:
      return formatNumber(
        value as unknown as number,
        NumberFormatType.Percent,
        column.precision ?? 2,
        column.precision ?? 2,
        positiveNumberDecorator
      );
    case ColumnDataType.Number:
      return formatNumber(
        value as unknown as number,
        NumberFormatType.Number,
        column.precision ?? 2,
        column.precision ?? 2
      );
  }
  return value as unknown as string;
};

export default getFormattedText;
