import _ from 'lodash';
import { TileProps } from '@newedge/common';
import {
  CashFlowDetailTableRowData,
  MobileCashFlowDetailTableRowData,
  CashFlowDetailTableNestedRowData,
  CashFlowDetailProjectionItem,
} from './@types';
// import { CashFlowDetailViewQuery$data } from './__generated__/CashFlowDetailViewQuery.graphql';
import { DateTime } from 'luxon';

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

//returns an array of the numbers 1-12 starting with next month.
//If the current month is february the array would start with 3 and end with 2
const getMonthsArray = () => {
  const startDate = DateTime.now()
    .startOf('month')
    .plus({ month: 1 })
    .toFormat('yyyy-MM-dd');
  const startMonth = DateTime.fromISO(startDate).month;

  return [...Array(12).keys()].map((x) =>
    (x + startMonth) % 12 === 0 ? 12 : (x + startMonth) % 12
  );
};

const monthFilter = (o: any) =>
  DateTime.fromISO(o?.incomeEstimateDate as string).month;

// filter to group by all fed taxable/state taxable/income type combinations
const DIVIDEND_NAME = 'Dividend';
const INTEREST_NAME = 'Interest';
const detailGroupFilter = (o: any) => {
  const isFedTax = o.isFederalTaxable;
  const isStateTax = o.isStateTaxable;
  const name = o.incomeTypeName;
  if (isFedTax && isStateTax && name === DIVIDEND_NAME) {
    return 0;
  }
  if (isFedTax && isStateTax && name === INTEREST_NAME) {
    return 1;
  }
  if (!isFedTax && isStateTax && name === DIVIDEND_NAME) {
    return 2;
  }
  if (!isFedTax && isStateTax && name === INTEREST_NAME) {
    return 3;
  }
  if (isFedTax && !isStateTax && name === DIVIDEND_NAME) {
    return 4;
  }
  if (isFedTax && !isStateTax && name === INTEREST_NAME) {
    return 5;
  }
  if (!isFedTax && !isStateTax && name === DIVIDEND_NAME) {
    return 6;
  }
  if (!isFedTax && !isStateTax && name === INTEREST_NAME) {
    return 7;
  }
};

const getMonthTotals = (groupedObject: any) => {
  return getMonthsArray().map((month) => {
    if (month in groupedObject) {
      const incomes = groupedObject[month];
      return Math.round(
        incomes.reduce((prev: any, curr: any) => {
          const amount = curr.incomeEstimateAmount as number;
          return prev + amount;
        }, 0)
      );
    }
    return 0;
  });
};

const getDetailsArray = (categoryArray: any) => {
  const detailGrouped = _.groupBy(categoryArray, detailGroupFilter);
  let i = 0;

  const subtaxCategories = [
    'Federal Taxable / State Taxable', // keys: 0, 1 (dividend, interest)
    'Federal Exempt / State Taxable', // keys: 2, 3
    'Federal Taxable / State Exempt', // keys: 4, 5
    'Federal Exempt / State Exempt', // keys: 6, 7
  ];

  return subtaxCategories.flatMap((value) => {
    const dividends = detailGrouped[i];
    const interest = detailGrouped[i + 1];
    i += 2;
    if (dividends === undefined && interest === undefined) {
      return [];
    }
    const dividendsByMonth = _.groupBy(dividends, monthFilter);
    const interestByMonth = _.groupBy(interest, monthFilter);
    return {
      category: value,
      dividends: getMonthTotals(dividendsByMonth),
      interest: getMonthTotals(interestByMonth),
    };
  });
};

const getTotalsForTaxType = (
  detailsArray: CashFlowDetailTableNestedRowData[]
): number[] => {
  const totalsForTaxType = [];
  for (let i = 0; i < 12; i++) {
    let total = 0;
    for (let j = 0; j < detailsArray.length; j++) {
      total += detailsArray[j].dividends[i];
      total += detailsArray[j].interest[i];
    }
    totalsForTaxType.push(total);
  }
  return totalsForTaxType;
};

const getOverallColumnTotalsDesktop = (
  tableData: CashFlowDetailTableRowData[]
) => {
  const totals = [];
  for (let i = 0; i < 12; i++) {
    let total = 0;
    for (let j = 0; j < tableData.length; j++) {
      total += tableData[j].cashArray[i];
    }
    totals.push(total);
  }
  return totals;
};

const getOverallColumnTotalsMobile = (
  tableData: MobileCashFlowDetailTableRowData[]
) => {
  const totals = [];
  for (let i = 0; i < 12; i++) {
    let total = 0;
    for (let j = 0; j < tableData.length; j++) {
      total += tableData[j][
        i as keyof MobileCashFlowDetailTableRowData
      ] as number;
    }
    totals.push(total);
  }
  return totals;
};

// entrypoint to compute tout data for the Expected Cash Flow report
export const computeCashFlowToutData = (data: CashFlowDetailTableRowData[]) => {
  const toutData: TileProps[] = [];
  if (!data) {
    return toutData;
  }

  const taxCategories = ['Taxable', 'Tax Exempt', 'Tax Deferred'];
  taxCategories.forEach((category) => {
    const categoryData = data.find((o) => o.category === category);
    if (categoryData) {
      const total = categoryData.cashArray.reduce(
        (prev, curr) => prev + curr,
        0
      );
      toutData.push({
        eyebrowText: category + ' Total',
        headlineText: formatter.format(total),
        color: '',
      });
    } else {
      toutData.push({
        eyebrowText: category + ' Total',
        headlineText: 'N/A',
        color: '',
      });
    }
  });

  return toutData;
};

export interface CashFlowRecord {
  readonly accountId: number;
  readonly asOfDate: any;
  readonly cashFlowDetailId: string;
  readonly incomeEstimateAmount: any;
  readonly incomeEstimateDate: any;
  readonly incomeTypeName: string;
  readonly isFederalTaxable: boolean;
  readonly isStateTaxable: boolean;
  readonly taxStatusName: string;
}

export const computeTotals = (data: readonly CashFlowRecord[]) => {
  const tableData: CashFlowDetailTableRowData[] = [];
  if (!data) {
    return tableData;
  }

  // group by tax status
  const taxCategories = ['Taxable', 'Tax Exempt', 'Tax Deferred'];
  const groupedByTaxStatus = _.groupBy(data, (value) => {
    return value.taxStatusName;
  });
  taxCategories.forEach((category) => {
    // for each tax status type, compute totals for that type
    if (category in groupedByTaxStatus) {
      // get nested table data for all fed/state taxable/exempt combinations
      // sum of data points is rounded in the results returned here
      // all additional total calculations for columns and rows use this rounded value to prevent the appearance of incorrect math
      const detailsArray = getDetailsArray(groupedByTaxStatus[category]);

      // compute totals for each top-level accordion row for taxable, tax deferred, etc.
      const totalsForTaxType = getTotalsForTaxType(detailsArray);

      tableData.push({
        category,
        startMonth: getMonthsArray()[0],
        cashArray: totalsForTaxType,
        details: detailsArray,
      });
    }
  });

  // compute totals for each month from totals computed for taxable, tax deferred, etc.
  const totals = getOverallColumnTotalsDesktop(tableData);

  // push overall column totals to front of array
  tableData.unshift({
    category: 'Totals',
    startMonth: getMonthsArray()[0],
    cashArray: totals,
  });

  return tableData;
};

// entrypoint to compute table data for Expected Cash Flow report
export const computeTableData = (
  projectionData: CashFlowDetailProjectionItem[]
) => {
  return computeTotals(projectionData);
};

export const computeMobileTableData = (
  projectionData: CashFlowDetailProjectionItem[]
) => {
  const tableData: MobileCashFlowDetailTableRowData[] = [];
  if (!projectionData) {
    return tableData;
  }

  // group by tax status
  const taxCategories = ['Taxable', 'Tax Exempt', 'Tax Deferred'];
  const groupedByTaxStatus = _.groupBy(projectionData, (value) => {
    return value.taxStatusName;
  });

  taxCategories.forEach((category) => {
    // for each tax status type, compute totals for that type
    if (category in groupedByTaxStatus) {
      // get nested table data for all fed/state taxable/exempt combinations
      const detailsArray: CashFlowDetailTableNestedRowData[] = getDetailsArray(
        groupedByTaxStatus[category]
      );

      // compute totals for each top-level accordion row for taxable, tax deferred, etc.
      const totalsForTaxType = getTotalsForTaxType(detailsArray);

      tableData.push({
        category,
        0: totalsForTaxType[0],
        1: totalsForTaxType[1],
        2: totalsForTaxType[2],
        3: totalsForTaxType[3],
        4: totalsForTaxType[4],
        5: totalsForTaxType[5],
        6: totalsForTaxType[6],
        7: totalsForTaxType[7],
        8: totalsForTaxType[8],
        9: totalsForTaxType[9],
        10: totalsForTaxType[10],
        11: totalsForTaxType[11],
        total: totalsForTaxType.reduce((total, value) => total + value, 0),
        details: detailsArray,
      });
    }
  });

  // compute totals for each month from totals computed for taxable, tax deferred, etc.
  const overallTotals = getOverallColumnTotalsMobile(tableData);

  // push overall column totals to front of array
  tableData.unshift({
    category: 'Totals',
    0: overallTotals[0],
    1: overallTotals[1],
    2: overallTotals[2],
    3: overallTotals[3],
    4: overallTotals[4],
    5: overallTotals[5],
    6: overallTotals[6],
    7: overallTotals[7],
    8: overallTotals[8],
    9: overallTotals[9],
    10: overallTotals[10],
    11: overallTotals[11],
    total: overallTotals.reduce((total, value) => total + value, 0),
  });

  return tableData;
};

interface CashFlowExcelData {
  'Account Tax Status': string;
  'Tax Liability': string;
  'Payment Type': string;
}

export const computeCashFlowExcelData = (
  tableData: CashFlowDetailTableRowData[]
): CashFlowExcelData[] => {
  const excelData: CashFlowExcelData[] = [];
  const startMonth = tableData[0].startMonth;
  const monthKeys = [...Array(12).keys()]
    .map((x) => ((x + startMonth - 1) % 12) + 1)
    .map((month) => {
      const currentMonth = DateTime.now().month;
      let year = DateTime.now().year;
      if (month <= currentMonth) {
        year += 1;
      }
      return DateTime.fromObject({ month, year })
        .toFormat('MMM yyyy')
        .toUpperCase();
    });
  tableData.forEach((o) => {
    if (o.details) {
      o.details.forEach((o2) => {
        const dividendData = o2.dividends.map((number) => number.toString());
        const interestData = o2.interest.map((number) => number.toString());
        const dividendMonthData: any = {};
        const interestMonthData: any = {};
        monthKeys.forEach((key, index) => {
          dividendMonthData[key] = Number(dividendData[index]);
          interestMonthData[key] = Number(interestData[index]);
        });
        excelData.push({
          'Account Tax Status': o.category,
          'Tax Liability': o2.category,
          'Payment Type': 'Dividends',
          ...dividendMonthData,
        });
        excelData.push({
          'Account Tax Status': o.category,
          'Tax Liability': o2.category,
          'Payment Type': 'Interest Payments',
          ...interestMonthData,
        });
      });
    }
  });
  return excelData;
};
