import { useState } from 'react';
import { DateRangeCriteria, FilterItem } from '../@types';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';
import { css } from '@emotion/css';
import { useTheme } from '@mui/material/styles';
import { getDatePickerCellRender } from './helpers';
const { RangePicker } = DatePicker;

const fontFamily = '"Source Sans Pro", "Arial", sans-serif';

const inputStylesDark = css({
  '.ant-picker-input > input': {
    color: 'white',
  },
  '.ant-picker-input > input::placeholder': {
    color: 'white',
  },
  '.ant-picker-suffix': {
    color: '#171717',
  },
  '.ant-picker-separator': {
    color: '#171717',
  },
});

const inputStylesLight = css({
  '.ant-picker-input > input': {
    color: 'black',
  },
  '.ant-picker-input > input::placeholder': {
    color: 'black',
  },
  '.ant-picker-suffix': {
    color: 'white',
  },
  '.ant-picker-separator': {
    color: 'white',
  },
});

const customDatePickerDropdown = css({
  fontFamily: fontFamily,
  color: 'white',
  backgroundColor: '#242424',
  '.ant-picker-panel-container': {
    color: 'white',
    backgroundColor: '#242424',
    '.ant-picker-panels': {
      color: 'white',
      backgroundColor: '#242424',
      '.ant-picker-panel:not(:first-child)': {
        color: 'white',
        backgroundColor: '#242424',
      },
    },
    '.ant-picker-dropdown-range': {
      backgroundColor: '#242424',
    },
    '.ant-picker-header-super-prev-btn': {
      color: 'white',
    },
    '.ant-picker-header-prev-btn': {
      color: 'white',
    },
    '.ant-picker-header-super-next-btn': {
      color: 'white',
    },
    '.ant-picker-header-next-btn': {
      color: 'white',
    },
    '.ant-picker-header': {
      color: 'white',
    },
    '.ant-picker-content > thead > tr > th': {
      color: 'white',
    },
  },
});

export type DateFilterPickerProps = {
  config: DateRangeCriteria;
  onValueChange: (filter: FilterItem) => void;
};

const DateFilterPicker = ({ config, onValueChange }: DateFilterPickerProps) => {
  const theme = useTheme();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [pickerValue, setPickerValue] = useState<[Dayjs, Dayjs] | null>(null);

  const rangePresetDates: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: 'Yesterday', value: [dayjs().add(-1, 'd'), dayjs().add(-1, 'd')] },
    { label: 'Month to Date', value: [dayjs().startOf('month'), dayjs()] },
    { label: 'Year to Date', value: [dayjs().startOf('year'), dayjs()] },
    {
      label: 'Last 12 Months',
      value: [
        dayjs().subtract(1, 'year').startOf('month'),
        dayjs().subtract(1, 'month').endOf('month'),
      ],
    },
  ];

  const handleDateChange = (
    startDate: string | undefined,
    endDate: string | undefined
  ) => {
    let displayValue = config.header;

    if (startDate && endDate) {
      const luxonStartDate = DateTime.fromISO(startDate, { zone: 'utc' }).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const luxonEndDate = DateTime.fromISO(endDate);

      displayValue =
        displayValue +
        ': ' +
        luxonStartDate.toLocaleString(DateTime.DATE_SHORT) +
        ' - ' +
        luxonEndDate.toLocaleString(DateTime.DATE_SHORT);

      onValueChange({
        predicateIdentifier: config.predicateIdentifier,
        displayValue,
        filterType: 'date',
        date: { startDate: luxonStartDate, endDate: luxonEndDate },
        unique: true,
      });
    }
    setPickerValue([dayjs(startDate), dayjs(endDate)]);
  };

  const addPastYearsToRangePresets = (
    numberOfYears: number,
    rangePresets: {
      label: string;
      value: [Dayjs, Dayjs];
    }[]
  ) => {
    for (let index = 1; index <= numberOfYears; index++) {
      const year = dayjs().subtract(index, 'year');
      rangePresets.push({
        label: year.year().toString(),
        value: [year.startOf('year'), year.endOf('year')],
      });
    }
  };

  addPastYearsToRangePresets(5, rangePresetDates);

  return (
    <>
      <RangePicker
        onOpenChange={(open) => setDatePickerOpen(open)}
        placeholder={['Select date range...', '']}
        style={
          theme.palette.type === 'dark'
            ? {
                color: 'white',
                backgroundColor: '#171717',
                borderColor: 'white',
                width: '100%',
              }
            : {
                width: '100%',
              }
        }
        className={
          theme.palette.type === 'dark' ? inputStylesDark : inputStylesLight
        }
        cellRender={getDatePickerCellRender(theme)}
        open={datePickerOpen}
        placement={'bottomLeft'}
        dropdownClassName={
          theme.palette.type === 'dark' ? customDatePickerDropdown : ''
        }
        popupStyle={{ fontFamily: fontFamily }}
        presets={rangePresetDates}
        onChange={(date) =>
          handleDateChange(date?.[0]?.toISOString(), date?.[1]?.toISOString())
        }
        value={pickerValue}
        disabledDate={(currentDate: Dayjs): boolean =>
          currentDate.isAfter(dayjs().subtract(1, 'day'))
        }
      />
    </>
  );
};

export { DateFilterPicker };
