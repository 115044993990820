import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { CashFlowDetailTableRowData } from '../@types';
import { CashFlowDetailTableRow } from './CashFlowDetailTableRow';
import { DateTime } from 'luxon';

const StyledTable = styled(Table)(() => ({
  tableLayout: 'fixed',
}));

const StyledHeaderCell = styled(TableCell)(
  ({ theme: { palette, spacing } }) => ({
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    textAlign: 'right',
    fontSize: '1.6rem',
    backgroundColor: palette.greyPercent[14],
  })
);

// returns month header cells in order starting with the next month and incrementing forward 11 months
const renderMonthHeaderCells = () => {
  const cells = [];

  //starting with i = 1 by default to start with next month instead of the current month.
  for (let i = 1; i <= 12; i++) {
    const headerDate = DateTime.now().plus({ months: i });

    cells.push(
      <StyledHeaderCell
        key={`cashflow-detail-table-render-month-header-cells-${i}`}
      >
        {headerDate.toFormat('MMM yyyy')}
      </StyledHeaderCell>
    );
  }

  // add total column header at end
  cells.push(
    <StyledHeaderCell
      key={`cashflow-detail-table-render-month-header-cells-total`}
      sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      colSpan={2}
    >
      Total
    </StyledHeaderCell>
  );

  return cells;
};

interface LocationState {
  category?: string;
}

const renderColGroup = () => {
  return (
    <colgroup>
      <col span={14} />
      <Box
        component="col"
        span={2}
        sx={(theme) => ({
          border: theme.extensions.borders.grey28,
        })}
      />
    </colgroup>
  );
};

type CashFlowDetailTableProps = {
  data: CashFlowDetailTableRowData[];
};

export function CashFlowDetailTable({ data }: CashFlowDetailTableProps) {
  const location = useLocation();
  const state = location.state as LocationState;

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ minWidth: '1000px', paddingBottom: '40px' }}
      >
        <StyledTable aria-label="expected cash flow table">
          {renderColGroup()}
          <TableHead>
            <TableRow>
              {/* two empty cells for dropdown arrow and category name in the rows below */}
              <StyledHeaderCell />
              <StyledHeaderCell />
              {renderMonthHeaderCells()}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              let startOpen = false;
              if (state && state.category === row.category) {
                startOpen = true;
              }
              return (
                <CashFlowDetailTableRow
                  key={row.category}
                  rowData={row}
                  startOpen={startOpen}
                />
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </>
  );
}

export default CashFlowDetailTable;
