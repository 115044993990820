import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { IconButton, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';
import { NumberFormatType, formatNumber } from '@newedge/common';
import { CashFlowDetailTableRowData } from '../@types';
import { CashFlowDetailTableNestedRow } from './CashFlowDetailTableNestedRow';

const StyledTableCellRowTitle = styled(TableCell)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(2),
  paddingRight: spacing(1),
  textAlign: 'left',
  fontWeight: 'bold',
  fontSize: '1.6rem',
}));

const StyledTableCellData = styled(TableCell)(({ theme: { spacing } }) => ({
  paddingLeft: spacing(1),
  paddingRight: spacing(1),
  textAlign: 'right',
  fontSize: '1.4rem',
}));

const StyledIconButton = styled(IconButton)(() => ({
  fontSize: '2.4rem',
  padding: '3px',
}));

type CashFlowDetailTableRowProps = {
  key: string;
  rowData: CashFlowDetailTableRowData;
  startOpen?: boolean;
  first?: boolean;
};

// 16 columns wide
// 2 cells for dropdown arrow and row category name text string, 12 cells for each month, last 2 cells for row total
export const CashFlowDetailTableRow = ({
  rowData,
  startOpen,
}: CashFlowDetailTableRowProps) => {
  let initialOpenValue = false;
  if (startOpen) {
    initialOpenValue = true;
  }
  const [open, setOpen] = useState(initialOpenValue);

  return (
    <>
      <TableRow
        sx={(theme) => ({
          backgroundColor:
            rowData.details === undefined
              ? theme.palette.greyPercent[10]
              : theme.palette.greyPercent[20],
        })}
      >
        <StyledTableCellRowTitle component='th' scope='row' colSpan={2}>
          {rowData.details && (
            <StyledIconButton
              aria-label='expand row'
              onClick={() => setOpen(!open)}
              size='large'
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize='inherit' />
              ) : (
                <KeyboardArrowDownIcon fontSize='inherit' />
              )}
            </StyledIconButton>
          )}
          <span style={{ textTransform: 'uppercase' }}>{rowData.category}</span>
        </StyledTableCellRowTitle>
        {rowData.cashArray.map((value, index) => {
          return (
            <StyledTableCellData
              key={`row-data-cash-array-CashFlowDetailTableRowProps-${index}`}
            >
              {formatNumber(value, NumberFormatType.Currency, 0, 0)}
            </StyledTableCellData>
          );
        })}
        <StyledTableCellData colSpan={2}>
          {formatNumber(
            rowData.cashArray.reduce((total, value) => total + value, 0),
            NumberFormatType.Currency,
            0,
            0
          )}
        </StyledTableCellData>
      </TableRow>
      {rowData.details &&
        rowData.details.map((subsection, index) => {
          return (
            <CashFlowDetailTableNestedRow
              data={subsection}
              open={open}
              key={index}
            />
          );
        })}
    </>
  );
};

export default CashFlowDetailTableRow;
