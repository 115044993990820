import { InteractionStatus } from '@azure/msal-browser';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAuth } from '@newedge/auth';

interface CallbackFallbackHandlerProps {
  path?: string;
}

export const CallbackFallbackHandler = ({ path = '/my/dashboard' }: CallbackFallbackHandlerProps) => {
  const { inProgress, login } = useAuth();
  const navigate = useNavigate();

  // If callback view is rendered, and there's no active authentication
  // interaction in progress, something went wrong with the redirect so
  // explicitly begin a new login as a fallback
  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      login({
        scopes: [],
        redirectStartPage: path,
      });
    }
  }, [inProgress, navigate]);

  return <></>;
};
