import {
  Tooltip,
  Typography,
  TypographyTypeMap,
} from '@mui/material';
import { CSSProperties } from '@mui/styles';
import { ReactNode } from 'react';

interface TooltipWrapperProps {
  text: string;
  typographyVariant?: TypographyTypeMap['props']['variant'];
  typographyStyles?: CSSProperties;
  maxLength?: number;
  tooltipRenderer?: (text: string) => ReactNode;
}

export const TooltipWrapper = ({
  text,
  typographyVariant,
  typographyStyles = {},
  maxLength,
  tooltipRenderer,
}: TooltipWrapperProps) => {
  if (maxLength && text.length > maxLength) {
    return (
      <Tooltip
        sx={(theme) => ({ ...theme.typography.caption })}
        title={
          tooltipRenderer ? (
            tooltipRenderer(text)
          ) : (
            <Typography variant='caption'>{text}</Typography>
          )
        }
        enterTouchDelay={0}
      >
        <Typography
          variant={typographyVariant}
          sx={{ ...typographyStyles, overflowWrap: 'anywhere' }}
        >{`${text.substring(0, maxLength)}...`}</Typography>
      </Tooltip>
    );
  }
  return (
    <Typography
      variant={typographyVariant}
      sx={{ ...typographyStyles, overflowWrap: 'anywhere' }}
    >
      {text}
    </Typography>
  );
};
