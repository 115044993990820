import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import {
  Key,
  MutableRefObject,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { getFormattedText } from '../TableHelpers';
import { MobileReportTableColumn } from './@types';
import MobileReportNestedTable from './MobileReportNestedTable';
import MobileReportTableNestedTile from './MobileReportTableNestedTile';
import { RefObjectMap } from './MobileReportTable';
import _ from 'lodash';
import VisibilitySensor from 'react-visibility-sensor';
import { TooltipWrapper } from '../../../utils';

const PREFIX = 'MobileReportTableRow';

const classes = {
  accordionSummaryRoot: `${PREFIX}-accordionSummaryRoot`,
  accordionSummaryContent: `${PREFIX}-accordionSummaryContent`,
  accordionSummaryIcon: `${PREFIX}-accordionSummaryIcon`,
};

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => {
  return {
    [`&.${classes.accordionSummaryRoot}`]: {
      flexDirection: 'row-reverse',
      padding: 'unset',
    },
    [`& .${classes.accordionSummaryContent}`]: {
      marginBottom: 0,
      '&.Mui-expanded': {
        marginTop: theme.spacing(1.5),
      },
    },
    [`& .${classes.accordionSummaryIcon}`]: {
      marginRight: 'unset',
      alignSelf: 'start',
    },
  };
});

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  paddingTop: 0,
}));

interface MobileReportTableRowProps<T, S> {
  row: T;
  columns: MobileReportTableColumn<T, S>[];
  headerId: keyof T;
  nestedHeaderId?: keyof S;
  customNestedHeaderRenderer?: (row: T, nestedRow: S) => ReactNode;
  customRowHeaderRenderer?: (row: T) => ReactNode;
  customTileListRenderer?: (
    nestedData: S[],
    topLevelIndex: number,
    scrollRefs: MutableRefObject<RefObjectMap>
  ) => ReactNode;
  hasCustomNestedTable?: boolean;
  customNestedTableHeaderRenderer?: (
    columns: MobileReportTableColumn<T, S>[],
    nestedRow: S
  ) => ReactNode;
  nestedData?: S[];
  zebra?: boolean;
  hasParentHeaders?: boolean;
  positiveNumberDecorator?: boolean;
  scrollRefs: MutableRefObject<RefObjectMap>;
  index: number;
  expand?: boolean;
  includeColumnLabelPerRow?: boolean;
}

export const MobileReportTableRow = <T, S>({
  row,
  columns,
  headerId,
  nestedHeaderId,
  customNestedHeaderRenderer,
  customRowHeaderRenderer,
  customTileListRenderer,
  nestedData = [],
  zebra = false,
  hasParentHeaders,
  hasCustomNestedTable,
  customNestedTableHeaderRenderer,
  positiveNumberDecorator,
  expand = false,
  scrollRefs,
  index,
  includeColumnLabelPerRow = true,
}: MobileReportTableRowProps<T, S>) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(expand);
  const [isVisible, setIsVisible] = useState<boolean>();

  useEffect(() => {
    if (isVisible) {
      if (scrollRef.current && scrollRefs.current) {
        const currRefs = Object.values(scrollRefs.current);
        if (currRefs.length > 0 && currRefs[0].current) {
          scrollRef.current.scrollLeft = currRefs[0].current.scrollLeft;
        }
      }
      scrollRefs.current[index] = scrollRef;
    } else {
      delete scrollRefs.current[index];
    }
  }, [isVisible]);

  const renderAccordionArrow = useCallback(() => {
    return (
      <>
        {isExpanded ? (
          <ArrowDropUpIcon fontSize='large' sx={{ marginRight: '2rem' }} />
        ) : (
          <ArrowDropDownIcon fontSize='large' sx={{ marginRight: '2rem' }} />
        )}
      </>
    );
  }, [isExpanded]);

  const onScroll = useCallback(
    (e: any) => {
      const target = e.target as HTMLDivElement;
      Object.keys(scrollRefs.current)
        .filter((key) => key !== index.toString())
        .forEach((key) => {
          const otherRef: HTMLDivElement | null =
            scrollRefs.current[key].current;
          if (otherRef !== null) {
            otherRef.scrollLeft = target.scrollLeft;
          }
        });
    },
    [scrollRefs]
  );

  const renderSummary = (nonAccordion = false) => {
    const headerText = row[headerId];
    return (
      <Box sx={{ display: 'grid', width: '100%' }}>
        <Box
          sx={(theme) => ({
            marginBottom: theme.spacing(1.5),
            paddingLeft: theme.spacing(2),
            ...theme.typography.body1,
          })}
        >
          {customRowHeaderRenderer ? (
            <Box sx={{ display: 'flex' }}>
              {!nonAccordion ? renderAccordionArrow() : null}
              {customRowHeaderRenderer(row)}
            </Box>
          ) : (
            <Box sx={{ display: 'flex' }}>
              {!nonAccordion ? renderAccordionArrow() : null}
              {typeof headerText === 'string' || typeof headerText === 'number'
                ? headerText
                : ''}
            </Box>
          )}
        </Box>
        <Box
          sx={(theme) => {
            const baseRowContainerStyle = {
              display: 'flex',
              gap: theme.spacing(5),
              overflowX: 'scroll',
              overflowY: 'hidden',
              paddingBottom: theme.spacing(1),
              paddingLeft: theme.spacing(4),
            };
            return zebra
              ? {
                  ...baseRowContainerStyle,
                  bgcolor: theme.extensions.mobileReportTable.stripe,
                }
              : baseRowContainerStyle;
          }}
          ref={scrollRef}
          onScroll={onScroll}
        >
          {hasParentHeaders &&
            columns.map((column) => {
              return (
                !column.isIgnorableColumn && (
                  <Box
                    sx={(theme) => ({
                      minWidth: theme.spacing(10),
                      py: theme.spacing(0.5),
                    })}
                    key={column.id as Key}
                    style={column.style}
                  >
                    {includeColumnLabelPerRow ? (
                      <Box
                        sx={(theme) => ({
                          ...theme.typography.body2,
                          marginBottom: theme.spacing(0.5),
                        })}
                      >
                        {column.label}
                      </Box>
                    ) : null}
                    <Box
                      sx={(theme) => ({
                        ...theme.typography.body1,
                        position: 'relative',
                      })}
                    >
                      {column.customValueRenderer ? (
                        column.customValueRenderer(row[column.id], row)
                      ) : (
                        <TooltipWrapper
                          text={getFormattedText<T, S>(
                            row[column.id],
                            column,
                            positiveNumberDecorator
                          )}
                          maxLength={
                            column.maxLength ? column.maxLength : undefined
                          }
                        />
                      )}
                    </Box>
                  </Box>
                )
              );
            })}
        </Box>
      </Box>
    );
  };

  const renderCustomNestedTable = () => {
    const rows: JSX.Element[] = [];
    nestedData.forEach((nestedRow) => {
      rows.push(
        <MobileReportNestedTable
          columns={columns}
          data={nestedRow}
          positiveNumberDecorator={positiveNumberDecorator}
          customNestedTableHeaderRenderer={customNestedTableHeaderRenderer}
        />
      );
    });
    return <div>{rows}</div>;
  };

  const renderTileList = () => {
    const tiles: JSX.Element[] = [];
    if (customTileListRenderer) {
      return customTileListRenderer(nestedData, index, scrollRefs);
    } else {
      nestedData.forEach((nestedRow, innerIndex) => {
        tiles.push(
          <MobileReportTableNestedTile
            title={nestedHeaderId ? (nestedRow[nestedHeaderId] as string) : ''}
            columns={columns}
            data={nestedRow}
            parentRow={row}
            customNestedHeaderRenderer={customNestedHeaderRenderer}
            key={JSON.stringify(nestedRow)}
            scrollRefs={scrollRefs}
            index={innerIndex}
            topLevelIndex={index}
            includeColumnLabelPerRow={includeColumnLabelPerRow}
          />
        );
      });
    }
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {tiles}
      </Box>
    );
  };

  const onChange = (isVisibleArg: boolean) => {
    setIsVisible(isVisibleArg);
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      {nestedData.length === 0 ? (
        <Paper
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
            paddingTop: theme.spacing(1.5),
            borderRadius: 'unset',
          })}
        >
          {renderSummary(true)}
        </Paper>
      ) : (
        <Accordion
          onChange={(e, expanded) => {
            setIsExpanded(expanded);
          }}
          expanded={isExpanded}
          sx={(theme) => ({
            '&.Mui-expanded': {
              margin: 0,
              borderTop: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
              borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
            },
            '&:before': {
              backgroundColor: theme.extensions.mobileReportTable.borderColor,
            },
          })}
        >
          <StyledAccordionSummary
            classes={{
              root: classes.accordionSummaryRoot,
              content: classes.accordionSummaryContent,
            }}
          >
            {renderSummary()}
          </StyledAccordionSummary>
          {hasCustomNestedTable ? (
            renderCustomNestedTable()
          ) : customTileListRenderer ? (
            renderTileList()
          ) : (
            <StyledAccordionDetails>{renderTileList()}</StyledAccordionDetails>
          )}
        </Accordion>
      )}
    </VisibilitySensor>
  );
};

export default MobileReportTableRow;
