import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useState } from 'react';

interface ViewHoldingsMenuProps {
  onViewHoldingsClick: () => void;
}

export const ViewHoldingsMenu = ({
  onViewHoldingsClick,
}: ViewHoldingsMenuProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuAnchor?.contains(event.currentTarget)) {
      setIsMenuOpen(false);
      setMenuAnchor(null);
      return;
    }
    setMenuAnchor(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleClickAway = (event: Event | React.MouseEvent<Document>) => {
    if (menuAnchor && menuAnchor.contains(event.target as HTMLButtonElement)) {
      return;
    }
    setIsMenuOpen(false);
    setMenuAnchor(null);
  };

  return (
    <>
      <IconButton
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          handleMenuClick(event);
        }}
        sx={({ spacing }) => ({
          padding: spacing(0),
          fontSize: '2.4rem',
        })}
        size="large"
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Popper
        open={isMenuOpen}
        anchorEl={menuAnchor}
        placement="top"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList autoFocusItem={isMenuOpen}>
                  <MenuItem onClick={onViewHoldingsClick}>
                    View Holdings
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ViewHoldingsMenu;
