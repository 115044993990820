import { Theme } from '@mui/material';
import dayjs from 'dayjs';

export const getDatePickerCellRender =
  (theme: Theme) =>
  (
    current: dayjs.Dayjs,
    info: {
      type: 'date' | 'month' | 'year' | 'decade' | 'week' | 'time' | 'quarter';
    }
  ) => {
    const isDisabled = current.isAfter(dayjs().subtract(1, 'day'));
    return (
      <div
        style={{
          color: isDisabled
            ? '#505050'
            : theme.palette.type === 'dark'
            ? 'white'
            : 'black',
        }}
      >
        {info.type === 'date' ? current.date() : null}
        {info.type === 'month' ? current.format('MMM') : null}
        {info.type === 'year' ? current.format('YYYY') : null}
        {info.type === 'decade'
          ? current.format('YYYY') + '-' + current.add(9, 'year').format('YYYY')
          : null}
      </div>
    );
  };
