import {
  Box,
  Chip,
  Divider,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import _ from 'lodash';
import { RefObject, useCallback, useEffect, useState } from 'react';
import AccordionItem from '../accordion/AccordionItem';
import { FilterConfig, FilterItem } from './@types';
import { v4 as uuidv4 } from 'uuid';
import SearchCriteria from './SearchCriteria';

interface TableFilterProps {
  /** List of currently selected values from state */
  values: FilterItem[];
  /** Function that sets current list of selected values in state */
  setValues: (filterItems: FilterItem[]) => void;
  /** Filter configuration object */
  config: FilterConfig;
  /** Helper text that is displayed to the user when filter is expanded */
  description?: string;
  /** Whether to show description or not */
  showDescription?: boolean;
  /** Reference to Refine your views section */
  renderRef?: RefObject<HTMLDivElement & { expandAccordition: () => void }>;
  //** Set whether the table filter should be initially open or closed */
  initialIsExpanded?: boolean;
}

export const TableFilter = ({
  values,
  setValues,
  config,
  description,
  showDescription = true,
  renderRef = undefined,
  initialIsExpanded = false,
}: TableFilterProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);

  const onValuesChange = useCallback(
    (newValues: FilterItem) => {
      if (newValues.filterType === 'date') {
        values = values.filter((x) => x.filterType !== 'date');
      }

      if (newValues.filterType === 'range') {
        values = values.filter((x) => x.filterType !== 'range');
      }

      setValues(_.uniqWith([...values, newValues], _.isEqual));
    },
    [setValues, values]
  );

  const handleChipDelete = (x: FilterItem) => {
    setValues(values.filter((y) => y.displayValue !== x.displayValue));
  };

  useEffect(() => {
    if (!renderRef?.current) return;
    renderRef.current.expandAccordition = () => {
      setIsExpanded(true);
    };
  }, [renderRef]);

  return (
    <AccordionItem
      renderRef={renderRef}
      isExpanded={isExpanded}
      onChange={(e, i) => setIsExpanded(i)}
      accordionSummaryProps={{
        sx: ({ spacing }) => ({
          flexDirection: 'row-reverse',
          borderRadius: '5px',
          expandIconWrapper: {
            marginRight: spacing(0.5),
            padding: spacing(0),
            backgroundColor: 'white',
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: spacing(0.5),
            padding: spacing(0),
          },
        }),
      }}
      summaryComponent={
        <Typography
          sx={({ typography }) => ({
            ...typography.body1,
            textTransform: 'uppercase',
          })}
        >
          Refine Your Report
        </Typography>
      }
      accordionDetailsProps={{
        sx: ({ spacing }) => ({
          paddingLeft: spacing(7),
        }),
      }}
      detailsComponent={
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Divider />
          {showDescription && (
            <>
              <Grid item xs={isMobile ? 12 : 4}>
                <Box>
                  <Typography>
                    {description
                      ? description
                      : 'Use the filters below to select what accounts are included within the scope of the results.' +
                        '  If no filter criteria is selected, then data for all accounts will show.'}
                  </Typography>
                </Box>
              </Grid>
              {!isMobile && <Grid item xs={8} />}
            </>
          )}
          <Grid item xs={isMobile ? 12 : 6}>
            <Grid item xs={12}>
              {config.config.map((criteria, index) => {
                return (
                  <SearchCriteria
                    key={`SearchCriteria_${index}`}
                    criteria={criteria}
                    onValuesChange={onValuesChange}
                  />
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography>Selected Filters: </Typography>
            <Box
              sx={({ spacing, breakpoints }) => ({
                display: 'flex',
                flex: 1,
                gap: spacing(1),
                marginTop: spacing(1),
                flexDirection: 'row',
                flexWrap: 'wrap',
                [breakpoints.down('sm')]: {
                  justifyContent: 'flex-end',
                  '& .MuiChip-root': {
                    borderRadius: '6px',
                  },
                },
              })}
            >
              {values.map((value) => (
                <Chip
                  sx={{
                    fontSize: '1.4rem',
                    lineHeight: '1.6rem',
                  }}
                  label={value.displayValue}
                  onDelete={() => handleChipDelete(value)}
                  size={isMobile ? 'small' : 'medium'}
                  key={uuidv4()}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      }
    />
  );
};

export default TableFilter;
