import {
  Box,
  Grid,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useMemo, useRef } from 'react';
import {
  ColumnDataType,
  DataAlignment,
  HeadCell,
  Order,
  RefineYourReportButton,
  SortableTable,
  SortBySelect,
  getDefaultComparator,
  getNullAtEndComparator,
  stableSort,
  FilterConfig,
  FilterItem,
  SelectableCriteria,
  filterSelected,
  TableFilter,
  MobileReportTableColumn,
  MobileReportTable,
  formatNumber,
  NumberFormatType,
  ClientPortalFinancialAccount,
  ExcelAccountItem,
  ExportToExcelButton,
  TooltipWrapper,
} from '@newedge/common';
import {
  HoldingsDetailExcelData,
  computeExcelData,
} from './HoldingsDetailHelper';
import { defaultReportDisclosuresTextLines } from '../../assets/text/ReportDisclosures';
import { HoldingsDetailModel } from './@types';

const StyledColumnDataWrapper = styled('div')(({ theme }) => ({
  ...theme.typography.body1,
}));

const getFilterOptions = (
  holdingsData: HoldingsDetailModel[],
  selectedAssetClasses: string[],
  accounts: ClientPortalFinancialAccount[],
  filterValues: FilterItem[]
): FilterConfig => {
  const assetClasses = [...new Set(holdingsData.map((o) => o.assetClass))];
  const subAssetClasses = [
    ...new Set(
      holdingsData
        // filter to include only sub-asset classes for currently selected asset classes
        .filter(
          (o) => selectedAssetClasses.findIndex((j) => j === o.assetClass) > -1
        )
        .map((o) => o.subAssetClass)
    ),
  ];
  const managementStyles = [...new Set(accounts.map((o) => o.managementStyle))];
  const tickers = [...new Set(holdingsData.map((o) => o.ticker))];
  return {
    config: [
      {
        header: 'Management Style',
        selections: filterSelected(
          managementStyles.map((name) => {
            return {
              searchValue: accounts
                .filter((account) => account.managementStyle === name)
                .map((account) => account.financialAccountId),
              predicateIdentifier: 'accountId',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
      {
        header: 'Asset Class',
        selections: filterSelected(
          assetClasses.map((name) => {
            return {
              searchValue: name,
              predicateIdentifier: 'assetClass',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
      {
        header: 'Sub-Asset Class',
        selections: filterSelected(
          subAssetClasses.map((name) => {
            return {
              searchValue: name,
              predicateIdentifier: 'subAssetClass',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
      {
        header: 'Ticker',
        selections: filterSelected(
          tickers.map((ticker) => {
            return {
              searchValue: ticker,
              predicateIdentifier: 'ticker',
              displayValue: ticker,
              logic: 'or',
            } as SelectableCriteria;
          }),
          filterValues
        ),
        filterType: 'text',
      },
    ],
  };
};

interface MobileTickerTooltipProps {
  value: string;
  maxLength: number;
}

const MobileTickerTooltip = ({
  value,
  maxLength,
}: MobileTickerTooltipProps) => {
  return (
    <Tooltip
      sx={{ fontSize: '1.4rem' }}
      title={<Box sx={{ fontSize: '1.4rem' }}>{value}</Box>}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      placement='bottom'
      PopperProps={{
        // modifiers: {
        //   computeStyle: {
        //     enabled: true,
        //     fn(data: any) {
        //       data.styles = {
        //         ...data.styles,
        //         width: 'fit-content',
        //       };
        //       return data;
        //     },
        //   },
        // },
        disablePortal: true,
      }}
    >
      <Typography>{`${value.substring(0, maxLength)}...`}</Typography>
    </Tooltip>
  );
};

const priceQtySortRenderer = (
  setOrder: (order: Order) => void,
  setOrderBy: (orderBy: keyof HoldingsDetailModel) => void,
  close: () => void
) => {
  return (
    <SortBySelect
      options={[
        {
          direction: Order.Asc,
          displayValue: 'Price: Lowest to Highest',
          onClick: () => {
            setOrder(Order.Asc);
            setOrderBy('price');
            close();
          },
        },
        {
          direction: Order.Desc,
          displayValue: 'Price: Highest to Lowest',
          onClick: () => {
            setOrder(Order.Desc);
            setOrderBy('price');
            close();
          },
        },
        {
          direction: Order.Asc,
          displayValue: 'Qty: Lowest to Highest',
          onClick: () => {
            setOrder(Order.Asc);
            setOrderBy('quantity');
            close();
          },
        },
        {
          direction: Order.Desc,
          displayValue: 'Qty: Highest to Lowest',
          onClick: () => {
            setOrder(Order.Desc);
            setOrderBy('quantity');
            close();
          },
        },
      ]}
      close={close}
    />
  );
};

const unrealizedGLSortRenderer = (
  setOrder: (order: Order) => void,
  setOrderBy: (orderBy: keyof HoldingsDetailModel) => void,
  close: () => void
) => {
  return (
    <SortBySelect
      options={[
        {
          direction: Order.Asc,
          displayValue: 'Dollar: Lowest to Highest',
          onClick: () => {
            setOrder(Order.Asc);
            setOrderBy('unrealizedGain');
            close();
          },
        },
        {
          direction: Order.Desc,
          displayValue: 'Dollar: Highest to Lowest',
          onClick: () => {
            setOrder(Order.Desc);
            setOrderBy('unrealizedGain');
            close();
          },
        },
        {
          direction: Order.Asc,
          displayValue: 'Percent: Lowest to Highest',
          onClick: () => {
            setOrder(Order.Asc);
            setOrderBy('unrealizedGainPercent');
            close();
          },
        },
        {
          direction: Order.Desc,
          displayValue: 'Percent: Highest to Lowest',
          onClick: () => {
            setOrder(Order.Desc);
            setOrderBy('unrealizedGainPercent');
            close();
          },
        },
      ]}
      close={close}
    />
  );
};

interface HoldingsDetailViewProps {
  holdingsData: HoldingsDetailModel[];
  date: any;
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
  hasInitialFilters: boolean;
  accounts: ClientPortalFinancialAccount[];
  selectedAccounts: number[];
}

export const HoldingsDetailView = ({
  holdingsData,
  date,
  filterValues,
  setFilterValues,
  setAsOfDate,
  hasInitialFilters,
  accounts,
}: HoldingsDetailViewProps) => {
  if (date) {
    setAsOfDate(date);
  }

  const headCells: HeadCell<HoldingsDetailModel>[] = [
    {
      id: 'productName',
      dataType: ColumnDataType.String,
      label: 'Holding',
      maxLength: 25,
    },
    {
      id: 'ticker',
      dataType: ColumnDataType.String,
      label: 'Ticker',
      maxLength: 5,
    },
    {
      id: 'accountNickname',
      dataType: ColumnDataType.String,
      label: 'Account Name',
      maxLength: 72,
    },
    { id: 'assetClass', dataType: ColumnDataType.String, label: 'Asset Class' },
    {
      id: 'subAssetClass',
      dataType: ColumnDataType.String,
      label: 'Sub-Asset Class',
    },
    {
      id: 'price',
      relatedIds: ['quantity'],
      dataType: ColumnDataType.Currency,
      label: 'Price/Qty',
      precision: 2,
      minimumDecimalToDisplay: 2,
      customSortRenderer: priceQtySortRenderer,
      customCellRenderer: (value, headCell, row, format) => {
        return (
          <>
            <StyledColumnDataWrapper>
              {value != null ? format(value, headCell) : 'N/A'}
            </StyledColumnDataWrapper>
            <StyledColumnDataWrapper>
              {row.quantity != null
                ? format(row.quantity, {
                    id: 'quantity',
                    dataType: ColumnDataType.Number,
                    label: 'Qty',
                    precision: 2,
                    minimumDecimalToDisplay: 2,
                  })
                : 'N/A'}
            </StyledColumnDataWrapper>
          </>
        );
      },
    },
    {
      id: 'currentMarketValue',
      dataType: ColumnDataType.Currency,
      label: 'Mkt Value',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'costBasis',
      dataType: ColumnDataType.Currency,
      label: 'Cost Basis',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'unrealizedGain',
      relatedIds: ['unrealizedGainPercent'],
      dataType: ColumnDataType.Currency,
      label: 'Unrealized G/L',
      customSortRenderer: unrealizedGLSortRenderer,
      customSortComparator: getNullAtEndComparator,
      customCellRenderer: (value, headCell, row, format) => {
        return (
          <>
            <StyledColumnDataWrapper>
              {value != null ? format(value, headCell) : 'N/A'}
            </StyledColumnDataWrapper>
            <StyledColumnDataWrapper>
              {row.unrealizedGainPercent || row.unrealizedGainPercent === 0
                ? format(row.unrealizedGainPercent, {
                    id: 'unrealizedGainPercent',
                    dataType: ColumnDataType.Percent,
                    label: 'Unrealized G/L (%)',
                    precision: 2,
                    minimumDecimalToDisplay: 2,
                  })
                : 'N/A'}
            </StyledColumnDataWrapper>
          </>
        );
      },
    },
    {
      id: 'yield',
      dataType: ColumnDataType.Percent,
      label: 'Yield',
      nullDisplay: 'N/A',
    },
  ];

  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const selectedAccountList = useMemo(() => {
    const accountList: ExcelAccountItem[] = [];

    holdingsData.forEach((o) => {
      const accountInfo = accounts.find(
        (o2) => o2.financialAccountId === o.accountId
      );

      if (accountInfo) {
        if (
          !accountList.find(
            (o3) => o3.accountNumber === accountInfo.accountNumber
          )
        ) {
          accountList.push({
            accountNumber: accountInfo.accountNumber,
            accountName: accountInfo.nicknameEntry
              ? accountInfo.nicknameEntry.accountNickname
              : accountInfo.accountName,
            custodian: accountInfo.custodian ?? '',
          });
        }
      }
    });
    return accountList;
  }, [accounts, holdingsData]);

  const filenamePrefix = 'Holdings';
  const dataSheetTitle = 'Holdings';

  const excelData = useMemo(() => {
    return computeExcelData(holdingsData);
  }, [holdingsData]);

  const getMobileTickerTooltip = (value: string, maxLength = 5) => {
    return <MobileTickerTooltip value={value} maxLength={maxLength} />;
  };

  const mobileReportColumns: MobileReportTableColumn<HoldingsDetailModel>[] = [
    {
      id: 'ticker',
      label: 'Ticker',
      dataType: ColumnDataType.String,
      customValueRenderer: (value) => {
        const maxLength = 5;
        if (value && (value as string).length > maxLength) {
          return getMobileTickerTooltip(value as string);
        }
        return value;
      },
    },
    {
      id: 'assetClass',
      label: 'Asset Class',
      dataType: ColumnDataType.String,
    },
    {
      id: 'subAssetClass',
      label: 'Sub-Asset Class',
      dataType: ColumnDataType.String,
      style: { minWidth: '10rem' },
    },
    {
      id: 'price',
      label: 'PRICE/QTY',
      dataType: ColumnDataType.Currency,
      customValueRenderer: (value, row) => {
        const price =
          value === null ? 'N/A' : `${formatNumber(value as number)}`;
        const qty =
          row?.quantity === null
            ? 'N/A'
            : `${formatNumber(
                row?.quantity as number,
                NumberFormatType.Number,
                2,
                2
              )}`;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>{price}</span>
            <span>{qty}</span>
          </Box>
        );
      },
    },
    {
      id: 'currentMarketValue',
      label: 'MKT Value',
      dataType: ColumnDataType.Currency,
    },
    {
      id: 'costBasis',
      label: 'Cost Basis',
      dataType: ColumnDataType.Currency,
    },
    {
      id: 'unrealizedGain',
      label: 'Unrealized G/L',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '11rem' },
      customValueRenderer: (value, row) => {
        const dollarAmount =
          value === null ? 'N/A' : `${formatNumber(value as number)}`;
        const percentage =
          row?.unrealizedGainPercent === null
            ? 'N/A'
            : `${formatNumber(
                row?.unrealizedGainPercent as number,
                NumberFormatType.Percent,
                2
              )}`;
        return (
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <span>{dollarAmount}</span>
            <span>{percentage}</span>
          </Box>
        );
      },
    },
    {
      id: 'yield',
      label: 'Yield',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => {
        if (value && (value as number) > 0) {
          return `${formatNumber(
            value as number,
            NumberFormatType.Percent,
            2
          )}`;
        }
        return 'N/A';
      },
    },
  ];

  const mobileSortedHoldingsData = stableSort<HoldingsDetailModel>(
    holdingsData,
    getDefaultComparator(Order.Asc, 'productName') as (
      a: HoldingsDetailModel,
      b: HoldingsDetailModel
    ) => number
  );

  const renderMobileReport = () => {
    return (
      <MobileReportTable
        zebra
        columns={mobileReportColumns}
        data={mobileSortedHoldingsData}
        headerId='accountId'
        title='Holdings'
        customRowHeaderRenderer={(row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TooltipWrapper
                text={row.accountNickname ?? ''}
                maxLength={72}
                typographyVariant='body2'
                typographyStyles={{
                  color: '#888889',
                }}
              />
              <StyledColumnDataWrapper>
                {row.productName}
              </StyledColumnDataWrapper>
            </div>
          );
        }}
      />
    );
  };

  const handlerScrollToTop = () => {
    renderRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    renderRef.current?.expandAccordition();
  };

  const filterConfig = useMemo(
    () =>
      getFilterOptions(
        holdingsData,
        filterValues
          .filter((o) => o.predicateIdentifier === 'assetClass')
          .map((o) => o.searchValue) as string[],
        accounts,
        filterValues
      ),
    [accounts, filterValues, holdingsData]
  );

  return (
    <div>
      <Grid container spacing={3} justifyContent='space-between'>
        <Grid item xs={isMobileOrTablet ? 12 : 8}>
          <TableFilter
            values={filterValues}
            setValues={setFilterValues}
            config={filterConfig}
            renderRef={renderRef}
            initialIsExpanded={hasInitialFilters}
          />
        </Grid>
        {!isMobileOrTablet ? (
          <Grid item xs={4}>
            <Box sx={{ width: '100%', display: 'grid' }}>
              <Box sx={{ justifySelf: 'flex-end' }}>
                <ExportToExcelButton<HoldingsDetailExcelData>
                  data={excelData}
                  accounts={selectedAccountList}
                  accountListColumns={[
                    {
                      key: 'accountNumber',
                      label: 'Account Number',
                    },
                    {
                      key: 'accountName',
                      label: 'Account Name',
                    },
                    {
                      key: 'custodian',
                      label: 'Custodian',
                    },
                  ]}
                  filenamePrefix={filenamePrefix}
                  dataSheetTitle={dataSheetTitle}
                  columnsToAutoSize={[
                    'Holding',
                    'Account Name',
                    'Asset Class',
                    'Mkt Value',
                    'Sub-Asset Class',
                    'Ticker',
                  ]}
                  disclosuresTextLines={defaultReportDisclosuresTextLines}
                />
              </Box>
            </Box>
          </Grid>
        ) : null}
      </Grid>
      <br />
      {isMobileOrTablet ? (
        <>
          {renderMobileReport()}
          <RefineYourReportButton onClick={handlerScrollToTop} />
        </>
      ) : (
        <SortableTable
          headCells={headCells}
          dataRows={holdingsData}
          initialSortColumn='productName'
          keyFields={['holdingsId']}
          stickyHeader
        />
      )}
    </div>
  );
};
