import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DataAlignment } from '../sortable-table/@types';
import { CompactTableColumn } from './@types';
import { CompactTableRow } from './CompactTableRow';

const StyledTableCell = styled(TableCell)(({ theme: { spacing } }) => {
  return {
    padding: spacing(1),
    fontSize: '1.4rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
  };
});

interface CompactTableProps<T> {
  columns: CompactTableColumn<T>[];
  data: T[];
}

export const CompactTable = <T extends unknown>({
  columns,
  data,
}: CompactTableProps<T>) => {
  return (
    <TableContainer>
      <Table aria-label='compact table' sx={{ tableLayout: 'fixed' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell width='5%' />
            {columns.map((column) => {
              const width = column.width;
              return (
                <StyledTableCell
                  width={width ? width : undefined}
                  align={
                    column.dataAlignment === DataAlignment.Right
                      ? 'right'
                      : 'left'
                  }
                  key={column.id as any}
                >
                  {column.label}
                </StyledTableCell>
              );
            })}
            <StyledTableCell width='5%' />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            return (
              <CompactTableRow
                columns={columns}
                rowData={row}
                key={`${typeof row}_${index}`}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CompactTable;
