import { ComponentType, Suspense, useEffect } from 'react';
import { LoadQueryOptions, PreloadedQuery, useQueryLoader } from 'react-relay';
import { GraphQLTaggedNode, OperationType, Variables } from 'relay-runtime';
import { PropsWithRelayRouteQueryRef } from './@types';

export type RelayRouteWithDataRefProps<TQuery extends OperationType> = {
  fallback?: React.ReactElement;
  query: GraphQLTaggedNode;
  initialVariables: Variables;
  initialQueryOptions?: LoadQueryOptions;
  environmentProviderOptions?: any;
  innerComponent: ComponentType<PropsWithRelayRouteQueryRef<any, any>>;
  initialQueryRef?: PreloadedQuery<TQuery>;
};

export const RelayRouteWithQuery = <TQuery extends OperationType>({
  fallback,
  query,
  initialQueryOptions,
  initialVariables,
  innerComponent: Component,
  initialQueryRef,
}: RelayRouteWithDataRefProps<TQuery>) => {
  const [queryReference, loadRouteQuery] = useQueryLoader(
    query,
    initialQueryRef
  );

  useEffect(() => {
    loadRouteQuery(initialVariables, initialQueryOptions);
  }, [initialQueryOptions, initialVariables, loadRouteQuery]);

  return (
    <Suspense fallback={fallback ?? false}>
      {queryReference && (
        <Component
          routeQueryRef={queryReference}
          loadRouteQuery={loadRouteQuery}
        />
      )}
    </Suspense>
  );
};
