import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useMemo, useRef } from 'react';
import { DateTime } from 'luxon';
import { computeExcelData } from './AccountActivityHelper';
import { defaultReportDisclosuresTextLines } from '../../assets/text/ReportDisclosures';
import {
  ClientPortalFinancialAccount,
  ColumnDataType,
  ExcelAccountItem,
  ExportToExcelButton,
  FilterConfig,
  FilterItem,
  HeadCell,
  MobileReportTable,
  MobileReportTableColumn,
  Order,
  RefineYourReportButton,
  SelectableCriteria,
  SortableTable,
  TableFilter,
  filterSelected,
  formatNumber,
  notEmpty,
} from '@newedge/common';
import { AccountActivityExcelData, AccountActivityModel } from './@types';

export interface AccountActivityFilterOptions {
  managementStyles: string[];
  transactionTypes: string[];
  minTransactionAmount: number;
  maxTransactionAmount: number;
}

interface AccountActivityViewProps {
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  filterOptions: AccountActivityFilterOptions;
  hasInitialFilters: boolean;
  accounts: ClientPortalFinancialAccount[];
  filteredAccountActivityData: AccountActivityModel[];
}

export const AccountActivityView = ({
  filterValues,
  setFilterValues,
  filterOptions,
  hasInitialFilters,
  accounts,
  filteredAccountActivityData,
}: AccountActivityViewProps) => {
  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  const handlerScrollToTop = () => {
    renderRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    renderRef.current?.expandAccordition();
  };

  const getFilterConfig = (): FilterConfig => {
    return {
      config: [
        {
          header: 'Management Style',
          selections: filterSelected(
            filterOptions.managementStyles.map((name) => {
              return {
                searchValue: accounts
                  .filter((account) => account.managementStyle === name)
                  .map((account) => account.financialAccountId),
                predicateIdentifier: 'accountId',
                displayValue: name,
              };
            }),
            filterValues
          ),
          filterType: 'text',
        },
        {
          header: 'Date',
          predicateIdentifier: 'transactionDate',
          filterType: 'date',
        },
        {
          header: 'Transaction Amount Range',
          min: filterOptions.minTransactionAmount,
          max: filterOptions.maxTransactionAmount,
          predicateIdentifier: 'amount',
          filterType: 'range',
        },
        {
          header: 'Transaction Type',
          selections: filterSelected(
            filterOptions.transactionTypes
              .filter(notEmpty)
              .map((transactionType) => {
                const returnValue: SelectableCriteria = {
                  searchValue: transactionType,
                  predicateIdentifier: 'transactionType',
                  displayValue: transactionType,
                  logic: 'or',
                };
                return returnValue;
              }),
            filterValues
          ),
          filterType: 'text',
        },
        {
          header: 'Keyword',
          selections: [],
          allowArbitraryValue: true,
          filterType: 'keyword',
        },
      ],
    };
  };

  const getTransactionTypeText = (
    value: string | null | undefined
  ): string | null | undefined => {
    if (value === 'Buy Exchange') {
      return 'Buy';
    } else if (value === 'Sell Exchange') {
      return 'Sell';
    }

    return value;
  };

  const headCells: HeadCell<AccountActivityModel>[] = [
    {
      id: 'transactionDate',
      dataType: ColumnDataType.Date,
      label: 'Date',
      customCellRenderer: (value) => {
        return (
          <Typography>
            {DateTime.fromISO(value, {
              zone: 'utc',
            }).toFormat('MM/dd/yyyy')}
          </Typography>
        );
      },
    },
    {
      id: 'transactionType',
      dataType: ColumnDataType.String,
      label: 'Transaction Type',
      customCellRenderer: (value) => {
        return <Typography>{getTransactionTypeText(value)}</Typography>;
      },
    },
    {
      id: 'accountNickname',
      dataType: ColumnDataType.String,
      label: 'Account Name',
      maxLength: 72,
    },
    {
      id: 'accountNumber',
      dataType: ColumnDataType.String,
      label: 'Account Number',
    },
    {
      id: 'ticker',
      dataType: ColumnDataType.String,
      label: 'Ticker',
      maxLength: 5,
    },
    {
      id: 'productName',
      dataType: ColumnDataType.String,
      label: 'Name',
      maxLength: 25,
    },
    { id: 'units', dataType: ColumnDataType.Number, label: 'Units' },
    { id: 'price', dataType: ColumnDataType.Currency, label: 'Price' },
    {
      id: 'amount',
      dataType: ColumnDataType.Currency,
      label: 'Amount',
      icon: (rowData) => {
        if (!rowData.amount) {
          return null;
        } else if (rowData.amount > 0) {
          return <AddIcon style={{ fontSize: '1.4rem' }} />;
        }
        return <RemoveIcon style={{ fontSize: '1.4rem' }} />;
      },
      transformFn: (value) => {
        return value === null || value === undefined
          ? 'N/A'
          : formatNumber(Math.abs(value));
      },
    },
  ];

  let keywordFilteredActivityData = filteredAccountActivityData.sort((a, b) =>
    a.transactionDate < b.transactionDate
      ? 1
      : a.transactionDate > b.transactionDate
      ? -1
      : 0
  );

  const keywordFilters: FilterItem[] = filterValues.filter(
    (o) => o.filterType === 'keyword'
  );

  if (keywordFilters.length > 0) {
    keywordFilteredActivityData = filteredAccountActivityData.filter((o) => {
      return Object.entries(o).some((value) => {
        return keywordFilters.some((keyword) => {
          if (
            keyword.searchValue &&
            value[1] !== null &&
            value[1]
              .toString()
              .toLowerCase()
              .indexOf(keyword.searchValue?.toString().toLowerCase()) > -1
          ) {
            return true;
          }
          return false;
        });
      });
    });
  }

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const selectedAccountList = useMemo(() => {
    const accountList: ExcelAccountItem[] = [];

    filteredAccountActivityData.forEach((o) => {
      const accountInfo = accounts.find(
        (o2) => o2.financialAccountId === o.accountId
      );

      if (accountInfo) {
        if (
          !accountList.find(
            (o3) => o3.accountNumber === accountInfo.accountNumber
          )
        ) {
          accountList.push({
            accountNumber: accountInfo.accountNumber,
            accountName: accountInfo.nicknameEntry
              ? accountInfo.nicknameEntry.accountNickname
              : accountInfo.accountName,
            custodian: accountInfo.custodian ?? '',
          });
        }
      }
    });
    return accountList;
  }, [accounts, filteredAccountActivityData]);

  const filenamePrefix = 'AccountActivity';
  const dataSheetTitle = 'Account Activity';

  const excelData = useMemo(() => {
    return computeExcelData(filteredAccountActivityData);
  }, [filteredAccountActivityData]);

  const mobileReportColumns: MobileReportTableColumn<AccountActivityModel>[] = [
    {
      id: 'amount',
      label: 'Amount',
      dataType: ColumnDataType.Currency,
      customValueRenderer: (value) => {
        if (value === null || value === undefined) {
          return 'N/A';
        } else if ((value as number) > 0) {
          return `+${formatNumber(value as number)}`;
        }
        return formatNumber(value as number);
      },
    },
    {
      id: 'accountNickname',
      label: 'Account Name',
      dataType: ColumnDataType.String,
      style: { minWidth: '20rem' },
      maxLength: 72,
    },
    {
      id: 'accountNumber',
      label: 'Account #',
      dataType: ColumnDataType.String,
    },
    {
      id: 'ticker',
      label: 'Ticker',
      dataType: ColumnDataType.String,
    },
    {
      id: 'productName',
      label: 'Name',
      dataType: ColumnDataType.String,
      style: { minWidth: '16rem' },
    },
    {
      id: 'units',
      label: 'Units',
      dataType: ColumnDataType.Number,
    },
    {
      id: 'price',
      label: 'Price',
      dataType: ColumnDataType.Currency,
    },
  ];

  const renderMobileReport = () => {
    return (
      <>
        <MobileReportTable
          zebra
          columns={mobileReportColumns}
          data={keywordFilteredActivityData}
          headerId='transactionType'
          title='Transactions'
          customRowHeaderRenderer={(row) => {
            return (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  component='span'
                  sx={{
                    fontSize: '1.4rem',
                    color: '#888888',
                    marginBottom: 0.5,
                  }}
                >
                  {row.transactionDate
                    ? DateTime.fromISO(row.transactionDate, {
                        zone: 'utc',
                      }).toFormat('MM/dd/yyyy')
                    : 'N/A'}
                </Box>
                <span>
                  {getTransactionTypeText(row.transactionType) ?? 'N/A'}
                </span>
              </div>
            );
          }}
          showNoDataMessage
        />
        <RefineYourReportButton onClick={handlerScrollToTop} />
      </>
    );
  };

  return (
    <Grid container spacing={3} justifyContent='space-between'>
      <Grid item xs={isMobileOrTablet ? 12 : 8}>
        <TableFilter
          values={filterValues}
          setValues={setFilterValues}
          config={getFilterConfig()}
          renderRef={renderRef}
          initialIsExpanded={hasInitialFilters}
        />
      </Grid>
      {!isMobileOrTablet ? (
      <Grid item xs={4}>
        <Box sx={{ width: '100%', display: 'grid' }}>
          <Box sx={{ justifySelf: 'flex-end' }}>
            <ExportToExcelButton<AccountActivityExcelData>
              data={excelData}
              accounts={selectedAccountList}
              accountListColumns={[
                {
                  key: 'accountNumber',
                  label: 'Account Number',
                },
                {
                  key: 'accountName',
                  label: 'Account Name',
                },
                {
                  key: 'custodian',
                  label: 'Custodian',
                },
              ]}
              filenamePrefix={filenamePrefix}
              dataSheetTitle={dataSheetTitle}
              columnsToAutoSize={[
                'Date',
                'Transaction Type',
                'Account Name',
                'Account Number',
                'Ticker',
                'Name',
                'Units',
                'Price',
                'Amount',
              ]}
              disclosuresTextLines={defaultReportDisclosuresTextLines}
            />
          </Box>
        </Box>
      </Grid>
      ) : null}
      <Grid item xs={12}>
        {isMobileOrTablet ? (
          renderMobileReport()
        ) : (
          <SortableTable
            headCells={headCells}
            dataRows={keywordFilteredActivityData}
            initialSortColumn='transactionDate'
            initialSortDirection={Order.Desc}
            stickyHeader
            showNoDataMessage
          />
        )}
      </Grid>
    </Grid>
  );
};
