import { Box } from '@mui/material';
import { Key, MutableRefObject, ReactNode, useCallback, useId, useRef } from 'react';
import { getFormattedText } from '../TableHelpers';
import { MobileReportTableColumn } from './@types';
import { RefObjectMap } from './MobileReportTable';
import _ from 'lodash';

interface MobileReportTableNestedTilesProps<T, S> {
  title: string;
  columns: MobileReportTableColumn<T, S>[];
  data: S;
  parentRow: T;
  customNestedHeaderRenderer?: (row: T, nestedRow: S) => ReactNode;
  scrollRefs: MutableRefObject<RefObjectMap>;
  index: number;
  topLevelIndex: number;
  includeColumnLabelPerRow?: boolean;
}

export const MobileReportTableNestedTile = <T, S>({
  title,
  columns,
  data,
  parentRow,
  customNestedHeaderRenderer,
  scrollRefs,
  index,
  topLevelIndex,
  includeColumnLabelPerRow = true,
}: MobileReportTableNestedTilesProps<T, S>) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRefKey = topLevelIndex + '_' + index;
  scrollRefs.current[scrollRefKey] = scrollRef;

  const onScroll = useCallback(
    (e: any) => {
      const target = e.target as HTMLDivElement;
      Object.keys(scrollRefs.current)
        .filter((key) => key !== scrollRefKey)
        .forEach((key) => {
          const otherRef = scrollRefs.current[key].current;
          if (otherRef !== null) {
            otherRef.scrollLeft = target.scrollLeft;
          }
        });
    },
    [scrollRefs]
  );

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
        borderRadius: '10px',
        padding: theme.spacing(1),
        paddingBottom: 0,
        margin: theme.spacing(1),
        display: 'grid',
      })}
    >
      <div>
        {customNestedHeaderRenderer ? (
          <span>{customNestedHeaderRenderer(parentRow, data)}</span>
        ) : (
          <Box
            sx={(theme) => ({
              fontSize: '1.4rem',
              borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
              paddingBottom: theme.spacing(0.5),
              marginBottom: theme.spacing(1),
              display: 'flex',
              justifyContent: 'space-between',
            })}
          >
            <span>{title}</span>
          </Box>
        )}
      </div>
      <Box
        sx={(theme) => ({
          display: 'flex',
          gap: theme.spacing(5),
          overflowX: 'scroll',
          overflowY: 'hidden',
          paddingBottom: theme.spacing(1),
        })}
        ref={scrollRef}
        onScroll={onScroll}
      >
        {columns.map((column) => {
          if (!column.isIgnorableColumn) {
            return (
              <Box
                sx={(theme) => ({ minWidth: theme.spacing(10) })}
                key={useId()}
                style={column.style}
              >
                {includeColumnLabelPerRow ? (
                  <Box
                    sx={(theme) => ({
                      ...theme.typography.body2,
                      marginBottom: theme.spacing(0.5),
                    })}
                  >
                    {column.label}
                  </Box>
                ) : null}
                <Box sx={(theme) => ({ ...theme.typography.body1 })}>
                  {column?.nestedId
                    ? column.customValueRenderer
                      ? column.customValueRenderer(data[column.nestedId], data)
                      : getFormattedText<T, S>(data[column.nestedId], column)
                    : ''}
                </Box>
              </Box>
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default MobileReportTableNestedTile;
