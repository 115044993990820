import { Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { MutableRefObject, Suspense, useMemo, useRef } from 'react';
import {
  ClientPortalFinancialAccount,
  RefineYourReportButton,
  ToutsWidget,
  ColumnDataType,
  FilterConfig,
  FilterItem,
  TableFilter,
  filterSelected,
  MobileReportTable,
  MobileReportTableColumn,
  RefObjectMap,
  getIdFromNodeId,
  ExportToExcelButton,
  ExcelAccountItem,
  Loading,
} from '@newedge/common';
import {
  CashFlowDetailProjectionItem,
  CashFlowDetailTableNestedRowData,
  CashFlowDetailTableRowData,
  MobileCashFlowDetailTableRowData,
} from './@types';
import { CashFlowDetailTable } from './cash-flow-table';
import {
  computeCashFlowExcelData,
  computeMobileTableData,
  computeTableData,
  computeCashFlowToutData,
} from './cashFlowDetailHelpers';
import CashFlowDetailMobileRow from './mobile/CashFlowDetailMobileRow';
import { DateTime } from 'luxon';
import { cashFlowDisclosuresTextLines } from '../../assets/text/ReportDisclosures';

const getCashFlowDetailViewSelectableCriteria = (
  financialAccounts: ClientPortalFinancialAccount[],
  filterValues: FilterItem[]
) => {
  const managementStyles = [
    ...new Set(financialAccounts.map((account) => account.managementStyle)),
  ];
  return {
    config: [
      {
        header: 'Management Style',
        selections: filterSelected(
          managementStyles.map((name) => {
            return {
              searchValue: financialAccounts
                .filter((account) => account.managementStyle === name)
                .map((account) => account.financialAccountId),
              predicateIdentifier: 'accountId',
              displayValue: name,
            };
          }),
          filterValues
        ),
        filterType: 'text',
      },
    ],
  };
};

type CashFlowDetailViewProps = {
  filterValues: FilterItem[];
  setFilterValues: (filterValues: FilterItem[]) => void;
  setAsOfDate: (asOfDate: string) => void;
  date: any;
  accounts: ClientPortalFinancialAccount[];
  selectedAccounts: number[];
  cashFlowDetailProjection: CashFlowDetailProjectionItem[];
};

const todaysDate = new Date();

export const CashFlowDetailView = ({
  filterValues,
  setFilterValues,
  setAsOfDate,
  date,
  accounts: financialAccounts,
  selectedAccounts,
  cashFlowDetailProjection,
}: CashFlowDetailViewProps) => {
  if (date !== undefined && date !== null) {
    setAsOfDate(date);
  }

  const filterConfig = useMemo(() => {
    return getCashFlowDetailViewSelectableCriteria(
      financialAccounts,
      filterValues
    );
  }, [financialAccounts, filterValues]);

  const customTileListRenderer = (
    nestedData: CashFlowDetailTableNestedRowData[],
    topLevelIndex: number,
    scrollRefs: MutableRefObject<RefObjectMap>
  ) => {
    return (
      <>
        {nestedData.map((nestedItem, index) => {
          return (
            <div key={`nested-data-CashFlowDetailTableNestedRowData-${index}`}>
              <Box
                sx={(theme) => ({
                  padding: theme.spacing(0.5),
                  margin: theme.spacing(1),
                  display: 'flex',
                  justifyContent: 'space-between',
                })}
              >
                <Typography variant='body1'>{nestedItem.category}</Typography>
              </Box>
              <CashFlowDetailMobileRow
                nestedItem={nestedItem}
                subTitle={'Dividends'}
                scrollRefs={scrollRefs}
                topLevelIndex={topLevelIndex}
                nestedIndex={index}
              />
              <CashFlowDetailMobileRow
                nestedItem={nestedItem}
                subTitle={'Interest Payments'}
                scrollRefs={scrollRefs}
                topLevelIndex={topLevelIndex}
                nestedIndex={index}
              />
            </div>
          );
        })}
      </>
    );
  };

  const renderMonthHeaderCells = () => {
    //starting with i = 1 by default to start with next month instead of the current month.
    const cashFlowDetailColumns: MobileReportTableColumn<
      MobileCashFlowDetailTableRowData,
      CashFlowDetailTableNestedRowData
    >[] = [];

    for (let i = 1; i <= 12; i++) {
      const headerDate = DateTime.now().plus({ months: i });

      cashFlowDetailColumns.push({
        id: (i - 1) as keyof MobileCashFlowDetailTableRowData, // index 0 => first column, index 1 => second column, etc.
        nestedId: 'category',
        label: `${headerDate.toFormat('MMM yyyy')}`,
        dataType: ColumnDataType.Currency,
        precision: 0,
      });
    }

    cashFlowDetailColumns.push({
      id: 'total',
      label: 'Total',
      dataType: ColumnDataType.Currency,
      precision: 0,
    });

    return cashFlowDetailColumns;
  };

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const customRowHeaderRenderFunction = (
    cashFlowDetailDataRow: MobileCashFlowDetailTableRowData
  ) => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography>{cashFlowDetailDataRow.category}</Typography>
      </Box>
    );
  };

  const renderMobileReport = () => {
    return (
      <>
        <MobileReportTable
          columns={renderMonthHeaderCells()}
          data={computeMobileTableData(cashFlowDetailProjection)}
          title='Monthly Summary'
          headerId={
            (todaysDate.getMonth() +
              1) as keyof MobileCashFlowDetailTableRowData
          }
          nestedAccessor={(rowData) =>
            rowData.details != null ? rowData.details : []
          }
          customRowHeaderRenderer={customRowHeaderRenderFunction}
          customTileListRenderer={customTileListRenderer}
          includeColumnLabelPerRow={false}
        />
      </>
    );
  };

  const renderRef = useRef<HTMLDivElement & { expandAccordition: () => void }>(
    null
  );

  const handlerScrollToTop = () => {
    renderRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
    renderRef.current?.expandAccordition();
  };

  const cashFlowTableData: CashFlowDetailTableRowData[] = computeTableData(
    cashFlowDetailProjection
  );

  const selectedAccountList = useMemo(() => {
    const accounts: ExcelAccountItem[] = [];
    selectedAccounts.forEach((acctId) => {
      const accountInfo = financialAccounts.find(
        (o) => o.financialAccountId === acctId
      );
      if (accountInfo) {
        accounts.push({
          accountNumber: accountInfo.accountNumber,
          accountName: accountInfo.nicknameEntry
            ? accountInfo.nicknameEntry.accountNickname
            : accountInfo.accountName,
          custodian: accountInfo.custodian ? accountInfo.custodian : '',
        });
      }
    });
    return accounts;
  }, [financialAccounts, selectedAccounts]);

  return (
    <Grid container spacing={3}>
      <Suspense fallback={<Loading />}>
        <ToutsWidget data={computeCashFlowToutData(cashFlowTableData)} />
      </Suspense>
      <Grid item xs={isMobileOrTablet ? 12 : 8}>
        <TableFilter
          values={filterValues}
          setValues={setFilterValues}
          config={filterConfig as FilterConfig}
          renderRef={renderRef}
        />
      </Grid>
      {!isMobileOrTablet ? (
        <Grid item xs={4}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ExportToExcelButton
              data={computeCashFlowExcelData(cashFlowTableData)}
              accounts={selectedAccountList}
              accountListColumns={[
                {
                  key: 'accountNumber',
                  label: 'Account Number',
                },
                {
                  key: 'accountName',
                  label: 'Account Name',
                },
                {
                  key: 'custodian',
                  label: 'Custodian',
                },
              ]}
              filenamePrefix='ExpectedCashFlow'
              dataSheetTitle='Expected Cash Flows'
              columnsToAutoSize={[
                'Account Tax Status',
                'Tax Liability',
                'Payment Type',
              ]}
              disclosuresTextLines={cashFlowDisclosuresTextLines}
            />
          </Box>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {isMobileOrTablet ? (
          <>
            {renderMobileReport()}
            <RefineYourReportButton onClick={handlerScrollToTop} />
          </>
        ) : (
          <CashFlowDetailTable data={cashFlowTableData} />
        )}
      </Grid>
    </Grid>
  );
};
