// ! Do not use the types in this file directly
// the only way this should be interacted with is through the theme in material-ui via useTheme, makeStyles, etc

// Color naming http://chir.ag/projects/name-that-color/
//              https://www.htmlcsscolor.com/hex/FFFFFF
export const namedColors = {
  white: '#fff',
  hintOfRed: '#FBF9F9',
  concrete: '#F2F2F2',
  alabaster: '#F8F8F8',
  wildSand: '#F4F4F4',
  mercury: '#e8e8e8',
  alto: '#D8D8D8',
  boulder: '#797979',
  zambezi: '#5F5D5D',
  emperor: '#565454',
  tundora: '#424242',
  mineShaft: '#2C2C2C',
  black: '#000',
  eucalyptus: '#1E9868',
  lightEucalyptus: '#63A289',
  gray: '#706565',
  lightGray: '#999999',
  orange: '#FFA600',
  lightOrange: '#FFCC99',
  sushi: '#8AAB2C',
  lightSushi: '#ABBB7D',
  teal: '#007A80',
  lightTeal: '#669999',
  navajoWhite: '#FFDFAA',
  cornflowerBlue: '#98C5E3',
};

export default namedColors;
