import { Tab, Tabs } from '@mui/material';

interface TabDefinition {
  id: string;
  label: string;
}

interface MobileTableTabsProps {
  value: string;
  onChange: (event: React.ChangeEvent<{}>, newValue: string) => void;
  tabs: TabDefinition[];
}

export const MobileTableTabs = ({
  value,
  onChange,
  tabs,
}: MobileTableTabsProps) => {
  return (
    <Tabs
      value={value}
      onChange={onChange}
      sx={{
        minHeight: 'unset',
        '& .MuiTabs-indicator': {
          backgroundColor: '#FFFFFF',
        },
        '& .Mui-selected': {
          color: '#FFFFFF',
        },
      }}
    >
      {tabs.map((tab) => {
        return (
          <Tab
            label={tab.label}
            sx={({ typography, spacing }) => ({
              flexGrow: 1,
              backgroundColor: '#505050',
              ...typography.body1,
              maxWidth: 'unset',
              minHeight: 'unset',
              padding: spacing(1),
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
            })}
            key={tab.label}
            value={tab.id}
          />
        );
      })}
    </Tabs>
  );
};

export default MobileTableTabs;
