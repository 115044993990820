import { ColumnDataType, MobileReportTableColumn } from '@newedge/common';
import { PerformanceNestedTableRowData, PerformanceTableRowData } from '../@types';

export enum ReportTypes {
  AccountGroups = 'acct-groups',
  ManagementStyle = 'mgmt-style',
  AssetClass = 'asset-class',
  SubAssetClass = 'sub-asset-class',
  Holdings = 'holdings',
}

export const mobileReportColumns: MobileReportTableColumn<
  PerformanceTableRowData,
  PerformanceNestedTableRowData
>[] = [
  {
    id: 'name',
    nestedId: 'name',
    label: 'Name',
    dataType: ColumnDataType.String,
    isIgnorableColumn: true,
  },
  {
    id: 'marketValue',
    nestedId: 'marketValue',
    label: 'Market Value',
    dataType: ColumnDataType.Currency,
    precision: 0,
  },
  {
    id: 'monthToDate',
    nestedId: 'monthToDate',
    label: 'MTD',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'quarterToDate',
    nestedId: 'quarterToDate',
    label: 'QTD',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'yearToDate',
    nestedId: 'yearToDate',
    label: 'YTD',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'oneYearReturns',
    nestedId: 'oneYearReturns',
    label: '1YR',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'threeYearReturns',
    nestedId: 'threeYearReturns',
    label: '3YR',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'fiveYearReturns',
    nestedId: 'fiveYearReturns',
    label: '5YR',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'tenYearReturns',
    nestedId: 'tenYearReturns',
    label: '10YR',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'sinceInception',
    nestedId: 'sinceInception',
    label: 'Since Inception',
    dataType: ColumnDataType.Percent,
    hasDashValuesWhenNull: true,
  },
  {
    id: 'name',
    nestedId: 'inceptionDate',
    label: 'Inception Date',
    dataType: ColumnDataType.Date,
    hasDashValuesWhenNull: true,
    isIgnorableColumn: true,
  },
];

export * from './AcctGroups';
export * from './AssetClass';
export * from './SubAssetClass';
export * from './Holdings';
export * from './ManagementStyle';
