import { AccordionDetails, Box, Typography, styled } from '@mui/material';
import { NumberFormatType, formatNumber, RefObjectMap } from '@newedge/common';
import { CashFlowDetailTableNestedRowData } from '../@types';
import { MutableRefObject, useCallback, useRef } from 'react';
import _ from 'lodash';

const CashFlowDetailMobileContainer = styled(Box)(({ theme }) => ({
  border: `1px solid #6C6C6C`,
  borderRadius: '10px',
  padding: theme.spacing(1),
  paddingBottom: 0,
  margin: theme.spacing(1),
  display: 'grid',
}));

const CashFlowDetailMobileData = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(5),
  overflowX: 'scroll',
  overflowY: 'hidden',
  paddingBottom: theme.spacing(1),
}));

interface CashFlowDetailMobileDataItemProps {
  value: number;
}

const CashFlowDetailMobileDataItem = ({
  value,
}: CashFlowDetailMobileDataItemProps) => {
  return (
    <Box
      sx={(theme) => ({
        minWidth: theme.spacing(10),
        py: theme.spacing(0.5),
      })}
    >
      <Typography variant='body1'>
        {formatNumber(value, NumberFormatType.Currency, 0)}
      </Typography>
    </Box>
  );
};

interface CashFlowDetailMobileRowProps {
  nestedItem: CashFlowDetailTableNestedRowData;
  subTitle: 'Dividends' | 'Interest Payments';
  scrollRefs: MutableRefObject<RefObjectMap>;
  topLevelIndex: number;
  nestedIndex: number;
}

export const CashFlowDetailMobileRow = ({
  nestedItem,
  subTitle,
  scrollRefs,
  topLevelIndex,
  nestedIndex,
}: CashFlowDetailMobileRowProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRefKey = topLevelIndex + '_' + subTitle + '_' + nestedIndex;
  scrollRefs.current[scrollRefKey] = scrollRef;

  const renderNestedColumnItems = () => {
    const items: JSX.Element[] = [];
    if (subTitle === 'Dividends') {
      nestedItem.dividends.forEach((dividendItem, index) => {
        items.push(
          <CashFlowDetailMobileDataItem
            value={dividendItem}
            key={`CashFlowDetailMobileData-${subTitle}-${index}`}
          />
        );
      });
      items.push(
        <CashFlowDetailMobileDataItem
          value={nestedItem.dividends.reduce(
            (total, value) => total + value,
            0
          )}
          key={`CashFlowDetailMobileData-${subTitle}-total`}
        />
      );
    } else {
      nestedItem.interest.forEach((interestItem, index) => {
        items.push(
          <CashFlowDetailMobileDataItem
            value={interestItem}
            key={`CashFlowDetailMobileData-${subTitle}-${index}`}
          />
        );
      });
      items.push(
        <CashFlowDetailMobileDataItem
          value={nestedItem.interest.reduce((total, value) => total + value, 0)}
          key={`CashFlowDetailMobileData-${subTitle}-total`}
        />
      );
    }
    return items;
  };

  const onScroll = useCallback(
    (e: any) => {
      const target = e.target as HTMLDivElement;
      Object.keys(scrollRefs.current)
        .filter((key) => key !== scrollRefKey)
        .forEach((key) => {
          const otherRef: HTMLDivElement | null =
            scrollRefs.current[key].current;
          if (otherRef !== null) {
            otherRef.scrollLeft = target.scrollLeft;
          }
        });
    },
    [scrollRefs]
  );

  return (
    <AccordionDetails
      sx={{
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <CashFlowDetailMobileContainer>
        <Typography
          sx={{
            fontSize: '1.4rem',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {subTitle}
        </Typography>
        <CashFlowDetailMobileData ref={scrollRef} onScroll={onScroll}>
          {renderNestedColumnItems()}
        </CashFlowDetailMobileData>
      </CashFlowDetailMobileContainer>
    </AccordionDetails>
  );
};

export default CashFlowDetailMobileRow;
