export enum LogLevel {
  Error = 4,
  Warning = 3,
  Info = 2,
  Debug = 1,
  Trace = 0,
}

export type LogConfig = {
  default: LogLevel;
  [key: string]: LogLevel;
};

export type LogContextType = {
  logLevel: LogConfig;
};
