import { useContext, useMemo } from 'react';
import LogContext from './LogContext';
import Logger from './logger';

export function useLogger(name?: string) {
  const context = useContext(LogContext);
  return useMemo(() => new Logger(context, name), [context, name]);
}

export default useLogger;
