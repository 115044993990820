import { MutableRefObject, useRef } from 'react';
import { RefObjectMap } from './MobileReportTable';
import { MobileReportTableColumn } from './@types';
import { Box } from '@mui/material';
import _ from 'lodash';

interface MobileReportTableColumnHeaderProps<T, S> {
  columns: MobileReportTableColumn<T, S>[];
  scrollRefs: MutableRefObject<RefObjectMap>;
}

export const MobileReportTableColumnHeader = <T, S>({
  columns,
  scrollRefs,
}: MobileReportTableColumnHeaderProps<T, S>) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollRefKey = 'MobileReportTableColumnHeader';
  scrollRefs.current[scrollRefKey] = scrollRef;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: '#373737',
        display: 'flex',
        gap: theme.spacing(5),
        overflowX: 'scroll',
        overflowY: 'hidden',
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(4),
      })}
      ref={scrollRef}
      onScroll={_.debounce(
        (e) => {
          const target = e.target as HTMLDivElement;
          Object.keys(scrollRefs.current)
            .filter((key) => key !== scrollRefKey)
            .forEach((key) => {
              const otherRef = scrollRefs.current[key].current;
              if (otherRef !== null) {
                otherRef.scrollLeft = target.scrollLeft;
              }
            });
        },
        16,
        { leading: true, trailing: true, maxWait: 50 }
      )}
    >
      {columns.map((column) => {
        return (
          <Box
            key={column.label}
            sx={(theme) => ({
              minWidth: theme.spacing(10),
              paddingTop: theme.spacing(1),
            })}
          >
            {column.label}
          </Box>
        );
      })}
    </Box>
  );
};

export default MobileReportTableColumnHeader;
