import { HoldingsDetailModel } from './@types';

export interface HoldingsDetailExcelData {
  Holding: string | null;
  'Account Name': string | null;
  'Asset Class': string;
  'Cost Basis': number | null;
  'Mkt Value': number | null;
  Price: number | null;
  Quantity: number | null;
  'Sub-Asset Class': string;
  Ticker: string;
  'Unrealized G/L': number | null;
  'Unrealized G/L %': number | null;
  Yield: number | null;
}

export const computeExcelData = (
  tableData: HoldingsDetailModel[]
): HoldingsDetailExcelData[] => {
  const excelData: HoldingsDetailExcelData[] = [];

  tableData.forEach((o) => {
    excelData.push({
      Holding: o.productName,
      Ticker: o.ticker,
      'Account Name': o.accountNickname,
      'Asset Class': o.assetClass,
      'Sub-Asset Class': o.subAssetClass,
      Price: o.price,
      Quantity: o.quantity,
      'Mkt Value': o.currentMarketValue,
      'Cost Basis': o.costBasis,
      'Unrealized G/L': o.unrealizedGain,
      'Unrealized G/L %': o.unrealizedGainPercent,
      Yield: o.yield,
    });
  });
  return excelData;
};
