import { useState } from 'react';
import { DateRangeCriteria, FilterItem } from '../@types';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { DateTime } from 'luxon';
import { css } from '@emotion/css';
import { Box, Button, useTheme } from '@mui/material';
import { getDatePickerCellRender } from './helpers';

const fontFamily = '"Source Sans Pro", "Arial", sans-serif';

const fromInputStylesDark = css({
  marginLeft: '.8rem',
  marginRight: 5,
  '.ant-picker-input > input': {
    color: 'white',
    width: 75,
  },
  '.ant-picker-input > input::placeholder': {
    color: 'white',
  },
});

const fromInputStylesLight = css({
  marginRight: 5,
  '.ant-picker-input > input': {
    color: 'black',
    width: 75,
  },
  '.ant-picker-input > input::placeholder': {
    color: 'black',
  },
});

const toInputStylesDark = css({
  marginLeft: '.8rem',
  marginTop: 5,
  marginRight: 10,
  '.ant-picker-input > input': {
    color: 'white',
    width: 75,
  },
  '.ant-picker-input > input::placeholder': {
    color: 'white',
  },
});

const toInputStylesLight = css({
  marginLeft: 5,
  '.ant-picker-input > input': {
    color: 'black',
    width: 75,
  },
  '.ant-picker-input > input::placeholder': {
    color: 'black',
  },
});

const customDatePickerDropdown = css({
  fontFamily: fontFamily,
  color: 'white',
  backgroundColor: '#242424',
  '.ant-picker-panel-container': {
    left: 65,
    color: 'white',
    backgroundColor: '#242424',
    '.ant-picker-panels': {
      color: 'white',
      backgroundColor: '#242424',
      '.ant-picker-panel:not(:first-child)': {
        color: 'white',
        backgroundColor: '#242424',
      },
    },
    '.ant-picker-header-super-prev-btn': {
      color: 'white',
    },
    '.ant-picker-header-prev-btn': {
      color: 'white',
    },
    '.ant-picker-header-super-next-btn': {
      color: 'white',
    },
    '.ant-picker-header-next-btn': {
      color: 'white',
    },
    '.ant-picker-header': {
      color: 'white',
    },
    '.ant-picker-content > thead > tr > th': {
      color: 'white',
    },
  },
});

export type MobileDateFilterPickerProps = {
  config: DateRangeCriteria;
  onValueChange: (filter: FilterItem) => void;
};

const MobileDateFilterPicker = ({
  config,
  onValueChange,
}: MobileDateFilterPickerProps) => {
  const theme = useTheme();
  const [fromDatePickerOpen, setFromDatePickerOpen] = useState(false);
  const [toDatePickerOpen, setToDatePickerOpen] = useState(false);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleApplyDateFilter = () => {
    let displayValue = config.header;

    if (startDate && endDate) {
      const luxonStartDate = DateTime.fromISO(startDate.toISOString(), {
        zone: 'utc',
      }).set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      const luxonEndDate = DateTime.fromISO(endDate.toISOString());

      displayValue =
        displayValue +
        ': ' +
        luxonStartDate.toLocaleString(DateTime.DATE_SHORT) +
        ' - ' +
        luxonEndDate.toLocaleString(DateTime.DATE_SHORT);

      onValueChange({
        predicateIdentifier: config.predicateIdentifier,
        displayValue,
        filterType: 'date',
        date: { startDate: luxonStartDate, endDate: luxonEndDate },
        unique: true,
      });
    }
  };

  return (
    <Box>
      <DatePicker
        onOpenChange={(open) => setFromDatePickerOpen(open)}
        placeholder={'From'}
        style={
          theme.palette.type === 'dark'
            ? {
                color: 'white',
                backgroundColor: '#171717',
                borderColor: 'white',
              }
            : {}
        }
        className={
          theme.palette.type === 'dark'
            ? fromInputStylesDark
            : fromInputStylesLight
        }
        cellRender={getDatePickerCellRender(theme)}
        open={fromDatePickerOpen}
        placement={'bottomRight'}
        dropdownClassName={
          theme.palette.type === 'dark' ? customDatePickerDropdown : ''
        }
        popupStyle={{ fontFamily: fontFamily, left: 65 }}
        onChange={(date) => setStartDate(date)}
        value={startDate !== null ? dayjs(startDate) : null}
        disabledDate={(currentDate: Dayjs): boolean =>
          currentDate.isAfter(dayjs().subtract(1, 'day'))
        }
      />
      <DatePicker
        disabledDate={(current) =>
          (startDate ? current < startDate : false) ||
          current.isAfter(dayjs().subtract(1, 'day'))
        }
        disabled={startDate === null}
        onOpenChange={(open) => setToDatePickerOpen(open)}
        placeholder={'To'}
        style={
          theme.palette.type === 'dark'
            ? {
                color: 'white',
                backgroundColor: '#171717',
                borderColor: 'white',
              }
            : {}
        }
        className={
          theme.palette.type === 'dark' ? toInputStylesDark : toInputStylesLight
        }
        cellRender={getDatePickerCellRender(theme)}
        open={toDatePickerOpen}
        placement={'bottomLeft'}
        dropdownClassName={
          theme.palette.type === 'dark' ? customDatePickerDropdown : ''
        }
        popupStyle={{ fontFamily: fontFamily }}
        onChange={(date) => setEndDate(date)}
        value={endDate !== null ? dayjs(endDate) : null}
      />
      <Button
        sx={{ flexDirection: 'column', marginLeft: 0 }}
        onClick={handleApplyDateFilter}
      >
        Apply Date Range
      </Button>
    </Box>
  );
};

export { MobileDateFilterPicker };
