import React from 'react';
import {
  ListItem as MuiListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';

import { ListItemAction, ListItemProps } from './@types';

(MuiListItem as any).displayName = 'MuiListItem';

export const ListItem = React.forwardRef<HTMLLIElement, ListItemProps>(
  function ListItem(
    {
      isNarrow = true,
      isRounded,
      children,
      primaryAction,
      secondaryAction,
      button,
      text,
      className,
      ...rest
    }: ListItemProps,
    forwardedRef
  ) {
    const renderAction = ({
      children: actionChildren,
      iconButton,
    }: ListItemAction) => actionChildren ?? iconButton;
    const primaryText =
      typeof text?.primary === 'function' ? text?.primary() : text?.primary;
    const secondaryText =
      text?.secondary instanceof Function ? text?.secondary() : text?.secondary;
    return (
      <MuiListItem
        button={button as any} // reason for any cast: https://github.com/mui-org/material-ui/issues/14971
        ref={forwardedRef}
        {...rest}
        sx={{ margin: '0 !important' }}
      >
        {children ?? (
          <>
            {primaryAction && (
              <ListItemIcon {...primaryAction.props}>
                {renderAction(primaryAction)}
              </ListItemIcon>
            )}
            <div>
              <ListItemText
                primary={primaryText}
                secondary={secondaryText}
                {...text?.props}
              />
              {secondaryAction && (
                <ListItemSecondaryAction {...secondaryAction.props}>
                  {renderAction(secondaryAction)}
                </ListItemSecondaryAction>
              )}
            </div>
          </>
        )}
      </MuiListItem>
    );
  }
);

export default ListItem;
