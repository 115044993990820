import _ from 'lodash';
import { AssetAllocation } from './@types';
import { AssetAllocationNestedRow, AssetAllocationRow } from './table';

export function getDataByManagementStyle(
  assetAllocationData: AssetAllocation[]
): AssetAllocationRow[] {
  type AssetAllocationKeys = keyof AssetAllocation;
  const managementStyleKey: AssetAllocationKeys = 'managementStyle';

  const accounts: AssetAllocation[] = _.uniqWith<AssetAllocation>(
    assetAllocationData,
    (value: AssetAllocation, other: AssetAllocation) => {
      return (
        value.accountId === other.accountId &&
        value.accountBalance === other.accountBalance
      );
    }
  );

  const managementStyleGroups: _.Dictionary<AssetAllocation[]> = _.groupBy(
    accounts,
    managementStyleKey
  );

  const totalAccountBalances = _.sumBy(accounts, (account: AssetAllocation) => {
    return account.accountBalance;
  });

  let assetAllocationProductDetailByManagement: AssetAllocationRow[] = [];

  Object.keys(managementStyleGroups).forEach((managementStyleAsKey) => {
    const initialValue = 0;
    const marketValue = managementStyleGroups[managementStyleAsKey].reduce(
      (total, assetAllocationRecord) =>
        assetAllocationRecord.accountBalance + total,
      initialValue
    );
    const result: AssetAllocationRow = {
      name: managementStyleAsKey,
      marketValue: marketValue,
      percent: (marketValue / totalAccountBalances) * 100,
      nestedData: managementStyleGroups[managementStyleAsKey].map(
        (x: AssetAllocation) => {
          const accountPercent: number =
            (x.accountBalance / totalAccountBalances) * 100;
          return {
            name:
              (x.userProfileData && x.userProfileData.accountNickname) ||
              x.accountDefaultName,
            marketValue: x.accountBalance,
            percent: accountPercent,
          };
        }
      ),
    };
    assetAllocationProductDetailByManagement.push(result);
  });

  return assetAllocationProductDetailByManagement;
}

export function getDataByAssetClass(
  assetAllocationData: AssetAllocation[]
): Array<AssetAllocationRow> {
  const assetClassKey = 'assetClass';
  const subAssetClassKey = 'subAssetClass';

  let allRecordsTotal: number = _.sumBy(
    assetAllocationData,
    function (assetData) {
      return assetData.subAssetClassBalance;
    }
  );

  const groupByAssetClass: _.Dictionary<AssetAllocation[]> = _.groupBy(
    assetAllocationData,
    assetClassKey
  );

  return _.map(groupByAssetClass, (assetClassGrouping) => {
    const assetClassTotal = _.sumBy(
      assetClassGrouping,
      (x) => x.subAssetClassBalance
    );
    const groupBySubAssetClass = _.groupBy(
      assetClassGrouping,
      subAssetClassKey
    );

    return {
      name: assetClassGrouping[0].assetClass,
      marketValue: assetClassTotal,
      percent: (assetClassTotal / allRecordsTotal) * 100,
      nestedData: _.map(groupBySubAssetClass, (subAssetClassGrouping) => {
        const subAssetTotal = _.sumBy(
          subAssetClassGrouping,
          (x) => x.subAssetClassBalance
        );

        return {
          name: subAssetClassGrouping[0].subAssetClass,
          marketValue: subAssetTotal,
          percent: (subAssetTotal / allRecordsTotal) * 100,
        } as AssetAllocationNestedRow;
      }) as AssetAllocationNestedRow[],
    } as AssetAllocationRow;
  });
}

export interface AssetAllocationByAssetClassExcelData {
  'Asset Class Name': string;
  'Sub Asset Class Name': string;
  'Market Value': number;
  '% Of Accounts': number;
}

export const computeExcelDataByAssetClass = (
  tableData: AssetAllocationRow[]
): AssetAllocationByAssetClassExcelData[] => {
  const excelData: AssetAllocationByAssetClassExcelData[] = [];
  tableData.forEach((o) => {
    o.nestedData.forEach((o2) => {
      excelData.push({
        'Asset Class Name': o.name,
        'Sub Asset Class Name': o2.name,
        'Market Value': o2.marketValue,
        '% Of Accounts': o2.percent,
      });
    });
  });
  return excelData;
};

export interface AssetAllocationByManagementStyleExcelData {
  'Management Style': string;
  'Account Name': string;
  'Market Value': number;
  '% Of Accounts': number;
}

export const computeExcelDataByManagementStyle = (
  tableData: AssetAllocationRow[]
): AssetAllocationByManagementStyleExcelData[] => {
  const excelData: AssetAllocationByManagementStyleExcelData[] = [];
  tableData.forEach((o) => {
    o.nestedData.forEach((o2) => {
      excelData.push({
        'Management Style': o.name,
        'Account Name': o2.name,
        'Market Value': o2.marketValue,
        '% Of Accounts': o2.percent,
      });
    });
  });
  return excelData;
};
