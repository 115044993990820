import { Box, Paper, Skeleton } from '@mui/material';
import {
  MutableRefObject,
  ReactNode,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { MobileReportTableColumn } from './@types';
import MobileReportTableHeader from './MobileReportTableHeader';
import MobileReportTableRow from './MobileReportTableRow';
import MobileReportTableColumnHeader from './MobileReportTableColumnHeader';
import InfiniteScroll from 'react-infinite-scroll-component';

const NO_DATA_MESSAGE = 'No data meets the selection criteria.';

interface MobileReportTableProps<T, S> {
  columns: MobileReportTableColumn<T, S>[];
  data: T[];
  title?: string;
  headerId: keyof T;
  nestedHeaderId?: keyof S;
  customNestedHeaderRenderer?: (row: T, nestedRow: S) => ReactNode;
  customRowHeaderRenderer?: (row: T) => ReactNode;
  nestedAccessor?: (rowData: T) => S[];
  customTileListRenderer?: (
    nestedData: S[],
    topLevelIndex: number,
    scrollRefs: MutableRefObject<RefObjectMap>
  ) => ReactNode;
  zebra?: boolean;
  hasParentHeaders?: boolean;
  hasCustomNestedTable?: boolean;
  positiveNumberDecorator?: boolean;
  customNestedTableHeaderRenderer?: (
    columns: MobileReportTableColumn<T, S>[],
    nestedRow: S
  ) => ReactNode;
  expandRowIndex?: number;
  includeColumnLabelPerRow?: boolean;
  showNoDataMessage?: boolean;
}

export interface RefObjectMap {
  [key: string]: RefObject<HTMLDivElement>;
}

export const MobileReportTable = <T, S>({
  columns,
  data,
  title,
  headerId,
  nestedHeaderId,
  customNestedHeaderRenderer,
  customRowHeaderRenderer,
  nestedAccessor,
  customTileListRenderer,
  zebra = false,
  hasParentHeaders = true,
  hasCustomNestedTable = false,
  positiveNumberDecorator,
  customNestedTableHeaderRenderer,
  expandRowIndex,
  includeColumnLabelPerRow = true,
  showNoDataMessage = false,
}: MobileReportTableProps<T, S>) => {
  const scrollRefs = useRef<RefObjectMap>({});
  const [renderedData, setRenderedData] = useState<T[]>(data.slice(0, 50));

  useEffect(() => {
    setRenderedData(data.slice(0, 50));
  }, [data]);

  const groupSize = 50;
  const loadMoreItems = () => {
    setRenderedData(
      renderedData.concat(
        ...data.slice(renderedData.length, renderedData.length + groupSize)
      )
    );
  };

  return (
    <Box sx={(theme) => ({ marginBottom: theme.spacing(3) })}>
      {title && data.length > 0 ? (
        <MobileReportTableHeader title={title} />
      ) : null}
      {!includeColumnLabelPerRow ? (
        <MobileReportTableColumnHeader
          columns={columns}
          scrollRefs={scrollRefs}
        />
      ) : null}

      <InfiniteScroll
        next={loadMoreItems}
        hasMore={renderedData.length < data.length}
        loader={<></>}
        dataLength={renderedData.length}
      >
        <>
          {renderedData.map((rowData, index) => {
            return (
              <MobileReportTableRow
                key={JSON.stringify(rowData)}
                row={rowData}
                nestedData={
                  nestedAccessor ? nestedAccessor(rowData) : undefined
                }
                columns={columns}
                headerId={headerId}
                nestedHeaderId={nestedHeaderId}
                customNestedHeaderRenderer={customNestedHeaderRenderer}
                customTileListRenderer={customTileListRenderer}
                customRowHeaderRenderer={customRowHeaderRenderer}
                hasCustomNestedTable={hasCustomNestedTable}
                customNestedTableHeaderRenderer={
                  customNestedTableHeaderRenderer
                }
                zebra={zebra}
                hasParentHeaders={hasParentHeaders}
                positiveNumberDecorator={positiveNumberDecorator}
                expand={index === expandRowIndex}
                scrollRefs={scrollRefs}
                index={index}
                includeColumnLabelPerRow={includeColumnLabelPerRow}
              />
            );
          })}
          {renderedData.length < data.length ? (
            <Skeleton height={250} width='100%' />
          ) : null}
        </>
      </InfiniteScroll>
      {showNoDataMessage && data.length === 0 ? (
        <Paper sx={{ display: 'flex', justifyContent: 'center', py: 1 }}>
          {NO_DATA_MESSAGE}
        </Paper>
      ) : null}
    </Box>
  );
};

export default MobileReportTable;
