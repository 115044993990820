import {
  Box,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import { Order } from './@types';

interface SortByOptions {
  direction: Order;
  displayValue: string;
  onClick: () => void;
}

interface SortBySelectProps {
  options: SortByOptions[];
  close: () => void;
}

export const SortBySelect = React.forwardRef(
  ({ options, close }: SortBySelectProps, ref) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '300px',
        }}
      >
        <Box
          sx={{
            width: 0,
            height: 0,
            borderLeft: '12px solid transparent',
            borderRight: '12px solid transparent',
            borderBottom: '12px solid #FFFFFF',
          }}
        />
        <Paper
          sx={(theme) => ({
            padding: theme.spacing(1),
            backgroundColor: theme.palette.greyPercent[16],
            border: '1px solid #FFFFFF',
          })}
          ref={ref as any}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              component="span"
              sx={{
                textTransform: 'uppercase',
                fontSize: '1.4rem',
                fontWeight: 'bold',
                paddingLeft: '1rem',
              }}
            >
              Sort By
            </Box>
            <IconButton onClick={() => close()} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
          <List>
            {options.map((option) => {
              return (
                <ListItemButton
                  key={option.displayValue}
                  onClick={option.onClick}
                >
                  <ListItemIcon>
                    {option.direction === 'asc' ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </ListItemIcon>
                  <ListItemText primary={option.displayValue} />
                </ListItemButton>
              );
            })}
          </List>
        </Paper>
      </Box>
    );
  }
);

export default SortBySelect;
