import { Button, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Slider } from '@mui/material';
import { useState } from 'react';

const PREFIX = 'NumberRangeFilter';

const classes = {
  textFields: `${PREFIX}-textFields`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => {
  return {
    [`& .${classes.textFields}`]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
});

export interface NumberRangeFilterProps {
  min: number;
  max: number;
  setValue: (newValue: number[]) => void;
}

export const NumberRangeFilter = ({
  min,
  max,
  setValue,
}: NumberRangeFilterProps) => {
  const [value, setSliderValue] = useState<number[]>([min, max]);

  const handleChange = (_event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number[]);
  };

  const applyChanges = () => {
    setValue(value);
  };

  return (
    <Root>
      <div>
        <Slider
          value={value}
          onChange={handleChange}
          min={min}
          max={max}
          valueLabelDisplay="auto"
          color="iron"
        />
      </div>
      <div className={classes.textFields}>
        <TextField
          variant="outlined"
          label="Minimum Value"
          value={value[0]}
          InputProps={{ readOnly: true }}
          style={{ margin: '1rem', maxWidth: '150px' }}
          color="secondary"
          size="small"
          disabled
        />
        <TextField
          variant="outlined"
          label="Maximum Value"
          value={value[1]}
          InputProps={{ readOnly: true }}
          style={{ margin: '1rem', maxWidth: '150px' }}
          color="secondary"
          size="small"
          disabled
        />
        <Button onClick={() => applyChanges()}>Apply</Button>
      </div>
    </Root>
  );
};
