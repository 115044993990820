export const cashFlowDisclosuresTextLines = [
  'This report is intended solely for your personal use and has been prepared for informational purposes only. Please review the report carefully and contact your NewEdge Private Wealth Advisor with any questions.',
  'Information included on this report may include assets and liabilities held or controlled by different beneficial owners, trustees, authorized parties and/or beneficiaries each with different investment objectives and risk profiles, investment rules and restrictions, and subject to different types of fees, mark-ups, commissions and other charges. In some cases, the assets and liabilities of different legal entities have been consolidated for information purposes. Please note that account names and groupings shown in this report are highly customizable and have no legal effect on account ownership.',
  'In some cases, NewEdge Wealth, LLC (“NewEdge”, “we”, “us”, or “our”) may show the holdings or performance of accounts or investments where NewEdge or a third-party adviser acts as a fiduciary to you or a related party under the Investment Advisers Act of 1940, as amended, or the Employee Retirement Income Security Act of 1974, as amended. In other cases, we may also show the performance of brokerage accounts for which certain of our supervised persons act as registered representatives and/or principals of our affiliate NewEdge Securities, Inc., introducing broker dealer registered with FINRA (e.g., accounts classified as “Brokerage”, “Cash Account” or “Loan” in the Account Classification view) as well as accounts/assets for which we are simply acting in an administrative capacity by consolidating information at your request (“Reporting Only”).',
  'Performance information contained in this report is not intended to reflect NewEdge Wealth’s performance as an investment adviser, as many of the assets are not and were not managed by NewEdge during the reported periods. Past performance does not guarantee future results.',
  'Client valued assets or liabilities may be shown upon request. We do not represent that such information is accurate, complete or timely. Please note that information related to such assets/liabilities is solely your responsibility and has not been verified by any other person, including our firm or any other third-party.',
  'Account name(s) displayed in this report and labels used for groupings of accounts can be customizable "nicknames" chosen by you to assist you with your recordkeeping or may have been included by your financial advisor for reference purposes only. The names used have no legal effect, are not intended to reflect any strategy, product, recommendation, investment  objective or risk profile associated with your accounts or any group of accounts, and are not a promise or guarantee that wealth, or any financial results, can or will be achieved. All investments involve the risk of loss, including the risk of loss of the entire investment.',
  'For more information about account or group names, or to make changes, contact your NewEdge Private Wealth Advisor.',
  'GENERAL DISCLAIMERS',
  'You may hold investment in one or more brokerage accounts.  Your account custodian(s) are listed in this report.  This consolidated report should not be used as a substitute for your official account statements, K1 or 1099. Your official account statements and trade confirmations contain the most accurate information on your account holdings. Values shown on your official account statement may differ from the values shown on this report due to, among other things, different reporting methods, delays, market conditions and technology interruptions. If there are discrepancies between your official statements and this report, you should rely on your official account statements. Information is subject to change at any time and we are not obligated to notify you of these changes.',
  'Although we source information and data that we believe to be reliable, we cannot guarantee its accuracy or timeliness. Information may be condensed and is not intended as an offer or solicitation with respect to the purchase or sale of any security.',
  'Any investment, tax, marketing, or legal information contained herein is general and educational in nature and should not be construed as advice. Please consult your tax advisor for matters involving taxation and tax planning and your attorney for matters involving trusts, estate planning, charitable giving, philanthropic planning, and other legal matters.',
  'This report does not represent an offer of or a solicitation for advisory services in any state/jurisdiction of the U.S. or any country where NewEdge is not registered, notice filed, or exempt.',
  'Please remember that different types of investments involve varying degrees of risk, and there can be no assurance that the future performance of any specific investment or investment strategy (including those undertaken or recommended by NewEdge) will be profitable or equal any historical performance level(s). Clients should carefully consider their own investment objectives and never rely on any single chart, graph or marketing piece in making investment decisions.',
  'Tax Status: "Taxable" includes all securities held in a taxable account that are subject to federal and/or state or local taxation. "Tax-exempt" includes all securities held in a taxable account that are exempt from federal, state and local taxation. "Tax-deferred" includes all securities held in a tax-deferred account, regardless of the status of the security.',
  'Cash Flow: This Cash Flow analysis is based on the historical dividend, coupon and interest payments you have received as of the Record Date in connection with the securities listed and assumes that you will continue to hold the securities for the periods for which cash flows are projected. The attached may or may not include principal paybacks for the securities listed. These potential cash flows are subject to change due to a variety of reasons, including but not limited to, contractual provisions, changes in corporate policies, changes in the value of the underlying securities and interest rate fluctuations. The effect of a call on any security(s) and the consequential impact on its potential cash flow(s) is not reflected in this report. Payments that occur in the same month in which the report is generated -- but prior to the report run ("As of") date -- are not reflected in this report. In determining the potential cash flows, NewEdge relies on information obtained from third party services it believes to be reliable. NewEdge does not independently verify or guarantee the accuracy or validity of any information provided by third parties. Although NewEdge generally updates this information as it is received, the Firm does not provide any assurances that the information listed is accurate as of the Record Date. Cash flows for mortgage-backed, asset-backed, factored, and other pass-through securities are based on the assumptions that the current face amount, principal pay-down, interest payment and payment frequency remain constant. Calculations may include principal payments, are intended to be an estimate of future projected interest cash flows and do not in any way guarantee accuracy.',
];

export const defaultReportDisclosuresTextLines = [
  'About this Consolidated Report',
  'THE INFORMATION CONTAINED ON THIS REPORT IS DESIGNED SOLELY FOR YOUR INDIVIDUAL USE AND IS FOR INFORMATIONAL PURPOSES ONLY.',
  'Information included on this report may include assets and liabilities held or controlled by different beneficial owners, trustees, authorized parties and/or beneficiaries each with different investment objectives and risk profiles, subject to different investment rules and restrictions, and subject to different types of fees and mark-ups, commissions and other charges.',
  'In some cases, the assets and liabilities of different legal entities have been consolidated for information purposes.  Please note that account names and groupings shown in this report are highly customizable and have no legal effect on account ownership.',
  'In some cases, NewEdge Wealth LLC (“NewEdge”, “we”, “us”, or “our”) may show the performance of accounts or investments where NewEdge or a third-party adviser acts as a fiduciary to you or a related party under the Investment Advisers Act of 1940, as amended, or the Employee Retirement Income Security Act of 1974, as amended (e.g., accounts classified as “Advisory” in the Account Classification view).  In other cases, we may also show the performance of brokerage accounts for which certain of our supervised persons act as registered representatives and/or principals of an introducing broker dealer registered with FINRA (e.g., accounts classified as “Brokerage” in the Account Classification view) as well as accounts/assets for which we are simply acting in an administrative capacity by consolidating information at your request (“Client Reported”).',
  'Performance information contained in this report is not intended to reflect NewEdge’s performance as an investment adviser, as many of the assets are not and were not managed by NewEdge during the reported periods.',
  'Client Reported assets or liabilities may be shown upon request. We do not represent that such information is accurate, complete or timely.  Please note that information related to such assets/liabilities is solely your responsibility and has not been verified by any other person, including our firm or any other  third-party.',
  'This report is intended solely for your personal use and has been prepared for informational purposes only. Please review the report carefully and contact your NewEdge Private Wealth Advisor with any questions.',
  'Account name(s) displayed in this report and labels used for groupings of accounts can be customizable "nicknames" chosen by you to assist you with your recordkeeping or may have been included by your financial advisor for reference purposes only. The names used have no legal effect, are not intended to reflect any strategy, product, recommendation, investment  objective or risk profile associated with your accounts or any group of accounts, and are not a promise or guarantee that wealth, or any financial results, can or will be achieved. All investments involve the risk of loss, including the risk of loss of the entire investment.',
  'For more information about account or group names, or to make changes, contact your NewEdge Private Wealth Advisor.',
  'Although we source information and data that we believe to be reliable, we cannot guarantee its accuracy or timeliness. Information may be condensed and is not intended as an offer or solicitation with respect to the purchase or sale of any security.',
  'Any investment, tax information contained herein is general and educational in nature and should not be construed as advice. Please consult your tax advisor for matters involving taxation.',
  'Please remember that different types of investments involve varying degrees of risk, and there can be no assurance that the future performance of any specific investment or investment strategy (including those undertaken or recommended by NewEdge) will be profitable or equal any historical performance level(s).',
  'Clients should carefully consider their own investment objectives and never rely on any single chart, graph or marketing piece in making investment decisions.',
  'Tax Status: "Taxable" includes all securities held in a taxable account that are subject to federal and/or state or local taxation. "Tax-exempt" includes all securities held in a taxable account that are exempt from federal, state and local taxation. "Tax-deferred" includes all securities held in a tax-deferred account, regardless of the status of the security.',
  'This report does not represent an offer of or a solicitation for advisory services in any state/jurisdiction of the U.S. or any country where NewEdge is not registered, notice filed, or exempt.',
  '',
  'Performance Disclosures & Definitions',
  'Past performance does not guarantee future results.',
  'Account Classification identifies the assets and accounts that are included in this consolidated report.  This view will show all open accounts being reporting into our system. Accounts that are closed will not appear in the list of current accounts but may be included in historical performance calculations. See below for more information about the effect of closed accounts on performance reporting.',
  'Net of Fee and Gross of Fee Performance: Unless otherwise notes, all returns for accounts where NewEdge acts as an investment adviser (i.e., accounts classified as Advisory) are shown “net of fees” in so far as they reflect the deduction of NewEdge’s fees from the account.  Advisory accounts that are billed separately via invoice or a separate account billing arrangement are shown “gross of fees”.  These accounts are identified with an ** in the account name. Gross returns do not reflect the deduction of fees, commissions or other charges, which reduce returns (e.g., an investment management fee of 0.50% will reduce a 10% return to a 9.5% return). The compounding effect of such fees over time can be substantial and should be taken into consideration when viewing these materials. If you have any questions concerning the fee calculation and deduction method, please contact us.',
  'Inception Date: The Inception Date for an account is the trade date of the first transaction in the account within our portfolio management system.  The Inception Date shown in this report and the date on which the account was opened at your custodian may not be the same. The Inception Date for a security means the trade date of the transaction or the first date the security appears in the account (e.g., in the case of ACATs or transfers).',
  'Cost Basis: Gain or loss will only be calculated for tax lots for which a cost basis has been provided. Cost basis data is derived from information supplied by you or other sources such as your account custodian. Totals shown do not incorporate gains (or losses) for securities that do not have a cost basis. The accuracy of the purchase date, cost basis and other information is not independently verified by us. Different custodians use different calculations to determine cost basis. Periodic updates to an individual custodian’s determination of cost basis may not be reflected in this report.',
  'Cost Basis Adjustments:  Realized gains and losses may or may not take into consideration adjustments to cost basis for the return of principal, reinvestment of dividends or accretion/amortization.  Gain/Loss information contained in this report should not be used or relied upon for tax reporting purposes.',
  'Closed Account Performance: Accounts that have been closed may be included in the consolidated performance. When closed accounts are included in the consolidated performance, we will only include information for the time periods during which the account was active. In these instances, returns for less than one (1) year will not be annualized.',
  'Time-Weighted Return (TWR): A measure of the compound rate of growth in a portfolio over time. This method eliminates the distorting effects created by inflows of new money or outflows/distributions.',
  'Total Return: The amount gained or lost on an investment, including capital appreciation and income, net of fees and expenses, over a particular period.',
  'IRR (Internal Rate of Return): A performance metric used to measure the rate of return that makes the net present value of an investment zero, or the rate at which the present value of the cost of an investment is equivalent to the present value of the benefit.',
  'Adjusted IRR:  The IRR adjusted to take into consideration contributions and distributor since the last reported IRR provided by the fund administrator.',
  'Unpriced Securities: Performance figures exclude unpriced securities (if any).',
  '',
  'Definitions',
  'Accrued Income:  The undistributed income an asset has generated since its last payment.',
  'Adjusted Value:  For Private Equity, the last net asset value provided by the fund administrator plus or minus any contribution/distributions.',
  'Aggregate Balance Sheet: The combination of Balance Sheet and Off-Balance Sheet assets and liabilities.',
  'Asset Table: A term used to describe a combination of assets, liabilities and accounts in a particular table shown in the report.',
  'Balance Sheet: Some or all of your assets, liabilities and equity as of a given point in time.',
  'Bank Issued Certificate of Deposit: A time deposit (generally with a specific fixed term and fixed interest rate) that is issued by a bank and insured by the Federal Deposit Insurance Corporation (“FDIC”). Funds are FDIC-insured up to $250,000 per depositor per insured institution, based on account ownership type.',
  'Cash Equivalent: Cash equivalents are generally used for short-term investing, have high credit quality and are highly liquid. For purposes of this report, cash equivalents include: uninvested cash balances in brokerage accounts, money market sweep funds, money market purchase funds, bank deposit programs, bank issued certificates of deposit, and money market deposit accounts (MMDA), and demand deposits (DDAs).',
  'Client Reported Assets: Accounts or assets that are included in this report at your direction and for which you have provided valuations.',
  'Commitment: The total amount that an investor is obligated to contribute to his or her investment.',
  'Distributions: The total amount of cash and stock that has been paid out.',
  'Equity: A stock or any other security representing an ownership interest. In certain views, we may also show mutual funds or ETFs that exclusively hold or track a basket of equity securities.',
  'Exposure: The percentage of the total value of all Private Equity holdings that any one holding represents.',
  'Fixed Income: A type of investment with a return coming from payment of a specific amount of money on a stated, periodic basis and the return of principal at maturity. A variety of institutions issue fixed income securities, including the U.S. government, state and local governments, and publicly held companies. In certain report views, we may also show mutual funds and ETFs that exclusively hold or track a basket of Fixed Income securities.',
  'Last Admin Value Date:  The date at which the position was last valued by the fund administrator.',
  'Long Term: The gain or loss on an investment that has not yet been traded in for cash for holding periods greater than 365 days.',
  'Marketable Securities: Marketable securities are securities or debts that are to be sold or redeemed within a year. These are financial instruments that can be easily converted to cash such as government bonds, common stock or certificates of deposit.',
  "Modified Duration: Measures the sensitivity of a bond's price to changes in interest rates. More specifically, it is the approximate percentage change in the price of a bond given a 1% change in the bond's yield to maturity.",
  'MOIC (Multiple on Investment Account):  The amount of money gained from a position as a fraction of the money paid into the position.',
  'Money Market Funds: A money market fund is a type of mutual fund. You could lose money by investing in a money market fund.  All fixed rate money market funds seek to preserve the value of your investment at $1.00 per share, but cannot guarantee they will do so.  An investment in a money market fund is not insured or guaranteed by the FDIC or any other government agency.',
  'Monthly Projected Cash Flows (including principal): The income that is expected to be received over a given period in the future based on the percentage yield.',
  'Off-Balance Sheet: Assets and liabilities of related parties or entities that have given you permission to view their financial information.',
  'Options: A contract that gives one party the right to either sell (in the case of a put option) or purchase (in the case of a call option) a security at an agreed-upon strike price before an agreed-upon expiration date.',
  'Private Equity: Private equity represents an investment in a company whose shares are not publicly traded.',
  'Projected Cashflow: The income that is expected to be received over a given period in the future.',
  'Retirement Accounts: Accounts that provide tax advantages for retirement savings (eg, Roth IRA, IRA, 401K, SEP Plans, ESOPs, defined benefit plans, profit sharing plans, etc.)',
  'Realized Gain/Loss: The gains or loss made from selling shares over a given period. Holding periods are 365-day inclusive.  For example, gains on an asset purchased on 06/06/2017 will be considered short-term through 06/06/2018 and long-term after 06/07/2018.',
  'Short Term: The gain or loss on an investment that has not yet been traded in for cash for holding periods greater than 365 days.',
  'Taxable Accounts: As shown in the Tax Summary Overview refers to accounts with assets that are subject to income tax at the time of earning, excluding private equity, hedge funds and direct investments (i.e., an asset or account that is not tax deferred or tax exempt),',
  'Total % of Portfolio: Totalpercentages below 100% do not include off-balance sheet assets.',
  'Unfunded Commitment: The unfunded commitment is the amount remaining to be paid in to a position before all the commitments to the position are fulfilled. We calculate unfunded commitment by taking the original capital commitment to an investment and subtracting from it the total contributions to that investment made to date.',
  'Unknown: An undefined asset class.',
  'Unrealized Gain/Loss: The gain or loss on an investment that has not yet been traded in for cash.',
  'Value: The market value as last reported to us by your custodian, or, in the case of Private Equity the Adjusted Market Value.',
  'Valuation Date: The date of the last reported market value of an investment, as identified in the Portfolio Allocation Overview.',
  'Year to Date Interest: An inflow of cash into a cash account as a result of interest earned on an asset from January 1 to the most recent update.',
  'Yield to Maturity: The IRR on a fixed income asset purchased today at the market price, assuming the asset is held to the maturity date and all coupon payments are made on time.',
  '',
  'Index Information',
  'Index returns shown are total returns which includes interest, capital gains, dividends, and distributions realized over a given period of time. You cannot invest directly in an index.  An individual who purchases an investment product which attempts to mimic the performance of a benchmark or index will incur expenses such as management fees and transaction costs which reduce returns.',
  'Bloomberg Barclays US Aggregate: is a broad-based flagship benchmark that measures the investment grade, US dollar-denominated, fixed-rate taxable bond market. The index includes Treasuries, government-related and corporate securities, MBS (agency fixed-rate and hybrid ARM pass-throughs), ABS and CMBS (agency and non-agency).',
  'S&P 500 (Total Return): is a total return index that reflects both changes in the prices of stocks in the S&P 500 Index as well as the reinvestment of the dividend income from its underlying stocks.',
  'Russell 2000 (Total Return):  is an index that measures the performance of the small-cap segment of the U.S. equity universe. The Russell 2000® Index is a subset of the Russell 3000® Index representing approximately 10% of the total market capitalization of that index. It includes approximately 2000 of the smallest securities based on a combination of their market cap and current Index membership.',
  'Russell 1000 (Total Return):  is a stock market index that tracks the highest-ranking 1,000 stocks in the Russell 3000 Index, which is made up of 3,000 of the largest U.S. stocks.',
  'MSCI EAFE (USD) (Total Return): is designed to represent the performance of large and mid-cap securities across 21 developed markets in Europe, Australasia and the Far East excluding the U.S. and Canada.',
  'MSCI ACWI Equal Weighted (Total Return): represents an alternative weighting scheme to its market cap weighted parent index, MSCI ACWI. The index includes the same constituents as its parent (large and mid-cap securities from 23 Developed Markets (DM) and 27 Emerging Markets (EM) countries).',
  'Bloomberg Barclays Municipal Bond Index:  is an unmanaged index considered to be representative of the tax-exempt municipal bond market.',
  'Bloomberg Barclays 1-3 Month T-Bill:  includes all publicly issued zero-coupon US Treasury Bills that have a remaining maturity of less than 3 months and more than 1 month, are rated investment grade, and have $250 million or more of outstanding face value.',
  'Bloomberg Barclays US HY: represents the universe of fixed rate, non-investment grade debt. The index includes corporate sectors. The corporate sectors are Industrial, Utility, and Finance, encompassing both US and non-US Corporations.',
  'Bloomberg Barclays US Corporate Investment Grade: is an unmanaged index considered representative of publicly issued, fixed-rate, nonconvertible, investment-grade debt securities.',
  '',
  '© NewEdge Wealth Management LLC 2023',
  'New Edge Wealth LLC is an investment adviser registered with the U.S. Securities and Exchange Commission. Securities offered and sold through NewEdge Securities, Inc., member FINRA/SIPC.',
];
