import ToutTile from './ToutTile';
import { TileProps } from './@types';

interface ToutsWidgetProps {
  data: TileProps[];
}

export function ToutsWidget({ data }: ToutsWidgetProps) {
  return (
    <>
      {data.map((tout, i) => (
        <ToutTile
          key={`tout-tile-${i}`}
          eyebrowText={tout.eyebrowText}
          eyebrowLinkText={tout.eyebrowLinkText}
          eyebrowLinkPath={tout.eyebrowLinkPath}
          secondaryFilters={tout.secondaryFilters}
          headlineText={tout.headlineText}
          color={tout.color}
          smallHeadline
        />
      ))}
    </>
  );
}

export default ToutsWidget;
