import { PropsWithChildren } from 'react';
import clientPortalTheme from './assets/theme';
import { Components, Theme, ThemeProvider, createTheme } from '@mui/material';

interface NewEdgeThemeProviderProps extends PropsWithChildren {
  prefersDarkMode?: boolean;
  additionalGlobalStyleOverrides?: Components<Omit<Theme, 'components'>>;
}

export const NewEdgeThemeProvider = ({
  children,
  prefersDarkMode = true,
  additionalGlobalStyleOverrides = {},
}: NewEdgeThemeProviderProps) => {
  const theme = clientPortalTheme(prefersDarkMode, additionalGlobalStyleOverrides);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default NewEdgeThemeProvider;
