import { Box, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useState } from 'react';
import { formatNumber, NumberFormatType } from '@newedge/common';
import { AssetAllocationNestedTable } from './AssetAllocationNestedTable';
import { AssetAllocationNestedRow, AssetAllocationRow } from './@types';
import { CSSProperties } from '@mui/styles';

const NumberTableCell = styled(TableCell)(() => ({
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.4rem',
}));

type AssetAllocationTableRowProps = {
  key: string;
  rowData: AssetAllocationRow;
  startOpen: boolean;
  disableLinks: boolean;
  onViewHoldingsClick: (
    row: AssetAllocationRow,
    nestedRow: AssetAllocationNestedRow
  ) => void;
  nestedCellStyle?: CSSProperties;
};

export const AssetAllocationTableRow = ({
  key,
  rowData,
  startOpen = false,
  disableLinks,
  onViewHoldingsClick,
  nestedCellStyle,
}: AssetAllocationTableRowProps) => {
  const [open, setOpen] = useState(startOpen);
  return (
    <>
      <TableRow
        sx={({ palette }) => ({
          backgroundColor: palette.greyPercent[14],
        })}
        key={key}
      >
        <TableCell
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '1.6rem',
          }}
          component='th'
          scope='row'
        >
          <IconButton
            aria-label='expand row'
            sx={{
              fontSize: '2.4rem',
              padding: '3px',
            }}
            onClick={() => setOpen(!open)}
            size='large'
          >
            {open ? (
              <ArrowDropUpIcon fontSize='inherit' />
            ) : (
              <ArrowDropDownIcon fontSize='inherit' />
            )}
          </IconButton>
        </TableCell>
        <TableCell
          sx={{
            textAlign: 'left',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            fontSize: '1.6rem',
          }}
          colSpan={5}
        >
          {rowData.name}
        </TableCell>
        <NumberTableCell colSpan={6}>
          {formatNumber(rowData.marketValue)}
        </NumberTableCell>
        <NumberTableCell colSpan={6}>
          {formatNumber(rowData.percent, NumberFormatType.Percent, 2, 2)}
        </NumberTableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={18}>
          <Collapse in={open} timeout='auto' mountOnEnter unmountOnExit>
            <Box margin={0}>
              <AssetAllocationNestedTable
                parentRow={rowData}
                nestedData={rowData.nestedData}
                disableLinks={disableLinks}
                onViewHoldingsClick={onViewHoldingsClick}
                nestedCellStyle={nestedCellStyle}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AssetAllocationTableRow;
