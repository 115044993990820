import { AllowedClaims } from '@newedge/auth';
import { Outlet, useNavigate } from 'react-router';
import {
  useIsAuthenticated,
  useAccount,
  useMsal,
  MsalAuthenticationTemplate,
} from '@azure/msal-react';
import { isObjKeyEqualTo } from '@newedge/common';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { useCallback, useEffect } from 'react';

interface AuthRoutesProps {
  allowedClaims?: AllowedClaims;
}

export const AuthRoutes = ({ allowedClaims = {} }: AuthRoutesProps) => {
  const { accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  const isAllowed = useCallback(() => {
    return Object.keys(allowedClaims).every((claim) => {
      return isObjKeyEqualTo(
        account?.idTokenClaims ?? {},
        claim,
        allowedClaims[claim as keyof AllowedClaims]
      );
    });
  }, [account, allowedClaims]);

  useEffect(() => {
    if (
      inProgress !== InteractionStatus.None ||
      !isAuthenticated ||
      isAllowed()
    )
      return;
    navigate('/error/403');
  }, [
    account?.idTokenClaims,
    inProgress,
    isAuthenticated,
    navigate,
    isAllowed,
  ]);

  const loginRequest = {
    scopes: ['profile', 'openid'],
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      <Outlet />
    </MsalAuthenticationTemplate>
  );
};
