import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useMediaQuery,
  Button,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import React, { ReactNode } from 'react';
import {
  ColumnDataType,
  Order,
  SortBySelect,
  getDefaultComparator,
  stableSort,
  MobileReportTable,
  MobileReportTableColumn,
  formatNumber,
  NumberFormatType,
  TooltipWrapper,
} from '@newedge/common';
import {
  AccountBalanceViewModel,
  AccountGroupBalanceViewModel,
} from '../@types';
import { AccountGroupTableRow } from './AccountGroupTableRow';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

const getUngroupedAccountsAtEndComparator = (
  order: Order,
  orderBy: keyof Omit<AccountGroupBalanceViewModel, 'Accounts'>
) => {
  return (a: AccountGroupBalanceViewModel, b: AccountGroupBalanceViewModel) => {
    if (a.AccountGroupId === '0') {
      return 1;
    }
    if (b.AccountGroupId === '0') {
      return -1;
    }
    return getDefaultComparator<
      keyof Omit<AccountGroupBalanceViewModel, 'Accounts'>
    >(order, orderBy)(a, b);
  };
};

interface AccountGroupTableColumn {
  sortProperty:
    | keyof Omit<AccountGroupBalanceViewModel, 'Accounts'>
    | keyof AccountBalanceViewModel;
  label: string;
  relatedSortProperties?: string[];
  onSortClick?: (event: React.MouseEvent<HTMLElement>) => void;
  customSortRenderer?: () => ReactNode;
}

interface AccountGroupTableProps {
  data?: AccountGroupBalanceViewModel[];
  disableLinks: boolean;
}

export const AccountGroupTable = ({
  data,
  disableLinks,
}: AccountGroupTableProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [order, setOrder] = React.useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = React.useState<
    | keyof Omit<AccountGroupBalanceViewModel, 'Accounts'>
    | keyof AccountBalanceViewModel
  >('TotalBalance');

  const headCells: AccountGroupTableColumn[] = [
    {
      sortProperty: 'AccountGroupName',
      label: 'Account Name',
    },
    {
      sortProperty: 'ManagementStyle',
      label: 'Management Style',
    },
    {
      sortProperty: 'AccountType',
      label: 'Account Type',
    },
    {
      sortProperty: 'MarketValue',
      label: 'Market Value',
    },
    {
      sortProperty: 'CashBalance',
      label: 'Cash & Cash Equivalents',
    },
    {
      sortProperty: 'TotalBalance',
      label: 'Total Balance',
    },
    {
      sortProperty: 'UnrealizedGl',
      relatedSortProperties: ['UnrealizedGlPercent'],
      label: 'Unrealized G/L',
      onSortClick: (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
      },
      customSortRenderer: () => {
        const close = () => {
          setAnchorEl(null);
        };

        return (
          <Popper
            id='UnrealizedGlCustomSort'
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
          >
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <SortBySelect
                options={[
                  {
                    direction: Order.Asc,
                    displayValue: 'Dollar: Lowest to Highest',
                    onClick: () => {
                      setOrder(Order.Asc);
                      setOrderBy('UnrealizedGl');
                      close();
                    },
                  },
                  {
                    direction: Order.Desc,
                    displayValue: 'Dollar: Highest to Lowest',
                    onClick: () => {
                      setOrder(Order.Desc);
                      setOrderBy('UnrealizedGl');
                      close();
                    },
                  },
                  {
                    direction: Order.Asc,
                    displayValue: 'Percent: Lowest to Highest',
                    onClick: () => {
                      setOrder(Order.Asc);
                      setOrderBy('UnrealizedGlPercent');
                      close();
                    },
                  },
                  {
                    direction: Order.Desc,
                    displayValue: 'Percent: Highest to Lowest',
                    onClick: () => {
                      setOrder(Order.Desc);
                      setOrderBy('UnrealizedGlPercent');
                      close();
                    },
                  },
                ]}
                close={close}
              />
            </ClickAwayListener>
          </Popper>
        );
      },
    },
    {
      sortProperty: 'DailyChange',
      label: 'Daily Change',
    },
  ];

  const isActive = (
    columnSortProperty: string,
    relatedSortProperties: string[] | undefined
  ): boolean => {
    return (
      columnSortProperty === orderBy ||
      (!!relatedSortProperties &&
        relatedSortProperties.some((x) => x === orderBy))
    );
  };

  const setSort = (
    fieldToSort: keyof Omit<AccountGroupBalanceViewModel, 'Accounts'>
  ) => {
    if (fieldToSort === orderBy) {
      setOrder(order === Order.Asc ? Order.Desc : Order.Asc);
    } else {
      setOrder(Order.Asc);
    }
    setOrderBy(fieldToSort);
  };

  const sort = (
    array: AccountGroupBalanceViewModel[] | undefined
  ): AccountGroupBalanceViewModel[] => {
    if (!array) {
      return [];
    }

    let accountGroupSortProperty = orderBy;
    let accountSortProperty = orderBy;

    if (orderBy === 'AccountGroupName') {
      accountSortProperty = 'DisplayName';
    }

    array.forEach((accountGroupBalance) => {
      accountGroupBalance.Accounts = stableSort<AccountBalanceViewModel>(
        accountGroupBalance.Accounts,
        getDefaultComparator(order, accountSortProperty) as any
      );
    });

    let groupOrder = order;

    if (orderBy === 'ManagementStyle' || orderBy === 'AccountType') {
      /* since these values are not defined at the account group level, 
         sort the account groups with the default sort properties */
      accountGroupSortProperty = 'TotalBalance';
      groupOrder = Order.Desc;
    }

    return stableSort<AccountGroupBalanceViewModel>(
      array,
      getUngroupedAccountsAtEndComparator(
        groupOrder,
        accountGroupSortProperty as keyof Omit<
          AccountGroupBalanceViewModel,
          'Accounts'
        >
      )
    );
  };

  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const mobileReportColumns: MobileReportTableColumn<
    AccountGroupBalanceViewModel,
    AccountBalanceViewModel
  >[] = [
    {
      id: 'MarketValue',
      nestedId: 'MarketValue',
      label: 'Market Value',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '10rem' },
    },
    {
      id: 'CashBalance',
      nestedId: 'CashBalance',
      label: 'Cash & Cash Equiv.',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '12rem' },
    },
    {
      id: 'TotalBalance',
      nestedId: 'TotalBalance',
      label: 'Total Balance',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '10rem' },
    },
    {
      id: 'UnrealizedGl',
      nestedId: 'UnrealizedGl',
      label: 'Unrealized G/L',
      dataType: ColumnDataType.Currency,
      style: { minWidth: '12rem' },
      customValueRenderer: (value, row) => {
        return `${formatNumber(value as number)} (${formatNumber(
          row?.UnrealizedGlPercent as number,
          NumberFormatType.Percent,
          2
        )})`;
      },
    },
    {
      id: 'DailyChange',
      nestedId: 'DailyChange',
      label: 'Daily Change',
      dataType: ColumnDataType.Percent,
      customValueRenderer: (value) => {
        if (value === undefined || value === null) {
          return <Typography>N/A</Typography>;
        }
        return (
          <Box sx={{ display: 'flex' }}>
            {value && (value as number) > 0 ? <Typography>+</Typography> : null}
            <Typography>
              {formatNumber(
                (value as number) * 100,
                NumberFormatType.Percent,
                2,
                2
              )}
            </Typography>
          </Box>
        );
      },
    },
  ];

  const navigate = useNavigate();

  const renderMobileView = () => {
    return (
      <MobileReportTable
        zebra
        title='Account Balances'
        columns={mobileReportColumns}
        data={data ? sort(data) : []}
        headerId='AccountGroupName'
        nestedHeaderId='Nickname'
        nestedAccessor={(rowData) => rowData.Accounts}
        customNestedHeaderRenderer={(row, nestedRow) => {
          return (
            <Box
              sx={(theme) => ({
                borderBottom: `1px solid ${theme.extensions.mobileReportTable.borderColor}`,
                paddingBottom: theme.spacing(0.5),
                marginBottom: theme.spacing(1),
                display: 'flex',
                justifyContent: 'space-between',
              })}
            >
              <TooltipWrapper text={nestedRow.Nickname ?? ''} maxLength={72} />
              {!disableLinks && (
                <Button
                  sx={(theme) => ({
                    margin: 0,
                    padding: 0,
                    ...theme.typography.link,
                  })}
                  variant='text'
                  onClick={() => {
                    navigate('/my/holdings', {
                      state: {
                        accountFilter: nestedRow.AccountId,
                      },
                    });
                  }}
                  endIcon={
                    <ArrowForwardIcon
                      sx={() => ({
                        fontSize: 'small',
                      })}
                    />
                  }
                >
                  View Holdings
                </Button>
              )}
            </Box>
          );
        }}
      />
    );
  };

  const renderDesktopView = () => {
    return (
      <Paper>
        <Table sx={{ minWidth: 750 }}>
          <TableHead sx={(theme) => ({ ...theme.typography.tableHeaderRow })}>
            <TableRow>
              <TableCell></TableCell>
              {headCells.map((headCell) => {
                return (
                  <TableCell
                    sortDirection={
                      isActive(
                        headCell.sortProperty,
                        headCell.relatedSortProperties
                      )
                        ? order
                        : false
                    }
                    sx={{ border: 0, verticalAlign: 'middle' }}
                    key={`HeaderCell_${headCell.sortProperty}`}
                  >
                    <TableSortLabel
                      active={isActive(
                        headCell.sortProperty,
                        headCell.relatedSortProperties
                      )}
                      direction={
                        isActive(
                          headCell.sortProperty,
                          headCell.relatedSortProperties
                        )
                          ? order
                          : Order.Asc
                      }
                      onClick={(event) =>
                        headCell.onSortClick
                          ? headCell.onSortClick(event)
                          : setSort(
                              headCell.sortProperty as keyof Omit<
                                AccountGroupBalanceViewModel,
                                'Accounts'
                              >
                            )
                      }
                      IconComponent={ArrowDropDownIcon}
                      sx={(theme) => ({ ...theme.typography.tableHeaderText })}
                    >
                      {headCell.label}
                    </TableSortLabel>
                    {headCell.customSortRenderer
                      ? headCell.customSortRenderer()
                      : null}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sort(data).map((accountGroupBalance) => {
              return (
                <AccountGroupTableRow
                  key={accountGroupBalance.AccountGroupId}
                  data={accountGroupBalance}
                  disableLinks={disableLinks}
                />
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    );
  };

  if (isMobileOrTablet) {
    return renderMobileView();
  }
  return renderDesktopView();
};

export default AccountGroupTable;
