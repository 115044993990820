import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { CashFlowDetailTableNestedRowData } from '../@types';

const StyledRootCell = styled(TableCell)(() => ({
  padding: 0,
}));

const StyledTypographyNestedSectionTitle = styled(Typography)(
  ({ theme: { spacing } }) => ({
    paddingLeft: spacing(2),
    marginTop: spacing(1),
    fontWeight: 'bold',
    fontSize: '1.4rem',
  })
);

const StyledTable = styled(Table)(() => ({
  tableLayout: 'fixed',
}));

const StyledTableCellNestedRowTitle = styled(TableCell)(
  ({ theme: { spacing } }) => ({
    paddingRight: spacing(1),
    textAlign: 'left',
    paddingLeft: spacing(6),
    fontSize: '1.4rem',
  })
);

const StyledTableCellNestedCell = styled(TableCell)(
  ({ theme: { spacing } }) => ({
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
    textAlign: 'right',
    fontSize: '1.4rem',
  })
);

const formatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const renderColGroup = () => {
  return (
    <colgroup>
      <col span={14} />
      <Box
        component="col"
        sx={(theme) => ({
          borderLeft: theme.extensions.borders.grey28,
        })}
      />
    </colgroup>
  );
};

type CashFlowDetailTableNestedRowProps = {
  data: CashFlowDetailTableNestedRowData;
  open: boolean;
};

export const CashFlowDetailTableNestedRow = ({
  data,
  open,
}: CashFlowDetailTableNestedRowProps) => {
  const { dividends, interest } = data;
  return (
    <TableRow>
      {/* for the collapsible row, it is a nested table inside
      one giant cell within the parent table with colSpan={15} */}
      <StyledRootCell colSpan={16}>
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          // align nested border with parent table border for "total" column
          sx={{ width: 'calc(100% + 1px)' }}
        >
          <Box margin={0}>
            <StyledTable size="small" aria-label={data.category}>
              {renderColGroup()}
              <TableBody>
                <TableRow>
                  <TableCell colSpan={14}>
                    <StyledTypographyNestedSectionTitle gutterBottom>
                      {data.category}
                    </StyledTypographyNestedSectionTitle>
                  </TableCell>
                  <TableCell colSpan={2} />
                </TableRow>
                <TableRow>
                  <StyledTableCellNestedRowTitle
                    component="th"
                    scope="row"
                    colSpan={2}
                  >
                    Dividends
                  </StyledTableCellNestedRowTitle>
                  {dividends.map((value, index) => {
                    return (
                      <StyledTableCellNestedCell
                        key={`cash-flow-detail-dividends-StyledTableCellNestedCell-${index}`}
                      >
                        {formatter.format(value)}
                      </StyledTableCellNestedCell>
                    );
                  })}
                  <StyledTableCellNestedCell colSpan={2}>
                    {formatter.format(
                      dividends.reduce((total, value) => total + value, 0)
                    )}
                  </StyledTableCellNestedCell>
                </TableRow>
                <TableRow>
                  <StyledTableCellNestedRowTitle
                    component="th"
                    scope="row"
                    colSpan={2}
                  >
                    Interest Payments
                  </StyledTableCellNestedRowTitle>
                  {interest.map((value, index) => {
                    return (
                      <StyledTableCellNestedCell
                        key={`cash-flow-detail-interest-StyledTableCellNestedCell-${index}`}
                      >
                        {formatter.format(value)}
                      </StyledTableCellNestedCell>
                    );
                  })}
                  <StyledTableCellNestedCell colSpan={2}>
                    {formatter.format(
                      interest.reduce((total, value) => total + value, 0)
                    )}
                  </StyledTableCellNestedCell>
                </TableRow>
              </TableBody>
            </StyledTable>
          </Box>
        </Collapse>
      </StyledRootCell>
    </TableRow>
  );
};

export default CashFlowDetailTableNestedRow;
