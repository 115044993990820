import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PerformanceModel, PerformanceNestedTableRowData } from '../@types';
import {
  ColumnDataType,
  DataAlignment,
  HeadCell,
  NumberFormatType,
  formatNumber,
} from '@newedge/common';
import { newEdgeNamedColors } from '@newedge/theme';

type BenchmarkTableProps = {
  data: PerformanceNestedTableRowData[];
  headCellWidths: {
    [index: number]: number;
  };
};

export function BenchmarkTable({ data, headCellWidths }: BenchmarkTableProps) {
  const headCells: HeadCell<PerformanceModel>[] = [
    {
      id: 'name',
      dataType: ColumnDataType.String,
      label: 'Benchmark',
    },
    {
      id: 'monthToDate',
      dataType: ColumnDataType.Percent,
      label: 'MTD',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'quarterToDate',
      dataType: ColumnDataType.String,
      label: 'QTD',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'yearToDate',
      dataType: ColumnDataType.Percent,
      label: 'YTD',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'oneYearReturns',
      dataType: ColumnDataType.Percent,
      label: '1YR',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'threeYearReturns',
      dataType: ColumnDataType.Percent,
      label: '3YR',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'fiveYearReturns',
      dataType: ColumnDataType.Percent,
      label: '5YR',
      dataAlignment: DataAlignment.Right,
    },
    {
      id: 'tenYearReturns',
      dataType: ColumnDataType.Percent,
      label: '10YR',
      dataAlignment: DataAlignment.Right,
    },
  ];

  return (
    <TableContainer>
      <Table
        size={'medium'}
        aria-label='benchmark table'
        sx={{ minWidth: '1120px' }}
      >
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  sx={(theme) => {
                    return {
                      py: 3,
                      fontSize: '1.6rem',
                      backgroundColor: theme.palette.greyPercent[20],
                      textAlign:
                        headCell.dataAlignment === DataAlignment.Right
                          ? 'right'
                          : 'left',
                      width:
                        headCell.label === 'Benchmark'
                          ? headCellWidths[0] + headCellWidths[1]
                          : 'auto',
                    };
                  }}
                >
                  {headCell.label}
                </TableCell>
              );
            })}
            <TableCell
              sx={(theme) => ({
                backgroundColor: theme.palette.greyPercent[20],
                width: headCellWidths[9] + headCellWidths[10],
              })}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => {
            return (
              <TableRow
                key={row.name}
                sx={{
                  '&:nth-of-type(odd)': {
                    backgroundColor: 'greyPercent.8',
                  },
                }}
              >
                {Object.values(row).map((rowValue, index) => {
                  return rowValue !== row.sinceInception ? (
                    <TableCell
                      key={row.name + '_' + index}
                      sx={() => {
                        return {
                          fontSize: '1.4rem',
                          color: newEdgeNamedColors.lightCadet,
                          textAlign: index === 0 ? 'left' : 'right',
                        };
                      }}
                    >
                      {typeof rowValue === 'string'
                        ? rowValue
                        : rowValue === null
                        ? '--'
                        : formatNumber(
                            rowValue as unknown as number,
                            NumberFormatType.Percent,
                            2,
                            2
                          )}
                    </TableCell>
                  ) : undefined;
                })}
                <TableCell
                  sx={{
                    width: headCellWidths[9] + headCellWidths[10],
                  }}
                ></TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BenchmarkTable;
