import { ClientPortalFinancialAccount } from '../';

export const findNicknameForAccountId = (
  financialAccounts: ClientPortalFinancialAccount[],
  id: number
): string | null => {
  let nickname = null;
  financialAccounts.some((account) => {
    if (account.financialAccountId === id) {
      nickname = account.nicknameEntry?.accountNickname;
      if (nickname === null || nickname === undefined) {
        nickname = account.accountName;
      }
      return true;
    }
    return false;
  });

  return nickname;
};

export default findNicknameForAccountId;
