import { DateTime } from 'luxon';
import { AccountActivityExcelData, AccountActivityModel } from './@types';
import { FilterItem } from '@newedge/common';

export const computeExcelData = (
  tableData: AccountActivityModel[]
): AccountActivityExcelData[] => {
  const excelData: AccountActivityExcelData[] = [];

  tableData.forEach((o) => {
    excelData.push({
      Date: o.transactionDate,
      'Transaction Type': o.transactionType,
      'Account Name': o.accountNickname,
      'Account Number': o.accountNumber,
      Ticker: o.ticker,
      Name: o.productName,
      Units: o.units,
      Price: o.price,
      Amount: o.amount,
    });
  });
  return excelData;
};

export const getDefaultFilterItems = () => {
  const items: FilterItem[] = [];

  // last 30 days
  const today = DateTime.utc().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const thirtyDaysAgo = today.minus({ days: 30 });

  items.push({
    date: {
      startDate: thirtyDaysAgo,
      endDate: today,
      datePeriod: 'Custom',
    },
    displayValue: `Date: ${thirtyDaysAgo.toLocaleString()} - ${today.toLocaleString()}`,
    filterType: 'date',
    predicateIdentifier: 'transactionDate',
    unique: true,
  });

  return items;
};
