import { IconButton as MuiIconButton } from '@mui/material';
import { useColor } from '@newedge/theme';
import { IconVariant } from '../icons/@types';
import Icon from '../icons/Icon';
import { ButtonValueType, CustomIconButtonProps } from './@types';

export function IconButton<TValue, TVariant extends IconVariant>({
  iconProps,
  variant,
  primaryColor = 'currentColor',
  secondaryColor,
  backgroundColor: backgroundColorProp,
  children,
  value,
  ...buttonProps
}: CustomIconButtonProps<TValue, TVariant>) {
  const defaultBackground = useColor().background;
  const backgroundColor = backgroundColorProp ?? defaultBackground;
  return (
    <MuiIconButton {...buttonProps} value={value as ButtonValueType}>
      {children ??
        (variant && (
          <Icon
            variant={variant}
            backgroundColor={backgroundColor}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            iconProps={iconProps}
          />
        ))}
    </MuiIconButton>
  );
}

export default IconButton;
