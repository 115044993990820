import { AllowedClaims } from '@newedge/auth';
import { ReactNode } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router';
import {
  AuthRoutes,
  ErrorBoundaryOutlet,
  CallbackFallbackHandler,
  MessageRoot,
} from './components';
import { ErrorView, LoggedOutView } from './components/message';
import { AutoLogoutMonitor } from '@newedge/common';

export const wrapAuthRoutes = (
  authRoutes: RouteObject[],
  allowedClaims: AllowedClaims = {},
  indexPath: string = '/my/dashboard',
  errorLinkRenderer?: () => ReactNode
): RouteObject[] => {
  return [
    {
      element: <ErrorBoundaryOutlet />,
      children: [
        {
          element: <AuthRoutes allowedClaims={allowedClaims} />,
          children: [
            {
              index: true,
              element: <Navigate to={indexPath} replace />,
            },
            {
              path: 'my/*',
              element: (
                <AutoLogoutMonitor>
                  <Outlet />
                </AutoLogoutMonitor>
              ),
              children: authRoutes,
            },
            {
              path: '*',
              element: <Navigate to='error/404' replace />,
            },
          ],
        },
        {
          element: <CallbackFallbackHandler path={indexPath} />,
          path: 'callback',
        },
      ],
    },
    {
      element: <MessageRoot />,
      children: [
        {
          path: 'error',
          element: (
            <ErrorView
              customLinkRenderer={
                errorLinkRenderer ? errorLinkRenderer : undefined
              }
            />
          ),
        },
        {
          path: 'error/:errorCode',
          element: (
            <ErrorView
              customLinkRenderer={
                errorLinkRenderer ? errorLinkRenderer : undefined
              }
            />
          ),
        },
        {
          path: 'logged-out',
          element: <LoggedOutView />,
        },
      ],
    },
  ];
};
