import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import ConfirmActions from './ConfirmActions';

export type InactivityLogOutMessageModalProps = {
  isOpen: boolean;
  okOnClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export function InactivityLogOutMessageModal({
  isOpen,
  okOnClick,
}: InactivityLogOutMessageModalProps) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby='inactivity-logout-info'
      PaperProps={{
        sx: { backgroundColor: '#6B6B6B' },
      }}
    >
      <DialogContent
        sx={{
          maxWidth: '300px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <DialogContentText
          sx={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontSize: '1.6rem',
            fontWeight: '400',
            fontFamily: '"Source Sans Pro", "Arial", sans-serif',
          }}
        >
          {
            'Due to inactivity for the last 15 minutes, we have logged you out for your data security.'
          }
        </DialogContentText>
      </DialogContent>
      <ConfirmActions
        handleSubmit={okOnClick}
        submitButton={{
          text: 'OK',
          sx: {
            fontFamily: '"Source Sans Pro", "Arial", sans-serif',
            fontSize: '1.4rem',
            background: 'linear-gradient(to right, #0A42C6, #1C60FF)',
            contrastText: '#FFFFFF',
            color: '#FFFFFF',
            textTransform: 'none',
            margin: '0.8rem',
          },
          variant: 'contained',
        }}
      />
    </Dialog>
  );
}

export default InactivityLogOutMessageModal;
